import { toast } from "react-toastify";
import {
	PASSWORD_STRENGTH,
	ENV_MAPPER,
	APP_PERMISSION_MAPPER,
	MFA_STRENGTH,
} from "./constants";
import { useEffect } from "react";
import jwt_decode from "jwt-decode";

export interface ProxyObjectType {}
const jwt = require("jsonwebtoken");

const emptyProxyObject: ProxyObjectType = new Proxy(
	{},
	{}
	// {
	//     get: () => {
	//         return emptyProxyObject;
	//     },
	// }
);

const isEmpty = (val: any) => {
	// Stolen From: https://stackoverflow.com/a/28953167
	/*
		test results
		--------------
		[] true, empty array
		{} true, empty object
		null true
		undefined true
		"" true, empty string
		'' true, empty string
		0 false, number
		true false, boolean
		false false, boolean
		Date false
		function false
		*/
	if (val === emptyProxyObject) return true;
	if (val === undefined) return true;

	if (
		typeof val == "function" ||
		typeof val == "number" ||
		typeof val == "boolean" ||
		Object.prototype.toString.call(val) === "[object Date]"
	)
		return false;

	if (val == null || val.length === 0)
		// null or 0 length array
		return true;

	if (typeof val == "object") if (Object.keys(val).length === 0) return true;

	return false;
};

export const getValue = (val: any) => {
	return isEmpty(val) ? "-" : val;
};

export interface PasswordStrength {
	length: Boolean;
	numeric: Boolean;
	lowerCase: Boolean;
	upperCase: Boolean;
	specialCharacter: Boolean;
}

export interface MfaStrength {
	length: Boolean;
	numeric: Boolean;
}

export const passwordValidator = (value: any) => {
	const result = {
		length: false,
		lowerCase: false,
		upperCase: false,
		specialCharacter: false,
		numeric: false,
	};
	if (!isEmpty(value)) {
		if (PASSWORD_STRENGTH.MIN_LENGTH <= value.length) {
			result.length = true;
		}
		if (value.match(/[_A-Z]/)) {
			result.upperCase = true;
		}
		if (value.match(/[_a-z]/)) {
			result.lowerCase = true;
		}
		if (value.match(/[_0-9]/)) {
			result.numeric = true;
		}
		if (value.match(/[_\W]/)) {
			result.specialCharacter = true;
		}
	}
	return result;
};

export const mfaValidator = (value: any) => {
	const result = {
		length: false,
		numeric: false,
	};
	if (!isEmpty(value)) {
		if (MFA_STRENGTH.MIN_LENGTH <= value.length) {
			result.length = true;
		}
		if (value.match(/[_0-9]/)) {
			result.numeric = true;
		}
	}
	return result;
};

export const notify = (type: string, message: string) => {
	type === "success"
		? toast.success(message, {
				position: toast.POSITION.TOP_RIGHT,
				autoClose: 10000,
		  })
		: toast.error(message, {
				position: toast.POSITION.TOP_RIGHT,
				autoClose: 10000,
		  });
};

const setLocalStorage = (key: string, value: any) => {
	window.localStorage.setItem(key, JSON.stringify(value));
};

const getLocalStorage = (key: string) => {
	const value: any = window.localStorage.getItem(key);
	return JSON.parse(value);
};

const clearLocalStorage = (key: Array<any>) => {
	key.forEach((item) => window.localStorage.removeItem(item));
};

const buildDateToSQLFmt = (ipDate: any) => {
	return (
		new Date(ipDate).getFullYear() +
		"-" +
		("0" + (new Date(ipDate).getMonth() + 1)).slice(-2) +
		"-" +
		("0" + new Date(ipDate).getDate()).slice(-2)
	);
};

const buildDateFromSQLFmt = (ipDate: any) => {
	let year = ipDate.split("-")[0];
	let month = ipDate.split("-")[1] - 1;
	let day = ipDate.split("-")[2];
	return new Date(year, month, day);
};

const UseLockBodyScroll = (status: boolean) => {
	// Get original value of body overflow
	if (status) document.body.style.overflow = "hidden";
	else document.body.style.overflow = "auto";
};

const useOutsideClick = (ref: any, callback: any) => {
	const handleClick = (e: any) => {
		if (ref.current && !ref.current.contains(e.target)) {
			callback();
		}
	};
	useEffect(() => {
		document.addEventListener("click", handleClick);
		return () => {
			document.removeEventListener("click", handleClick);
		};
	});
};

// TODO: Deprecate the following method
// const generateRedirectAppUrl = (host: string) => {
//   const baseUrl = process.env.REACT_APP_BASE_URL;
//   if (
//     baseUrl?.includes("localhost") ||
//     baseUrl?.includes("127.0.0.1") ||
//     baseUrl?.includes(".dev.")
//   ) {
//     return `https://${host}.dev.nuflights.com/`;
//   }
//   if (baseUrl?.includes(".qa.")) {
//     return `https://${host}.qa.nuflights.com/`;
//   }
//   if (baseUrl?.includes(".staging.")) {
//     return `https://${host}.staging.nuflights.com/`;
//   }
//   return `https://${host}.nuflights.com/`;
// };

const getEnv = () => {
	const baseUrl = process.env.REACT_APP_BASE_URL;
	console.log("baseUrl", baseUrl);
	if (baseUrl?.includes("localhost") || baseUrl?.includes("127.0.0.1")) {
		return ENV_MAPPER.local;
	}
	if (baseUrl?.includes(".dev.")) {
		return ENV_MAPPER.development;
	}
	if (baseUrl?.includes(".qa.")) {
		return ENV_MAPPER.qa;
	}
	if (baseUrl?.includes(".staging.")) {
		return ENV_MAPPER.staging;
	}
	if (baseUrl?.includes(".intg.")) {
		return ENV_MAPPER.integration;
	}
	return ENV_MAPPER.production;
};

const nodeEnv = process.env.NODE_ENV;

const verifyAuthToken = (authToken: string, appPermission: string) => {
	// TODO: add Interface for authToken
	let decodedToken: any, scope;
	try {
		decodedToken = jwt_decode(authToken);
		scope = decodedToken?.scope?.split(" ") || [];
	} catch (err) {
		console.error(err);
	}
	return Boolean(scope?.includes(APP_PERMISSION_MAPPER[appPermission]));
};

// TODO: Move to NODE_ENV approach
// const generateRedirectUrl = (host : string) =>
//     nodeEnv === "production"
//         ? `https://${host}.nuflights.com/`
//         : `https://${host}.${ENV_MAPPER[nodeEnv] || "dev"}.nuflights.com/`;

export {
	jwt,
	nodeEnv,
	emptyProxyObject,
	verifyAuthToken,
	isEmpty,
	getEnv,
	setLocalStorage,
	getLocalStorage,
	clearLocalStorage,
	UseLockBodyScroll,
	buildDateToSQLFmt,
	buildDateFromSQLFmt,
	useOutsideClick,
};
