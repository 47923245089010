// DON'T EDIT. THIS FILE IS GENERATED BY ./manage.py frontend_export
// CHANGE MADES MANUALLY TO THIS FILE WILL BE LOST
// backend/contribs/management/commands/frontend_export.py

const CHOICES = {
	AirlineDesignator: {
		A3: "Aegean Airlines",
		A9: "Georgian Airways",
		AA: "American Airlines",
		AC: "Air Canada",
		AE: "Mandarin Airlines",
		AF: "Air France",
		AH: "Air Alg\u00e9rie",
		AI: "Air India",
		AM: "Aeromexico",
		AP: "Albastar",
		AR: "Aerolineas Argentinas",
		AS: "Alaska Airlines",
		AT: "Royal Air Maroc",
		AU: "Austral",
		AV: "Avianca",
		AW: "Africa World Airlines",
		AY: "Finnair",
		AZ: "Alitalia",
		B0: "Blue Air",
		B2: "Belavia Belarusian Airlines",
		B6: "JetBlue",
		B7: "UNI AIR",
		B9: "Iran Airtour Airline",
		BA: "British Airways",
		BF: "French Bee",
		BG: "Biman Bangladesh Airlines",
		BI: "Royal Brunei",
		BJ: "Nouvelair",
		BK: "Okay Airways",
		BP: "Air Botswana",
		BR: "EVA Air",
		BT: "Air Baltic",
		BV: "Blue Panorama",
		BW: "Caribbean Airlines",
		C4: "LATAM Airlines Colombia",
		C5: "CAL Cargo Airlines",
		C7: "Jeju Air",
		CA: "Air China",
		CF: "China Postal Airlines",
		CHOICES: {
			ANA: "NH",
			ASKY: "KP",
			"ASL Airlines France": "5O",
			"Aegean Airlines": "A3",
			"Aer Lingus": "EI",
			"Aero Republica": "P5",
			Aeroflot: "SU",
			"Aerolineas Argentinas": "AR",
			Aeromar: "VW",
			Aeromexico: "AM",
			"Africa World Airlines": "AW",
			"Air Alg\u00e9rie": "AH",
			"Air Arabia": "G9",
			"Air Astana": "KC",
			"Air Austral": "UU",
			"Air Baltic": "BT",
			"Air Botswana": "BP",
			"Air Caledonie": "TY",
			"Air Canada": "AC",
			"Air Caraibes": "TX",
			"Air China": "CA",
			"Air Corsica": "XK",
			"Air Dolomiti": "EN",
			"Air Europa": "UX",
			"Air France": "AF",
			"Air Guilin": "GT",
			"Air India": "AI",
			"Air Koryo": "JS",
			"Air Macau": "NX",
			"Air Madagascar": "MD",
			"Air Malta": "KM",
			"Air Mauritius": "MK",
			"Air Moldova": "9U",
			"Air Namibia": "SW",
			"Air New Zealand": "NZ",
			"Air Niugini": "PX",
			"Air Nostrum": "YW",
			"Air Peace": "P4",
			"Air Serbia": "JU",
			"Air Seychelles": "HM",
			"Air Tahiti": "VT",
			"Air Tahiti Nui": "TN",
			"Air Tanzania": "TC",
			"Air Transat": "TS",
			"Air Vanuatu": "NF",
			"AirBridgeCargo Airlines": "RU",
			Aircalin: "SB",
			Airlink: "4Z",
			"Alaska Airlines": "AS",
			Albastar: "AP",
			Alitalia: "AZ",
			"American Airlines": "AA",
			"Arik Air": "W3",
			"Arkia Israeli Airlines": "IZ",
			"Asiana Airlines": "OZ",
			"Atlantic Airways": "RC",
			"Atlas Air": "5Y",
			Austral: "AU",
			Austrian: "OS",
			Avianca: "AV",
			"Avianca Costa Rica": "LR",
			"Avianca Ecuador": "2K",
			"Azerbaijan Airlines": "J2",
			"Azores Airlines": "S4",
			Bahamasair: "UP",
			"Bamboo Airways": "QH",
			"Bangkok Airways": "PG",
			"Batik Air": "ID",
			"Belavia Belarusian Airlines": "B2",
			"Biman Bangladesh Airlines": "BG",
			"Binter Canarias": "NT",
			"Blue Air": "0B",
			"Blue Panorama": "BV",
			"Braathens Regional Airways": "TF",
			"British Airways": "BA",
			"Brussels Airlines": "SN",
			"Bulgaria Air": "FB",
			"CAL Cargo Airlines": "5C",
			"COPA Airlines": "CM",
			"Camair-Co": "QC",
			"Cambodia Angkor Air": "K6",
			"Cargojet Airways": "W8",
			Cargolux: "CV",
			"Caribbean Airlines": "BW",
			Carpatair: "V3",
			"Cathay Pacific": "CX",
			"Cebu Pacific": "5J",
			"China Airlines": "CI",
			"China Cargo Airlines": "CK",
			"China Eastern": "MU",
			"China Express Airlines": "G5",
			"China Postal Airlines": "CF",
			"China Southern Airlines": "CZ",
			CityJet: "WX",
			Condor: "DE",
			"Corendon Airlines": "XC",
			"Corsair International": "SS",
			"Croatia Airlines": "OU",
			Cubana: "CU",
			"Cyprus Airways": "CY",
			"Czech Airlines": "OK",
			"DHL Air": "D0",
			"DHL Aviation": "ES",
			"Delta Air Lines": "DL",
			"EL AL": "LY",
			"EVA Air": "BR",
			"Eastern Airways": "T3",
			Egyptair: "MS",
			Emirates: "EK",
			"Ethiopian Airlines": "ET",
			"Etihad Airways": "EY",
			"European Air Transport": "QY",
			Eurowings: "EW",
			"Evelop Airlines": "E9",
			"FedEx Express": "FX",
			"Fiji Airways": "FJ",
			Finnair: "AY",
			FlyEgypt: "FT",
			"Freebird Airlines": "FH",
			"French Bee": "BF",
			"GOL Linhas Aereas": "G3",
			"GX Airlines": "GX",
			"Garuda Indonesia": "GA",
			"Georgian Airways": "A9",
			"Gulf Air": "GF",
			"Hahn Air": "HR",
			"Hainan Airlines": "HU",
			"Hawaiian Airlines": "HA",
			"Hi Fly": "5K",
			"Hong Kong Air Cargo": "RH",
			"Hong Kong Airlines": "HX",
			"Hong Kong Express Airways": "UO",
			IBERIA: "IB",
			Icelandair: "FI",
			IndiGo: "6E",
			Interjet: "4O",
			"Iran Air": "IR",
			"Iran Airtour Airline": "B9",
			"Iran Aseman Airlines": "EP",
			Israir: "6H",
			"Japan Airlines": "JL",
			"Japan Transocean Air": "NU",
			"Jazeera Airways": "J9",
			"Jeju Air": "7C",
			JetBlue: "B6",
			"Jin Air": "LJ",
			"Jordan Aviation": "R5",
			"Juneyao Airlines": "HO",
			KLM: "KL",
			"Kam Air": "RQ",
			"Kenya Airways": "KQ",
			"Korean Air": "KE",
			"Kunming Airlines": "KY",
			"Kuwait Airways": "KU",
			LAM: "TM",
			"LATAM Airlines Brasil": "JJ",
			"LATAM Airlines Colombia": "4C",
			"LATAM Airlines Ecuador": "XL",
			"LATAM Airlines Group": "LA",
			"LATAM Airlines Paraguay": "PZ",
			"LATAM Airlines Peru": "LP",
			"LATAM Cargo Brasil": "M3",
			"LATAM Cargo Chile": "UC",
			"LIAT Airlines": "LI",
			"LOT Polish Airlines": "LO",
			"Lao Airlines": "QV",
			"Loong Air": "GJ",
			"Lucky Air": "8L",
			Lufthansa: "LH",
			"Lufthansa CityLine": "CL",
			Luxair: "LG",
			MEA: "ME",
			"MIAT Mongolian Airlines": "OM",
			"MNG Airlines": "MB",
			"Mahan Air": "W5",
			"Malaysia Airlines": "MH",
			"Malindo Air": "OD",
			"Mandarin Airlines": "AE",
			"Montenegro Airlines": "YM",
			"Myanmar Airways International": "8M",
			"NCA Nippon Cargo Airlines": "KZ",
			Neos: "NO",
			"Nesma Airlines": "NE",
			NordStar: "Y7",
			"Nordavia Regional Airlines": "5N",
			Nouvelair: "BJ",
			"Okay Airways": "BK",
			"Olympic Air": "OA",
			"Oman Air": "WY",
			"Onur Air": "8Q",
			"Overland Airways": "OF",
			"PGA-Portugalia Airlines": "NI",
			"PIA Pakistan International Airlines": "PK",
			Paranair: "ZP",
			"Pegas Fly": "EO",
			"Pegasus Airlines": "PC",
			"Philippine Airlines": "PR",
			"Polar Air Cargo": "PO",
			"Precision Air": "PW",
			Qantas: "QF",
			"Qatar Airways": "QR",
			"Qazaq Air": "IQ",
			"Rossiya Airlines": "FV",
			"Royal Air Maroc": "AT",
			"Royal Brunei": "BI",
			"Royal Jordanian": "RJ",
			"Ruili Airlines": "DR",
			RusLine: "7R",
			"S7 Airlines": "S7",
			SAS: "SK",
			"SATA Air Acores": "SP",
			"SCAT Airlines": "DV",
			"SKY Airline": "H2",
			SWISS: "LX",
			Safair: "FA",
			"Saudi Arabian Airlines": "SV",
			"SaudiGulf Airlines": "6S",
			"Shandong Airlines": "SC",
			"Shanghai Airlines": "FM",
			"Shenzhen Airlines": "ZH",
			"Sichuan Airlines": "3U",
			"Silk Way West Airlines": "7L",
			SilkAir: "MI",
			"Singapore Airlines": "SQ",
			"Solomon Airlines": "IE",
			"South African Airways": "SA",
			SpiceJet: "SG",
			"SriLankan Airlines": "UL",
			SunExpress: "XQ",
			"Suparna Airlines": "Y8",
			"Surinam Airways": "PY",
			Syrianair: "RB",
			"TAAG Angola Airlines": "DT",
			TACA: "TA",
			"TAP Portugal": "TP",
			TAROM: "RO",
			TUIfly: "X3",
			"Tassili Airlines": "SF",
			"Thai Airways International": "TG",
			"Thai Smile": "WE",
			Tunisair: "TU",
			"Turkish Airlines": "TK",
			"UNI AIR": "B7",
			"UPS Airlines": "5X",
			UTair: "UT",
			"Ukraine International Airlines": "PS",
			"United Airlines": "UA",
			"Ural Airlines": "U6",
			"Urumqi Air": "UQ",
			"Uzbekistan Airways": "HY",
			Vietjet: "VJ",
			"Vietnam Airlines": "VN",
			"Virgin Atlantic": "VS",
			"Virgin Australia": "VA",
			Vistara: "UK",
			Volotea: "V7",
			Vueling: "VY",
			WestJet: "WS",
			"White coloured by you": "WI",
			Wideroe: "WF",
			"Xiamen Airlines": "MF",
			"YTO Cargo Airlines": "YG",
			flydubai: "FZ",
		},
		CHOICE_LIST: [
			{
				label: "NH",
				value: "ANA",
			},
			{
				label: "KP",
				value: "ASKY",
			},
			{
				label: "5O",
				value: "ASL Airlines France",
			},
			{
				label: "A3",
				value: "Aegean Airlines",
			},
			{
				label: "EI",
				value: "Aer Lingus",
			},
			{
				label: "P5",
				value: "Aero Republica",
			},
			{
				label: "SU",
				value: "Aeroflot",
			},
			{
				label: "AR",
				value: "Aerolineas Argentinas",
			},
			{
				label: "VW",
				value: "Aeromar",
			},
			{
				label: "AM",
				value: "Aeromexico",
			},
			{
				label: "AW",
				value: "Africa World Airlines",
			},
			{
				label: "AH",
				value: "Air Alg\u00e9rie",
			},
			{
				label: "G9",
				value: "Air Arabia",
			},
			{
				label: "KC",
				value: "Air Astana",
			},
			{
				label: "UU",
				value: "Air Austral",
			},
			{
				label: "BT",
				value: "Air Baltic",
			},
			{
				label: "BP",
				value: "Air Botswana",
			},
			{
				label: "TY",
				value: "Air Caledonie",
			},
			{
				label: "AC",
				value: "Air Canada",
			},
			{
				label: "TX",
				value: "Air Caraibes",
			},
			{
				label: "CA",
				value: "Air China",
			},
			{
				label: "XK",
				value: "Air Corsica",
			},
			{
				label: "EN",
				value: "Air Dolomiti",
			},
			{
				label: "UX",
				value: "Air Europa",
			},
			{
				label: "AF",
				value: "Air France",
			},
			{
				label: "GT",
				value: "Air Guilin",
			},
			{
				label: "AI",
				value: "Air India",
			},
			{
				label: "JS",
				value: "Air Koryo",
			},
			{
				label: "NX",
				value: "Air Macau",
			},
			{
				label: "MD",
				value: "Air Madagascar",
			},
			{
				label: "KM",
				value: "Air Malta",
			},
			{
				label: "MK",
				value: "Air Mauritius",
			},
			{
				label: "9U",
				value: "Air Moldova",
			},
			{
				label: "SW",
				value: "Air Namibia",
			},
			{
				label: "NZ",
				value: "Air New Zealand",
			},
			{
				label: "PX",
				value: "Air Niugini",
			},
			{
				label: "YW",
				value: "Air Nostrum",
			},
			{
				label: "P4",
				value: "Air Peace",
			},
			{
				label: "JU",
				value: "Air Serbia",
			},
			{
				label: "HM",
				value: "Air Seychelles",
			},
			{
				label: "VT",
				value: "Air Tahiti",
			},
			{
				label: "TN",
				value: "Air Tahiti Nui",
			},
			{
				label: "TC",
				value: "Air Tanzania",
			},
			{
				label: "TS",
				value: "Air Transat",
			},
			{
				label: "NF",
				value: "Air Vanuatu",
			},
			{
				label: "RU",
				value: "AirBridgeCargo Airlines",
			},
			{
				label: "SB",
				value: "Aircalin",
			},
			{
				label: "4Z",
				value: "Airlink",
			},
			{
				label: "AS",
				value: "Alaska Airlines",
			},
			{
				label: "AP",
				value: "Albastar",
			},
			{
				label: "AZ",
				value: "Alitalia",
			},
			{
				label: "AA",
				value: "American Airlines",
			},
			{
				label: "W3",
				value: "Arik Air",
			},
			{
				label: "IZ",
				value: "Arkia Israeli Airlines",
			},
			{
				label: "OZ",
				value: "Asiana Airlines",
			},
			{
				label: "RC",
				value: "Atlantic Airways",
			},
			{
				label: "5Y",
				value: "Atlas Air",
			},
			{
				label: "AU",
				value: "Austral",
			},
			{
				label: "OS",
				value: "Austrian",
			},
			{
				label: "AV",
				value: "Avianca",
			},
			{
				label: "LR",
				value: "Avianca Costa Rica",
			},
			{
				label: "2K",
				value: "Avianca Ecuador",
			},
			{
				label: "J2",
				value: "Azerbaijan Airlines",
			},
			{
				label: "S4",
				value: "Azores Airlines",
			},
			{
				label: "UP",
				value: "Bahamasair",
			},
			{
				label: "QH",
				value: "Bamboo Airways",
			},
			{
				label: "PG",
				value: "Bangkok Airways",
			},
			{
				label: "ID",
				value: "Batik Air",
			},
			{
				label: "B2",
				value: "Belavia Belarusian Airlines",
			},
			{
				label: "BG",
				value: "Biman Bangladesh Airlines",
			},
			{
				label: "NT",
				value: "Binter Canarias",
			},
			{
				label: "0B",
				value: "Blue Air",
			},
			{
				label: "BV",
				value: "Blue Panorama",
			},
			{
				label: "TF",
				value: "Braathens Regional Airways",
			},
			{
				label: "BA",
				value: "British Airways",
			},
			{
				label: "SN",
				value: "Brussels Airlines",
			},
			{
				label: "FB",
				value: "Bulgaria Air",
			},
			{
				label: "5C",
				value: "CAL Cargo Airlines",
			},
			{
				label: "CM",
				value: "COPA Airlines",
			},
			{
				label: "QC",
				value: "Camair-Co",
			},
			{
				label: "K6",
				value: "Cambodia Angkor Air",
			},
			{
				label: "W8",
				value: "Cargojet Airways",
			},
			{
				label: "CV",
				value: "Cargolux",
			},
			{
				label: "BW",
				value: "Caribbean Airlines",
			},
			{
				label: "V3",
				value: "Carpatair",
			},
			{
				label: "CX",
				value: "Cathay Pacific",
			},
			{
				label: "5J",
				value: "Cebu Pacific",
			},
			{
				label: "CI",
				value: "China Airlines",
			},
			{
				label: "CK",
				value: "China Cargo Airlines",
			},
			{
				label: "MU",
				value: "China Eastern",
			},
			{
				label: "G5",
				value: "China Express Airlines",
			},
			{
				label: "CF",
				value: "China Postal Airlines",
			},
			{
				label: "CZ",
				value: "China Southern Airlines",
			},
			{
				label: "WX",
				value: "CityJet",
			},
			{
				label: "DE",
				value: "Condor",
			},
			{
				label: "XC",
				value: "Corendon Airlines",
			},
			{
				label: "SS",
				value: "Corsair International",
			},
			{
				label: "OU",
				value: "Croatia Airlines",
			},
			{
				label: "CU",
				value: "Cubana",
			},
			{
				label: "CY",
				value: "Cyprus Airways",
			},
			{
				label: "OK",
				value: "Czech Airlines",
			},
			{
				label: "D0",
				value: "DHL Air",
			},
			{
				label: "ES",
				value: "DHL Aviation",
			},
			{
				label: "DL",
				value: "Delta Air Lines",
			},
			{
				label: "LY",
				value: "EL AL",
			},
			{
				label: "BR",
				value: "EVA Air",
			},
			{
				label: "T3",
				value: "Eastern Airways",
			},
			{
				label: "MS",
				value: "Egyptair",
			},
			{
				label: "EK",
				value: "Emirates",
			},
			{
				label: "ET",
				value: "Ethiopian Airlines",
			},
			{
				label: "EY",
				value: "Etihad Airways",
			},
			{
				label: "QY",
				value: "European Air Transport",
			},
			{
				label: "EW",
				value: "Eurowings",
			},
			{
				label: "E9",
				value: "Evelop Airlines",
			},
			{
				label: "FX",
				value: "FedEx Express",
			},
			{
				label: "FJ",
				value: "Fiji Airways",
			},
			{
				label: "AY",
				value: "Finnair",
			},
			{
				label: "FT",
				value: "FlyEgypt",
			},
			{
				label: "FH",
				value: "Freebird Airlines",
			},
			{
				label: "BF",
				value: "French Bee",
			},
			{
				label: "G3",
				value: "GOL Linhas Aereas",
			},
			{
				label: "GX",
				value: "GX Airlines",
			},
			{
				label: "GA",
				value: "Garuda Indonesia",
			},
			{
				label: "A9",
				value: "Georgian Airways",
			},
			{
				label: "GF",
				value: "Gulf Air",
			},
			{
				label: "HR",
				value: "Hahn Air",
			},
			{
				label: "HU",
				value: "Hainan Airlines",
			},
			{
				label: "HA",
				value: "Hawaiian Airlines",
			},
			{
				label: "5K",
				value: "Hi Fly",
			},
			{
				label: "RH",
				value: "Hong Kong Air Cargo",
			},
			{
				label: "HX",
				value: "Hong Kong Airlines",
			},
			{
				label: "UO",
				value: "Hong Kong Express Airways",
			},
			{
				label: "IB",
				value: "IBERIA",
			},
			{
				label: "FI",
				value: "Icelandair",
			},
			{
				label: "6E",
				value: "IndiGo",
			},
			{
				label: "4O",
				value: "Interjet",
			},
			{
				label: "IR",
				value: "Iran Air",
			},
			{
				label: "B9",
				value: "Iran Airtour Airline",
			},
			{
				label: "EP",
				value: "Iran Aseman Airlines",
			},
			{
				label: "6H",
				value: "Israir",
			},
			{
				label: "JL",
				value: "Japan Airlines",
			},
			{
				label: "NU",
				value: "Japan Transocean Air",
			},
			{
				label: "J9",
				value: "Jazeera Airways",
			},
			{
				label: "7C",
				value: "Jeju Air",
			},
			{
				label: "B6",
				value: "JetBlue",
			},
			{
				label: "LJ",
				value: "Jin Air",
			},
			{
				label: "R5",
				value: "Jordan Aviation",
			},
			{
				label: "HO",
				value: "Juneyao Airlines",
			},
			{
				label: "KL",
				value: "KLM",
			},
			{
				label: "RQ",
				value: "Kam Air",
			},
			{
				label: "KQ",
				value: "Kenya Airways",
			},
			{
				label: "KE",
				value: "Korean Air",
			},
			{
				label: "KY",
				value: "Kunming Airlines",
			},
			{
				label: "KU",
				value: "Kuwait Airways",
			},
			{
				label: "TM",
				value: "LAM",
			},
			{
				label: "JJ",
				value: "LATAM Airlines Brasil",
			},
			{
				label: "4C",
				value: "LATAM Airlines Colombia",
			},
			{
				label: "XL",
				value: "LATAM Airlines Ecuador",
			},
			{
				label: "LA",
				value: "LATAM Airlines Group",
			},
			{
				label: "PZ",
				value: "LATAM Airlines Paraguay",
			},
			{
				label: "LP",
				value: "LATAM Airlines Peru",
			},
			{
				label: "M3",
				value: "LATAM Cargo Brasil",
			},
			{
				label: "UC",
				value: "LATAM Cargo Chile",
			},
			{
				label: "LI",
				value: "LIAT Airlines",
			},
			{
				label: "LO",
				value: "LOT Polish Airlines",
			},
			{
				label: "QV",
				value: "Lao Airlines",
			},
			{
				label: "GJ",
				value: "Loong Air",
			},
			{
				label: "8L",
				value: "Lucky Air",
			},
			{
				label: "LH",
				value: "Lufthansa",
			},
			{
				label: "CL",
				value: "Lufthansa CityLine",
			},
			{
				label: "LG",
				value: "Luxair",
			},
			{
				label: "ME",
				value: "MEA",
			},
			{
				label: "OM",
				value: "MIAT Mongolian Airlines",
			},
			{
				label: "MB",
				value: "MNG Airlines",
			},
			{
				label: "W5",
				value: "Mahan Air",
			},
			{
				label: "MH",
				value: "Malaysia Airlines",
			},
			{
				label: "OD",
				value: "Malindo Air",
			},
			{
				label: "AE",
				value: "Mandarin Airlines",
			},
			{
				label: "YM",
				value: "Montenegro Airlines",
			},
			{
				label: "8M",
				value: "Myanmar Airways International",
			},
			{
				label: "KZ",
				value: "NCA Nippon Cargo Airlines",
			},
			{
				label: "NO",
				value: "Neos",
			},
			{
				label: "NE",
				value: "Nesma Airlines",
			},
			{
				label: "Y7",
				value: "NordStar",
			},
			{
				label: "5N",
				value: "Nordavia Regional Airlines",
			},
			{
				label: "BJ",
				value: "Nouvelair",
			},
			{
				label: "BK",
				value: "Okay Airways",
			},
			{
				label: "OA",
				value: "Olympic Air",
			},
			{
				label: "WY",
				value: "Oman Air",
			},
			{
				label: "8Q",
				value: "Onur Air",
			},
			{
				label: "OF",
				value: "Overland Airways",
			},
			{
				label: "NI",
				value: "PGA-Portugalia Airlines",
			},
			{
				label: "PK",
				value: "PIA Pakistan International Airlines",
			},
			{
				label: "ZP",
				value: "Paranair",
			},
			{
				label: "EO",
				value: "Pegas Fly",
			},
			{
				label: "PC",
				value: "Pegasus Airlines",
			},
			{
				label: "PR",
				value: "Philippine Airlines",
			},
			{
				label: "PO",
				value: "Polar Air Cargo",
			},
			{
				label: "PW",
				value: "Precision Air",
			},
			{
				label: "QF",
				value: "Qantas",
			},
			{
				label: "QR",
				value: "Qatar Airways",
			},
			{
				label: "IQ",
				value: "Qazaq Air",
			},
			{
				label: "FV",
				value: "Rossiya Airlines",
			},
			{
				label: "AT",
				value: "Royal Air Maroc",
			},
			{
				label: "BI",
				value: "Royal Brunei",
			},
			{
				label: "RJ",
				value: "Royal Jordanian",
			},
			{
				label: "DR",
				value: "Ruili Airlines",
			},
			{
				label: "7R",
				value: "RusLine",
			},
			{
				label: "S7",
				value: "S7 Airlines",
			},
			{
				label: "SK",
				value: "SAS",
			},
			{
				label: "SP",
				value: "SATA Air Acores",
			},
			{
				label: "DV",
				value: "SCAT Airlines",
			},
			{
				label: "H2",
				value: "SKY Airline",
			},
			{
				label: "LX",
				value: "SWISS",
			},
			{
				label: "FA",
				value: "Safair",
			},
			{
				label: "SV",
				value: "Saudi Arabian Airlines",
			},
			{
				label: "6S",
				value: "SaudiGulf Airlines",
			},
			{
				label: "SC",
				value: "Shandong Airlines",
			},
			{
				label: "FM",
				value: "Shanghai Airlines",
			},
			{
				label: "ZH",
				value: "Shenzhen Airlines",
			},
			{
				label: "3U",
				value: "Sichuan Airlines",
			},
			{
				label: "7L",
				value: "Silk Way West Airlines",
			},
			{
				label: "MI",
				value: "SilkAir",
			},
			{
				label: "SQ",
				value: "Singapore Airlines",
			},
			{
				label: "IE",
				value: "Solomon Airlines",
			},
			{
				label: "SA",
				value: "South African Airways",
			},
			{
				label: "SG",
				value: "SpiceJet",
			},
			{
				label: "UL",
				value: "SriLankan Airlines",
			},
			{
				label: "XQ",
				value: "SunExpress",
			},
			{
				label: "Y8",
				value: "Suparna Airlines",
			},
			{
				label: "PY",
				value: "Surinam Airways",
			},
			{
				label: "RB",
				value: "Syrianair",
			},
			{
				label: "DT",
				value: "TAAG Angola Airlines",
			},
			{
				label: "TA",
				value: "TACA",
			},
			{
				label: "TP",
				value: "TAP Portugal",
			},
			{
				label: "RO",
				value: "TAROM",
			},
			{
				label: "X3",
				value: "TUIfly",
			},
			{
				label: "SF",
				value: "Tassili Airlines",
			},
			{
				label: "TG",
				value: "Thai Airways International",
			},
			{
				label: "WE",
				value: "Thai Smile",
			},
			{
				label: "TU",
				value: "Tunisair",
			},
			{
				label: "TK",
				value: "Turkish Airlines",
			},
			{
				label: "B7",
				value: "UNI AIR",
			},
			{
				label: "5X",
				value: "UPS Airlines",
			},
			{
				label: "UT",
				value: "UTair",
			},
			{
				label: "PS",
				value: "Ukraine International Airlines",
			},
			{
				label: "UA",
				value: "United Airlines",
			},
			{
				label: "U6",
				value: "Ural Airlines",
			},
			{
				label: "UQ",
				value: "Urumqi Air",
			},
			{
				label: "HY",
				value: "Uzbekistan Airways",
			},
			{
				label: "VJ",
				value: "Vietjet",
			},
			{
				label: "VN",
				value: "Vietnam Airlines",
			},
			{
				label: "VS",
				value: "Virgin Atlantic",
			},
			{
				label: "VA",
				value: "Virgin Australia",
			},
			{
				label: "UK",
				value: "Vistara",
			},
			{
				label: "V7",
				value: "Volotea",
			},
			{
				label: "VY",
				value: "Vueling",
			},
			{
				label: "WS",
				value: "WestJet",
			},
			{
				label: "WI",
				value: "White coloured by you",
			},
			{
				label: "WF",
				value: "Wideroe",
			},
			{
				label: "MF",
				value: "Xiamen Airlines",
			},
			{
				label: "YG",
				value: "YTO Cargo Airlines",
			},
			{
				label: "FZ",
				value: "flydubai",
			},
		],
		CI: "China Airlines",
		CK: "China Cargo Airlines",
		CL: "Lufthansa CityLine",
		CM: "COPA Airlines",
		CU: "Cubana",
		CV: "Cargolux",
		CX: "Cathay Pacific",
		CY: "Cyprus Airways",
		CZ: "China Southern Airlines",
		D0: "DHL Air",
		DE: "Condor",
		DL: "Delta Air Lines",
		DR: "Ruili Airlines",
		DT: "TAAG Angola Airlines",
		DV: "SCAT Airlines",
		E6: "IndiGo",
		E9: "Evelop Airlines",
		EI: "Aer Lingus",
		EK: "Emirates",
		EN: "Air Dolomiti",
		EO: "Pegas Fly",
		EP: "Iran Aseman Airlines",
		ES: "DHL Aviation",
		ET: "Ethiopian Airlines",
		EW: "Eurowings",
		EY: "Etihad Airways",
		FA: "Safair",
		FB: "Bulgaria Air",
		FH: "Freebird Airlines",
		FI: "Icelandair",
		FJ: "Fiji Airways",
		FM: "Shanghai Airlines",
		FT: "FlyEgypt",
		FV: "Rossiya Airlines",
		FX: "FedEx Express",
		FZ: "flydubai",
		G3: "GOL Linhas Aereas",
		G5: "China Express Airlines",
		G9: "Air Arabia",
		GA: "Garuda Indonesia",
		GF: "Gulf Air",
		GJ: "Loong Air",
		GT: "Air Guilin",
		GX: "GX Airlines",
		H2: "SKY Airline",
		H6: "Israir",
		HA: "Hawaiian Airlines",
		HM: "Air Seychelles",
		HO: "Juneyao Airlines",
		HR: "Hahn Air",
		HU: "Hainan Airlines",
		HX: "Hong Kong Airlines",
		HY: "Uzbekistan Airways",
		IB: "IBERIA",
		ID: "Batik Air",
		IE: "Solomon Airlines",
		IQ: "Qazaq Air",
		IR: "Iran Air",
		IZ: "Arkia Israeli Airlines",
		J2: "Azerbaijan Airlines",
		J5: "Cebu Pacific",
		J9: "Jazeera Airways",
		JJ: "LATAM Airlines Brasil",
		JL: "Japan Airlines",
		JS: "Air Koryo",
		JU: "Air Serbia",
		K2: "Avianca Ecuador",
		K5: "Hi Fly",
		K6: "Cambodia Angkor Air",
		KC: "Air Astana",
		KE: "Korean Air",
		KL: "KLM",
		KM: "Air Malta",
		KP: "ASKY",
		KQ: "Kenya Airways",
		KU: "Kuwait Airways",
		KY: "Kunming Airlines",
		KZ: "NCA Nippon Cargo Airlines",
		L7: "Silk Way West Airlines",
		L8: "Lucky Air",
		LA: "LATAM Airlines Group",
		LG: "Luxair",
		LH: "Lufthansa",
		LI: "LIAT Airlines",
		LJ: "Jin Air",
		LO: "LOT Polish Airlines",
		LP: "LATAM Airlines Peru",
		LR: "Avianca Costa Rica",
		LX: "SWISS",
		LY: "EL AL",
		M3: "LATAM Cargo Brasil",
		M8: "Myanmar Airways International",
		MB: "MNG Airlines",
		MD: "Air Madagascar",
		ME: "MEA",
		MF: "Xiamen Airlines",
		MH: "Malaysia Airlines",
		MI: "SilkAir",
		MK: "Air Mauritius",
		MS: "Egyptair",
		MU: "China Eastern",
		N5: "Nordavia Regional Airlines",
		NE: "Nesma Airlines",
		NF: "Air Vanuatu",
		NH: "ANA",
		NI: "PGA-Portugalia Airlines",
		NO: "Neos",
		NT: "Binter Canarias",
		NU: "Japan Transocean Air",
		NX: "Air Macau",
		NZ: "Air New Zealand",
		O4: "Interjet",
		O5: "ASL Airlines France",
		OA: "Olympic Air",
		OD: "Malindo Air",
		OF: "Overland Airways",
		OK: "Czech Airlines",
		OM: "MIAT Mongolian Airlines",
		OS: "Austrian",
		OU: "Croatia Airlines",
		OZ: "Asiana Airlines",
		P4: "Air Peace",
		P5: "Aero Republica",
		PC: "Pegasus Airlines",
		PG: "Bangkok Airways",
		PK: "PIA Pakistan International Airlines",
		PO: "Polar Air Cargo",
		PR: "Philippine Airlines",
		PS: "Ukraine International Airlines",
		PW: "Precision Air",
		PX: "Air Niugini",
		PY: "Surinam Airways",
		PZ: "LATAM Airlines Paraguay",
		Q8: "Onur Air",
		QC: "Camair-Co",
		QF: "Qantas",
		QH: "Bamboo Airways",
		QR: "Qatar Airways",
		QV: "Lao Airlines",
		QY: "European Air Transport",
		R5: "Jordan Aviation",
		R7: "RusLine",
		RB: "Syrianair",
		RC: "Atlantic Airways",
		RH: "Hong Kong Air Cargo",
		RJ: "Royal Jordanian",
		RO: "TAROM",
		RQ: "Kam Air",
		RU: "AirBridgeCargo Airlines",
		S4: "Azores Airlines",
		S6: "SaudiGulf Airlines",
		S7: "S7 Airlines",
		SA: "South African Airways",
		SB: "Aircalin",
		SC: "Shandong Airlines",
		SF: "Tassili Airlines",
		SG: "SpiceJet",
		SK: "SAS",
		SN: "Brussels Airlines",
		SP: "SATA Air Acores",
		SQ: "Singapore Airlines",
		SS: "Corsair International",
		SU: "Aeroflot",
		SV: "Saudi Arabian Airlines",
		SW: "Air Namibia",
		T3: "Eastern Airways",
		TA: "TACA",
		TC: "Air Tanzania",
		TF: "Braathens Regional Airways",
		TG: "Thai Airways International",
		TK: "Turkish Airlines",
		TM: "LAM",
		TN: "Air Tahiti Nui",
		TP: "TAP Portugal",
		TS: "Air Transat",
		TU: "Tunisair",
		TX: "Air Caraibes",
		TY: "Air Caledonie",
		U3: "Sichuan Airlines",
		U6: "Ural Airlines",
		U9: "Air Moldova",
		UA: "United Airlines",
		UC: "LATAM Cargo Chile",
		UK: "Vistara",
		UL: "SriLankan Airlines",
		UO: "Hong Kong Express Airways",
		UP: "Bahamasair",
		UQ: "Urumqi Air",
		UT: "UTair",
		UU: "Air Austral",
		UX: "Air Europa",
		V3: "Carpatair",
		V7: "Volotea",
		VA: "Virgin Australia",
		VJ: "Vietjet",
		VN: "Vietnam Airlines",
		VS: "Virgin Atlantic",
		VT: "Air Tahiti",
		VW: "Aeromar",
		VY: "Vueling",
		W3: "Arik Air",
		W5: "Mahan Air",
		W8: "Cargojet Airways",
		WE: "Thai Smile",
		WF: "Wideroe",
		WI: "White coloured by you",
		WS: "WestJet",
		WX: "CityJet",
		WY: "Oman Air",
		X3: "TUIfly",
		X5: "UPS Airlines",
		XC: "Corendon Airlines",
		XK: "Air Corsica",
		XL: "LATAM Airlines Ecuador",
		XQ: "SunExpress",
		Y5: "Atlas Air",
		Y7: "NordStar",
		Y8: "Suparna Airlines",
		YG: "YTO Cargo Airlines",
		YM: "Montenegro Airlines",
		YW: "Air Nostrum",
		Z4: "Airlink",
		ZH: "Shenzhen Airlines",
		ZP: "Paranair",
	},
	BillingCurrency: {
		AED: 0,
		CHOICES: {
			0: "AED",
			48: "EUR",
			52: "GBP",
			157: "USD",
		},
		CHOICE_LIST: [
			{
				label: "AED",
				value: 0,
			},
			{
				label: "EUR",
				value: 48,
			},
			{
				label: "GBP",
				value: 52,
			},
			{
				label: "USD",
				value: 157,
			},
		],
		EUR: 48,
		GBP: 52,
		USD: 157,
	},
	BillingCycle: {
		CHOICES: {
			1: "Weekly",
			2: "Fortnightly",
			3: "Monthly",
		},
		CHOICE_LIST: [
			{
				label: "Weekly",
				value: 1,
			},
			{
				label: "Fortnightly",
				value: 2,
			},
			{
				label: "Monthly",
				value: 3,
			},
		],
		FORTNIGHTLY: 2,
		MONTHLY: 3,
		WEEKLY: 1,
	},
	BillingMethod: {
		CHOICES: {
			1: "Credit Card",
			2: "Manual",
		},
		CHOICE_LIST: [
			{
				label: "Credit Card",
				value: 1,
			},
			{
				label: "Manual",
				value: 2,
			},
		],
		CREDIT_CARD: 1,
		MANUAL: 2,
	},
	BillingTerm: {
		CHOICES: {
			1: "7 Days",
		},
		CHOICE_LIST: [
			{
				label: "7 Days",
				value: 1,
			},
		],
		DAYS_14: 1,
		DAYS_7: 1,
	},
	BillingUnit: {
		CHOICES: {
			1: "Per Ticket",
		},
		CHOICE_LIST: [
			{
				label: "Per Ticket",
				value: 1,
			},
		],
		PER_SEGMENT: 1,
		PER_TICKET: 1,
	},
	CardTypeKind: {
		CHOICES: {
			1: "FOO",
		},
		CHOICE_LIST: [
			{
				label: "FOO",
				value: 1,
			},
		],
		FOO: 1,
	},
	ContactKind: {
		CHOICES: {
			1: "Primary",
			2: "Finance",
			3: "Technical",
		},
		CHOICE_LIST: [
			{
				label: "Primary",
				value: 1,
			},
			{
				label: "Finance",
				value: 2,
			},
			{
				label: "Technical",
				value: 3,
			},
		],
		FINANCE: 2,
		PRIMARY: 1,
		TECHNICAL: 3,
	},
	Country: {
		CHOICES: {
			1: "QATAR",
			2: "UAE",
			3: "UK",
			4: "USA",
		},
		CHOICE_LIST: [
			{
				label: "QATAR",
				value: 1,
			},
			{
				label: "UAE",
				value: 2,
			},
			{
				label: "UK",
				value: 3,
			},
			{
				label: "USA",
				value: 4,
			},
		],
		QATAR: 1,
		UAE: 2,
		UK: 3,
		USA: 4,
	},
	CountryCodeAlpha2: {
		AFGHANISTAN: 1,
		ALAND_ISLANDS: 2,
		ALBANIA: 3,
		ALGERIA: 4,
		AMERICAN_SAMOA: 5,
		ANDORRA: 6,
		ANGOLA: 7,
		ANGUILLA: 8,
		ANTARCTICA: 9,
		ANTIGUA_AND_BARBUDA: 10,
		ARGENTINA: 11,
		ARMENIA: 12,
		ARUBA: 13,
		AUSTRALIA: 14,
		AUSTRIA: 15,
		AZERBAIJAN: 16,
		BAHAMAS: 17,
		BAHRAIN: 18,
		BANGLADESH: 19,
		BARBADOS: 20,
		BELARUS: 21,
		BELGIUM: 22,
		BELIZE: 23,
		BENIN: 24,
		BERMUDA: 25,
		BHUTAN: 26,
		BOLIVIA: 27,
		BONAIRE_SINT_EUSTATIUS_AND_SABA: 28,
		BOSNIA_AND_HERZEGOVINA: 29,
		BOTSWANA: 30,
		BOUVET_ISLAND: 31,
		BRAZIL: 32,
		BRITISH_INDIAN_OCEAN_TERRITORY: 33,
		BRITISH_VIRGIN_ISLANDS: 34,
		BRUNEI_DARUSSALAM: 35,
		BULGARIA: 36,
		BURKINA_FASO: 37,
		BURUNDI: 38,
		CAMBODIA: 39,
		CAMEROON: 40,
		CANADA: 41,
		CAPE_VERDE: 42,
		CAYMAN_ISLANDS: 43,
		CENTRAL_AFRICAN_REPUBLIC: 44,
		CHAD: 45,
		CHILE: 46,
		CHINA: 47,
		CHOICES: {
			1: "AF",
			2: "AX",
			3: "AL",
			4: "DZ",
			5: "AS",
			6: "AD",
			7: "AO",
			8: "AI",
			9: "AQ",
			10: "AG",
			11: "AR",
			12: "AM",
			13: "AW",
			14: "AU",
			15: "AT",
			16: "AZ",
			17: "BS",
			18: "BH",
			19: "BD",
			20: "BB",
			21: "BY",
			22: "BE",
			23: "BZ",
			24: "BJ",
			25: "BM",
			26: "BT",
			27: "BO",
			28: "BQ",
			29: "BA",
			30: "BW",
			31: "BV",
			32: "BR",
			33: "IO",
			34: "VG",
			35: "BN",
			36: "BG",
			37: "BF",
			38: "BI",
			39: "KH",
			40: "CM",
			41: "CA",
			42: "CV",
			43: "KY",
			44: "CF",
			45: "TD",
			46: "CL",
			47: "CN",
			48: "CX",
			49: "CC",
			50: "CO",
			51: "KM",
			52: "CK",
			53: "CR",
			54: "HR",
			55: "CU",
			56: "CW",
			57: "CY",
			58: "CZ",
			59: "CD",
			60: "DK",
			61: "DJ",
			62: "DM",
			63: "DO",
			64: "EC",
			65: "EG",
			66: "SV",
			67: "GQ",
			68: "ER",
			69: "EE",
			70: "ET",
			71: "FK",
			72: "FO",
			73: "FJ",
			74: "FI",
			75: "FR",
			76: "GF",
			77: "PF",
			78: "TF",
			79: "GA",
			80: "GM",
			81: "GE",
			82: "DE",
			83: "GH",
			84: "GI",
			85: "GR",
			86: "GL",
			87: "GD",
			88: "GP",
			89: "GU",
			90: "GT",
			91: "GG",
			92: "GN",
			93: "GW",
			94: "GY",
			95: "HT",
			96: "HM",
			97: "HN",
			98: "HK",
			99: "HU",
			100: "IS",
			101: "IN",
			102: "ID",
			103: "IR",
			104: "IQ",
			105: "IE",
			106: "IM",
			107: "IL",
			108: "IT",
			109: "CI",
			110: "JM",
			111: "JP",
			112: "JE",
			113: "JO",
			114: "KZ",
			115: "KE",
			116: "KI",
			117: "KW",
			118: "KG",
			119: "LA",
			120: "LV",
			121: "LB",
			122: "LS",
			123: "LR",
			124: "LY",
			125: "LI",
			126: "LT",
			127: "LU",
			128: "MO",
			129: "MK",
			130: "MG",
			131: "MW",
			132: "MY",
			133: "MV",
			134: "ML",
			135: "MT",
			136: "MH",
			137: "MQ",
			138: "MR",
			139: "MU",
			140: "YT",
			141: "MX",
			142: "FM",
			143: "MD",
			144: "MC",
			145: "MN",
			147: "MS",
			148: "MA",
			149: "MZ",
			150: "MM",
			151: "nan",
			152: "NR",
			153: "NP",
			154: "NL",
			155: "AN",
			156: "NC",
			157: "NZ",
			158: "NI",
			159: "NE",
			160: "NG",
			161: "NU",
			162: "NF",
			163: "KP",
			164: "MP",
			165: "NO",
			166: "OM",
			167: "PK",
			168: "PW",
			169: "PS",
			170: "PA",
			171: "PG",
			172: "PY",
			173: "PE",
			174: "PH",
			175: "PN",
			176: "PL",
			177: "PT",
			178: "PR",
			179: "QA",
			180: "CG",
			181: "RE",
			182: "RO",
			183: "RU",
			184: "RW",
			185: "BL",
			186: "KN",
			187: "LC",
			188: "MF",
			189: "VC",
			190: "WS",
			191: "SM",
			192: "ST",
			193: "SA",
			194: "SN",
			195: "RS",
			196: "ME",
			197: "SC",
			198: "SL",
			199: "SG",
			200: "SX",
			201: "SK",
			202: "SI",
			203: "SB",
			204: "SO",
			205: "ZA",
			206: "GS",
			207: "KR",
			208: "SS",
			209: "ES",
			210: "LK",
			211: "SH",
			212: "PM",
			213: "SD",
			214: "SR",
			215: "SJ",
			216: "SZ",
			217: "SE",
			218: "CH",
			219: "SY",
			220: "TW",
			221: "TJ",
			222: "TZ",
			223: "TH",
			224: "TL",
			225: "TG",
			226: "TK",
			227: "TO",
			228: "TT",
			229: "TN",
			230: "TR",
			231: "TM",
			232: "TC",
			233: "TV",
			234: "UG",
			235: "UA",
			236: "AE",
			237: "UK",
			238: "US",
			239: "UY",
			240: "UM",
			241: "VI",
			242: "UZ",
			243: "VU",
			244: "VA",
			245: "VE",
			246: "VN",
			247: "WF",
			248: "EH",
			249: "YE",
			250: "ZM",
			251: "ZW",
		},
		CHOICE_LIST: [
			{
				label: "AF",
				value: 1,
			},
			{
				label: "AX",
				value: 2,
			},
			{
				label: "AL",
				value: 3,
			},
			{
				label: "DZ",
				value: 4,
			},
			{
				label: "AS",
				value: 5,
			},
			{
				label: "AD",
				value: 6,
			},
			{
				label: "AO",
				value: 7,
			},
			{
				label: "AI",
				value: 8,
			},
			{
				label: "AQ",
				value: 9,
			},
			{
				label: "AG",
				value: 10,
			},
			{
				label: "AR",
				value: 11,
			},
			{
				label: "AM",
				value: 12,
			},
			{
				label: "AW",
				value: 13,
			},
			{
				label: "AU",
				value: 14,
			},
			{
				label: "AT",
				value: 15,
			},
			{
				label: "AZ",
				value: 16,
			},
			{
				label: "BS",
				value: 17,
			},
			{
				label: "BH",
				value: 18,
			},
			{
				label: "BD",
				value: 19,
			},
			{
				label: "BB",
				value: 20,
			},
			{
				label: "BY",
				value: 21,
			},
			{
				label: "BE",
				value: 22,
			},
			{
				label: "BZ",
				value: 23,
			},
			{
				label: "BJ",
				value: 24,
			},
			{
				label: "BM",
				value: 25,
			},
			{
				label: "BT",
				value: 26,
			},
			{
				label: "BO",
				value: 27,
			},
			{
				label: "BQ",
				value: 28,
			},
			{
				label: "BA",
				value: 29,
			},
			{
				label: "BW",
				value: 30,
			},
			{
				label: "BV",
				value: 31,
			},
			{
				label: "BR",
				value: 32,
			},
			{
				label: "IO",
				value: 33,
			},
			{
				label: "VG",
				value: 34,
			},
			{
				label: "BN",
				value: 35,
			},
			{
				label: "BG",
				value: 36,
			},
			{
				label: "BF",
				value: 37,
			},
			{
				label: "BI",
				value: 38,
			},
			{
				label: "KH",
				value: 39,
			},
			{
				label: "CM",
				value: 40,
			},
			{
				label: "CA",
				value: 41,
			},
			{
				label: "CV",
				value: 42,
			},
			{
				label: "KY",
				value: 43,
			},
			{
				label: "CF",
				value: 44,
			},
			{
				label: "TD",
				value: 45,
			},
			{
				label: "CL",
				value: 46,
			},
			{
				label: "CN",
				value: 47,
			},
			{
				label: "CX",
				value: 48,
			},
			{
				label: "CC",
				value: 49,
			},
			{
				label: "CO",
				value: 50,
			},
			{
				label: "KM",
				value: 51,
			},
			{
				label: "CK",
				value: 52,
			},
			{
				label: "CR",
				value: 53,
			},
			{
				label: "HR",
				value: 54,
			},
			{
				label: "CU",
				value: 55,
			},
			{
				label: "CW",
				value: 56,
			},
			{
				label: "CY",
				value: 57,
			},
			{
				label: "CZ",
				value: 58,
			},
			{
				label: "CD",
				value: 59,
			},
			{
				label: "DK",
				value: 60,
			},
			{
				label: "DJ",
				value: 61,
			},
			{
				label: "DM",
				value: 62,
			},
			{
				label: "DO",
				value: 63,
			},
			{
				label: "EC",
				value: 64,
			},
			{
				label: "EG",
				value: 65,
			},
			{
				label: "SV",
				value: 66,
			},
			{
				label: "GQ",
				value: 67,
			},
			{
				label: "ER",
				value: 68,
			},
			{
				label: "EE",
				value: 69,
			},
			{
				label: "ET",
				value: 70,
			},
			{
				label: "FK",
				value: 71,
			},
			{
				label: "FO",
				value: 72,
			},
			{
				label: "FJ",
				value: 73,
			},
			{
				label: "FI",
				value: 74,
			},
			{
				label: "FR",
				value: 75,
			},
			{
				label: "GF",
				value: 76,
			},
			{
				label: "PF",
				value: 77,
			},
			{
				label: "TF",
				value: 78,
			},
			{
				label: "GA",
				value: 79,
			},
			{
				label: "GM",
				value: 80,
			},
			{
				label: "GE",
				value: 81,
			},
			{
				label: "DE",
				value: 82,
			},
			{
				label: "GH",
				value: 83,
			},
			{
				label: "GI",
				value: 84,
			},
			{
				label: "GR",
				value: 85,
			},
			{
				label: "GL",
				value: 86,
			},
			{
				label: "GD",
				value: 87,
			},
			{
				label: "GP",
				value: 88,
			},
			{
				label: "GU",
				value: 89,
			},
			{
				label: "GT",
				value: 90,
			},
			{
				label: "GG",
				value: 91,
			},
			{
				label: "GN",
				value: 92,
			},
			{
				label: "GW",
				value: 93,
			},
			{
				label: "GY",
				value: 94,
			},
			{
				label: "HT",
				value: 95,
			},
			{
				label: "HM",
				value: 96,
			},
			{
				label: "HN",
				value: 97,
			},
			{
				label: "HK",
				value: 98,
			},
			{
				label: "HU",
				value: 99,
			},
			{
				label: "IS",
				value: 100,
			},
			{
				label: "IN",
				value: 101,
			},
			{
				label: "ID",
				value: 102,
			},
			{
				label: "IR",
				value: 103,
			},
			{
				label: "IQ",
				value: 104,
			},
			{
				label: "IE",
				value: 105,
			},
			{
				label: "IM",
				value: 106,
			},
			{
				label: "IL",
				value: 107,
			},
			{
				label: "IT",
				value: 108,
			},
			{
				label: "CI",
				value: 109,
			},
			{
				label: "JM",
				value: 110,
			},
			{
				label: "JP",
				value: 111,
			},
			{
				label: "JE",
				value: 112,
			},
			{
				label: "JO",
				value: 113,
			},
			{
				label: "KZ",
				value: 114,
			},
			{
				label: "KE",
				value: 115,
			},
			{
				label: "KI",
				value: 116,
			},
			{
				label: "KW",
				value: 117,
			},
			{
				label: "KG",
				value: 118,
			},
			{
				label: "LA",
				value: 119,
			},
			{
				label: "LV",
				value: 120,
			},
			{
				label: "LB",
				value: 121,
			},
			{
				label: "LS",
				value: 122,
			},
			{
				label: "LR",
				value: 123,
			},
			{
				label: "LY",
				value: 124,
			},
			{
				label: "LI",
				value: 125,
			},
			{
				label: "LT",
				value: 126,
			},
			{
				label: "LU",
				value: 127,
			},
			{
				label: "MO",
				value: 128,
			},
			{
				label: "MK",
				value: 129,
			},
			{
				label: "MG",
				value: 130,
			},
			{
				label: "MW",
				value: 131,
			},
			{
				label: "MY",
				value: 132,
			},
			{
				label: "MV",
				value: 133,
			},
			{
				label: "ML",
				value: 134,
			},
			{
				label: "MT",
				value: 135,
			},
			{
				label: "MH",
				value: 136,
			},
			{
				label: "MQ",
				value: 137,
			},
			{
				label: "MR",
				value: 138,
			},
			{
				label: "MU",
				value: 139,
			},
			{
				label: "YT",
				value: 140,
			},
			{
				label: "MX",
				value: 141,
			},
			{
				label: "FM",
				value: 142,
			},
			{
				label: "MD",
				value: 143,
			},
			{
				label: "MC",
				value: 144,
			},
			{
				label: "MN",
				value: 145,
			},
			{
				label: "MS",
				value: 147,
			},
			{
				label: "MA",
				value: 148,
			},
			{
				label: "MZ",
				value: 149,
			},
			{
				label: "MM",
				value: 150,
			},
			{
				label: "nan",
				value: 151,
			},
			{
				label: "NR",
				value: 152,
			},
			{
				label: "NP",
				value: 153,
			},
			{
				label: "NL",
				value: 154,
			},
			{
				label: "AN",
				value: 155,
			},
			{
				label: "NC",
				value: 156,
			},
			{
				label: "NZ",
				value: 157,
			},
			{
				label: "NI",
				value: 158,
			},
			{
				label: "NE",
				value: 159,
			},
			{
				label: "NG",
				value: 160,
			},
			{
				label: "NU",
				value: 161,
			},
			{
				label: "NF",
				value: 162,
			},
			{
				label: "KP",
				value: 163,
			},
			{
				label: "MP",
				value: 164,
			},
			{
				label: "NO",
				value: 165,
			},
			{
				label: "OM",
				value: 166,
			},
			{
				label: "PK",
				value: 167,
			},
			{
				label: "PW",
				value: 168,
			},
			{
				label: "PS",
				value: 169,
			},
			{
				label: "PA",
				value: 170,
			},
			{
				label: "PG",
				value: 171,
			},
			{
				label: "PY",
				value: 172,
			},
			{
				label: "PE",
				value: 173,
			},
			{
				label: "PH",
				value: 174,
			},
			{
				label: "PN",
				value: 175,
			},
			{
				label: "PL",
				value: 176,
			},
			{
				label: "PT",
				value: 177,
			},
			{
				label: "PR",
				value: 178,
			},
			{
				label: "QA",
				value: 179,
			},
			{
				label: "CG",
				value: 180,
			},
			{
				label: "RE",
				value: 181,
			},
			{
				label: "RO",
				value: 182,
			},
			{
				label: "RU",
				value: 183,
			},
			{
				label: "RW",
				value: 184,
			},
			{
				label: "BL",
				value: 185,
			},
			{
				label: "KN",
				value: 186,
			},
			{
				label: "LC",
				value: 187,
			},
			{
				label: "MF",
				value: 188,
			},
			{
				label: "VC",
				value: 189,
			},
			{
				label: "WS",
				value: 190,
			},
			{
				label: "SM",
				value: 191,
			},
			{
				label: "ST",
				value: 192,
			},
			{
				label: "SA",
				value: 193,
			},
			{
				label: "SN",
				value: 194,
			},
			{
				label: "RS",
				value: 195,
			},
			{
				label: "ME",
				value: 196,
			},
			{
				label: "SC",
				value: 197,
			},
			{
				label: "SL",
				value: 198,
			},
			{
				label: "SG",
				value: 199,
			},
			{
				label: "SX",
				value: 200,
			},
			{
				label: "SK",
				value: 201,
			},
			{
				label: "SI",
				value: 202,
			},
			{
				label: "SB",
				value: 203,
			},
			{
				label: "SO",
				value: 204,
			},
			{
				label: "ZA",
				value: 205,
			},
			{
				label: "GS",
				value: 206,
			},
			{
				label: "KR",
				value: 207,
			},
			{
				label: "SS",
				value: 208,
			},
			{
				label: "ES",
				value: 209,
			},
			{
				label: "LK",
				value: 210,
			},
			{
				label: "SH",
				value: 211,
			},
			{
				label: "PM",
				value: 212,
			},
			{
				label: "SD",
				value: 213,
			},
			{
				label: "SR",
				value: 214,
			},
			{
				label: "SJ",
				value: 215,
			},
			{
				label: "SZ",
				value: 216,
			},
			{
				label: "SE",
				value: 217,
			},
			{
				label: "CH",
				value: 218,
			},
			{
				label: "SY",
				value: 219,
			},
			{
				label: "TW",
				value: 220,
			},
			{
				label: "TJ",
				value: 221,
			},
			{
				label: "TZ",
				value: 222,
			},
			{
				label: "TH",
				value: 223,
			},
			{
				label: "TL",
				value: 224,
			},
			{
				label: "TG",
				value: 225,
			},
			{
				label: "TK",
				value: 226,
			},
			{
				label: "TO",
				value: 227,
			},
			{
				label: "TT",
				value: 228,
			},
			{
				label: "TN",
				value: 229,
			},
			{
				label: "TR",
				value: 230,
			},
			{
				label: "TM",
				value: 231,
			},
			{
				label: "TC",
				value: 232,
			},
			{
				label: "TV",
				value: 233,
			},
			{
				label: "UG",
				value: 234,
			},
			{
				label: "UA",
				value: 235,
			},
			{
				label: "AE",
				value: 236,
			},
			{
				label: "UK",
				value: 237,
			},
			{
				label: "US",
				value: 238,
			},
			{
				label: "UY",
				value: 239,
			},
			{
				label: "UM",
				value: 240,
			},
			{
				label: "VI",
				value: 241,
			},
			{
				label: "UZ",
				value: 242,
			},
			{
				label: "VU",
				value: 243,
			},
			{
				label: "VA",
				value: 244,
			},
			{
				label: "VE",
				value: 245,
			},
			{
				label: "VN",
				value: 246,
			},
			{
				label: "WF",
				value: 247,
			},
			{
				label: "EH",
				value: 248,
			},
			{
				label: "YE",
				value: 249,
			},
			{
				label: "ZM",
				value: 250,
			},
			{
				label: "ZW",
				value: 251,
			},
		],
		CHRISTMAS_ISLAND: 48,
		COCOS_ISLANDS: 49,
		COLOMBIA: 50,
		COMOROS: 51,
		COOK_ISLANDS: 52,
		COSTA_RICA: 53,
		CROATIA: 54,
		CUBA: 55,
		CURACAO: 56,
		CYPRUS: 57,
		CZECH_REPUBLIC: 58,
		DEMOCRATIC_REPUBLIC_OF_THE_CONGO: 59,
		DENMARK: 60,
		DJIBOUTI: 61,
		DOMINICA: 62,
		DOMINICAN_REPUBLIC: 63,
		ECUADOR: 64,
		EGYPT: 65,
		EL_SALVADOR: 66,
		EQUATORIAL_GUINEA: 67,
		ERITREA: 68,
		ESTONIA: 69,
		ETHIOPIA: 70,
		FALKLAND_ISLANDS: 71,
		FAROE_ISLANDS: 72,
		FIJI: 73,
		FINLAND: 74,
		FRANCE: 75,
		FRENCH_GUIANA: 76,
		FRENCH_POLYNESIA: 77,
		FRENCH_SOUTHERN_AND_ANTARCTIC_TERRITORIES: 78,
		GABON: 79,
		GAMBIA: 80,
		GEORGIA: 81,
		GERMANY: 82,
		GHANA: 83,
		GIBRALTAR: 84,
		GREECE: 85,
		GREENLAND: 86,
		GRENADA: 87,
		GUADELOUPE: 88,
		GUAM: 89,
		GUATEMALA: 90,
		GUERNSEY: 91,
		GUINEA: 92,
		GUINEA_BISSAU: 93,
		GUYANA: 94,
		HAITI: 95,
		HEARD_AND_MCDONALD_ISLANDS: 96,
		HONDURAS: 97,
		HONG_KONG: 98,
		HUNGARY: 99,
		ICELAND: 100,
		INDIA: 101,
		INDONESIA: 102,
		IRAN: 103,
		IRAQ: 104,
		IRELAND: 105,
		ISLE_OF_MAN: 106,
		ISRAEL: 107,
		ITALY: 108,
		IVORY_COAST: 109,
		JAMAICA: 110,
		JAPAN: 111,
		JERSEY: 112,
		JORDAN: 113,
		KAZAKHSTAN: 114,
		KENYA: 115,
		KIRIBATI: 116,
		KUWAIT: 117,
		KYRGYZSTAN: 118,
		LAOS: 119,
		LATVIA: 120,
		LEBANON: 121,
		LESOTHO: 122,
		LIBERIA: 123,
		LIBYAN_ARAB_JAMAHIRIYA: 124,
		LIECHTENSTEIN: 125,
		LITHUANIA: 126,
		LUXEMBOURG: 127,
		MACAU: 128,
		MACEDONIA: 129,
		MADAGASCAR: 130,
		MALAWI: 131,
		MALAYSIA: 132,
		MALDIVES: 133,
		MALI: 134,
		MALTA: 135,
		MARSHALL_ISLANDS: 136,
		MARTINIQUE: 137,
		MAURITANIA: 138,
		MAURITIUS: 139,
		MAYOTTE: 140,
		MEXICO: 141,
		MICRONESIA: 142,
		MOLDOVA: 143,
		MONACO: 144,
		MONGOLIA: 145,
		MONTENEGRO: 196,
		MONTSERRAT: 147,
		MOROCCO: 148,
		MOZAMBIQUE: 149,
		MYANMAR: 150,
		NAMIBIA: 151,
		NAURU: 152,
		NEPAL: 153,
		NETHERLANDS: 154,
		NETHERLANDS_ANTILLES: 155,
		NEW_CALEDONIA: 156,
		NEW_ZEALAND: 157,
		NICARAGUA: 158,
		NIGER: 159,
		NIGERIA: 160,
		NIUE: 161,
		NORFOLK_ISLAND: 162,
		NORTHERN_MARIANA_ISLANDS: 164,
		NORTH_KOREA: 163,
		NORWAY: 165,
		OMAN: 166,
		PAKISTAN: 167,
		PALAU: 168,
		PALESTINIAN_TERRITORY: 169,
		PANAMA: 170,
		PAPUA_NEW_GUINEA: 171,
		PARAGUAY: 172,
		PERU: 173,
		PHILIPPINES: 174,
		PITCAIRN_ISLAND: 175,
		POLAND: 176,
		PORTUGAL: 177,
		PUERTO_RICO: 178,
		QATAR: 179,
		REPUBLIC_OF_THE_CONGO: 180,
		REUNION: 181,
		ROMANIA: 182,
		RUSSIA: 183,
		RWANDA: 184,
		SAINT_BARTHELEMY: 185,
		SAINT_KITTS_AND_NEVIS: 186,
		SAINT_LUCIA: 187,
		SAINT_MARTIN: 188,
		SAINT_VINCENT_AND_THE_GRENADINES: 189,
		SAMOA: 190,
		SAN_MARINO: 191,
		SAO_TOME_AND_PRINCIPE: 192,
		SAUDI_ARABIA: 193,
		SENEGAL: 194,
		SERBIA: 195,
		SEYCHELLES: 197,
		SIERRA_LEONE: 198,
		SINGAPORE: 199,
		SINT_MAARTEN: 200,
		SLOVAKIA: 201,
		SLOVENIA: 202,
		SOLOMON_ISLANDS: 203,
		SOMALIA: 204,
		SOUTH_AFRICA: 205,
		SOUTH_GEORGIA_AND_THE_SOUTH_SANDWICH_ISLANDS: 206,
		SOUTH_KOREA: 207,
		SOUTH_SUDAN: 208,
		SPAIN: 209,
		SRI_LANKA: 210,
		ST_HELENA: 211,
		ST_PIERRE_AND_MIQUELON: 212,
		SUDAN: 213,
		SURINAME: 214,
		SVALBARD_AND_JAN_MAYEN: 215,
		SWAZILAND: 216,
		SWEDEN: 217,
		SWITZERLAND: 218,
		SYRIAN_ARAB_REPUBLIC: 219,
		TAIWAN: 220,
		TAJIKISTAN: 221,
		TANZANIA: 222,
		THAILAND: 223,
		TIMOR_LESTE: 224,
		TOGO: 225,
		TOKELAU: 226,
		TONGA: 227,
		TRINIDAD_AND_TOBAGO: 228,
		TUNISIA: 229,
		TURKEY: 230,
		TURKMENISTAN: 231,
		TURKS_AND_CAICOS_ISLANDS: 232,
		TUVALU: 233,
		UGANDA: 234,
		UKRAINE: 235,
		UNITED_ARAB_EMIRATES: 236,
		UNITED_KINGDOM: 237,
		UNITED_STATES: 238,
		URUGUAY: 239,
		US_MINOR_OUTLYING_ISLANDS: 240,
		US_VIRGIN_ISLANDS: 241,
		UZBEKISTAN: 242,
		VANUATU: 243,
		VATICAN_CITY: 244,
		VENEZUELA: 245,
		VIETNAM: 246,
		WALLIS_AND_FUTUNA: 247,
		WESTERN_SAHARA: 248,
		YEMEN: 249,
		ZAMBIA: 250,
		ZIMBABWE: 251,
	},
	CountryCodeAlpha3: {
		AFGHANISTAN: 1,
		ALAND_ISLANDS: 2,
		ALBANIA: 3,
		ALGERIA: 4,
		AMERICAN_SAMOA: 5,
		ANDORRA: 6,
		ANGOLA: 7,
		ANGUILLA: 8,
		ANTARCTICA: 9,
		ANTIGUA_AND_BARBUDA: 10,
		ARGENTINA: 11,
		ARMENIA: 12,
		ARUBA: 13,
		AUSTRALIA: 14,
		AUSTRIA: 15,
		AZERBAIJAN: 16,
		BAHAMAS: 17,
		BAHRAIN: 18,
		BANGLADESH: 19,
		BARBADOS: 20,
		BELARUS: 21,
		BELGIUM: 22,
		BELIZE: 23,
		BENIN: 24,
		BERMUDA: 25,
		BHUTAN: 26,
		BOLIVIA: 27,
		BONAIRE_SINT_EUSTATIUS_AND_SABA: 28,
		BOSNIA_AND_HERZEGOVINA: 29,
		BOTSWANA: 30,
		BOUVET_ISLAND: 31,
		BRAZIL: 32,
		BRITISH_INDIAN_OCEAN_TERRITORY: 33,
		BRITISH_VIRGIN_ISLANDS: 34,
		BRUNEI_DARUSSALAM: 35,
		BULGARIA: 36,
		BURKINA_FASO: 37,
		BURUNDI: 38,
		CAMBODIA: 39,
		CAMEROON: 40,
		CANADA: 41,
		CAPE_VERDE: 42,
		CAYMAN_ISLANDS: 43,
		CENTRAL_AFRICAN_REPUBLIC: 44,
		CHAD: 45,
		CHILE: 46,
		CHINA: 47,
		CHOICES: {
			1: "AFG",
			2: "ALA",
			3: "ALB",
			4: "DZA",
			5: "ASM",
			6: "AND",
			7: "AGO",
			8: "AIA",
			9: "ATA",
			10: "ATG",
			11: "ARG",
			12: "ARM",
			13: "ABW",
			14: "AUS",
			15: "AUT",
			16: "AZE",
			17: "BHS",
			18: "BHR",
			19: "BGD",
			20: "BRB",
			21: "BLR",
			22: "BEL",
			23: "BLZ",
			24: "BEN",
			25: "BMU",
			26: "BTN",
			27: "BOL",
			28: "BES",
			29: "BIH",
			30: "BWA",
			31: "BVT",
			32: "BRA",
			33: "IOT",
			34: "VGB",
			35: "BRN",
			36: "BGR",
			37: "BFA",
			38: "BDI",
			39: "KHM",
			40: "CMR",
			41: "CAN",
			42: "CPV",
			43: "CYM",
			44: "CAF",
			45: "TCD",
			46: "CHL",
			47: "CHN",
			48: "CXR",
			49: "CCK",
			50: "COL",
			51: "COM",
			52: "COK",
			53: "CRI",
			54: "HRV",
			55: "CUB",
			56: "CUW",
			57: "CYP",
			58: "CZE",
			59: "COD",
			60: "DNK",
			61: "DJI",
			62: "DMA",
			63: "DOM",
			64: "ECU",
			65: "EGY",
			66: "SLV",
			67: "GNQ",
			68: "ERI",
			69: "EST",
			70: "ETH",
			71: "FLK",
			72: "FRO",
			73: "FJI",
			74: "FIN",
			75: "FRA",
			76: "GUF",
			77: "PYF",
			78: "ATF",
			79: "GAB",
			80: "GMB",
			81: "GEO",
			82: "DEU",
			83: "GHA",
			84: "GIB",
			85: "GRC",
			86: "GRL",
			87: "GRD",
			88: "GLP",
			89: "GUM",
			90: "GTM",
			91: "GGY",
			92: "GIN",
			93: "GNB",
			94: "GUY",
			95: "HTI",
			96: "HMD",
			97: "HND",
			98: "HKG",
			99: "HUN",
			100: "ISL",
			101: "IND",
			102: "IDN",
			103: "IRN",
			104: "IRQ",
			105: "IRL",
			106: "IMN",
			107: "ISR",
			108: "ITA",
			109: "CIV",
			110: "JAM",
			111: "JPN",
			112: "JEY",
			113: "JOR",
			114: "KAZ",
			115: "KEN",
			116: "KIR",
			117: "KWT",
			118: "KGZ",
			119: "LAO",
			120: "LVA",
			121: "LBN",
			122: "LSO",
			123: "LBR",
			124: "LBY",
			125: "LIE",
			126: "LTU",
			127: "LUX",
			128: "MAC",
			129: "MKD",
			130: "MDG",
			131: "MWI",
			132: "MYS",
			133: "MDV",
			134: "MLI",
			135: "MLT",
			136: "MHL",
			137: "MTQ",
			138: "MRT",
			139: "MUS",
			140: "MYT",
			141: "MEX",
			142: "FSM",
			143: "MDA",
			144: "MCO",
			145: "MNG",
			147: "MSR",
			148: "MAR",
			149: "MOZ",
			150: "MMR",
			151: "NAM",
			152: "NRU",
			153: "NPL",
			154: "NLD",
			155: "ANT",
			156: "NCL",
			157: "NZL",
			158: "NIC",
			159: "NER",
			160: "NGA",
			161: "NIU",
			162: "NFK",
			163: "PRK",
			164: "MNP",
			165: "NOR",
			166: "OMN",
			167: "PAK",
			168: "PLW",
			169: "PSE",
			170: "PAN",
			171: "PNG",
			172: "PRY",
			173: "PER",
			174: "PHL",
			175: "PCN",
			176: "POL",
			177: "PRT",
			178: "PRI",
			179: "QAT",
			180: "COG",
			181: "REU",
			182: "ROU",
			183: "RUS",
			184: "RWA",
			185: "BLM",
			186: "KNA",
			187: "LCA",
			188: "MAF",
			189: "VCT",
			190: "WSM",
			191: "SMR",
			192: "STP",
			193: "SAU",
			194: "SEN",
			195: "SRB",
			196: "MNE",
			197: "SYC",
			198: "SLE",
			199: "SGP",
			200: "SXM",
			201: "SVK",
			202: "SVN",
			203: "SLB",
			204: "SOM",
			205: "ZAF",
			206: "SGS",
			207: "KOR",
			208: "SSD",
			209: "ESP",
			210: "LKA",
			211: "SHN",
			212: "SPM",
			213: "SDN",
			214: "SUR",
			215: "SJM",
			216: "SWZ",
			217: "SWE",
			218: "CHE",
			219: "SYR",
			220: "TWN",
			221: "TJK",
			222: "TZA",
			223: "THA",
			224: "TLS",
			225: "TGO",
			226: "TKL",
			227: "TON",
			228: "TTO",
			229: "TUN",
			230: "TUR",
			231: "TKM",
			232: "TCA",
			233: "TUV",
			234: "UGA",
			235: "UKR",
			236: "ARE",
			237: "GBR",
			238: "USA",
			239: "URY",
			240: "UMI",
			241: "VIR",
			242: "UZB",
			243: "VUT",
			244: "VAT",
			245: "VEN",
			246: "VNM",
			247: "WLF",
			248: "ESH",
			249: "YEM",
			250: "ZMB",
			251: "ZWE",
		},
		CHOICE_LIST: [
			{
				label: "AFG",
				value: 1,
			},
			{
				label: "ALA",
				value: 2,
			},
			{
				label: "ALB",
				value: 3,
			},
			{
				label: "DZA",
				value: 4,
			},
			{
				label: "ASM",
				value: 5,
			},
			{
				label: "AND",
				value: 6,
			},
			{
				label: "AGO",
				value: 7,
			},
			{
				label: "AIA",
				value: 8,
			},
			{
				label: "ATA",
				value: 9,
			},
			{
				label: "ATG",
				value: 10,
			},
			{
				label: "ARG",
				value: 11,
			},
			{
				label: "ARM",
				value: 12,
			},
			{
				label: "ABW",
				value: 13,
			},
			{
				label: "AUS",
				value: 14,
			},
			{
				label: "AUT",
				value: 15,
			},
			{
				label: "AZE",
				value: 16,
			},
			{
				label: "BHS",
				value: 17,
			},
			{
				label: "BHR",
				value: 18,
			},
			{
				label: "BGD",
				value: 19,
			},
			{
				label: "BRB",
				value: 20,
			},
			{
				label: "BLR",
				value: 21,
			},
			{
				label: "BEL",
				value: 22,
			},
			{
				label: "BLZ",
				value: 23,
			},
			{
				label: "BEN",
				value: 24,
			},
			{
				label: "BMU",
				value: 25,
			},
			{
				label: "BTN",
				value: 26,
			},
			{
				label: "BOL",
				value: 27,
			},
			{
				label: "BES",
				value: 28,
			},
			{
				label: "BIH",
				value: 29,
			},
			{
				label: "BWA",
				value: 30,
			},
			{
				label: "BVT",
				value: 31,
			},
			{
				label: "BRA",
				value: 32,
			},
			{
				label: "IOT",
				value: 33,
			},
			{
				label: "VGB",
				value: 34,
			},
			{
				label: "BRN",
				value: 35,
			},
			{
				label: "BGR",
				value: 36,
			},
			{
				label: "BFA",
				value: 37,
			},
			{
				label: "BDI",
				value: 38,
			},
			{
				label: "KHM",
				value: 39,
			},
			{
				label: "CMR",
				value: 40,
			},
			{
				label: "CAN",
				value: 41,
			},
			{
				label: "CPV",
				value: 42,
			},
			{
				label: "CYM",
				value: 43,
			},
			{
				label: "CAF",
				value: 44,
			},
			{
				label: "TCD",
				value: 45,
			},
			{
				label: "CHL",
				value: 46,
			},
			{
				label: "CHN",
				value: 47,
			},
			{
				label: "CXR",
				value: 48,
			},
			{
				label: "CCK",
				value: 49,
			},
			{
				label: "COL",
				value: 50,
			},
			{
				label: "COM",
				value: 51,
			},
			{
				label: "COK",
				value: 52,
			},
			{
				label: "CRI",
				value: 53,
			},
			{
				label: "HRV",
				value: 54,
			},
			{
				label: "CUB",
				value: 55,
			},
			{
				label: "CUW",
				value: 56,
			},
			{
				label: "CYP",
				value: 57,
			},
			{
				label: "CZE",
				value: 58,
			},
			{
				label: "COD",
				value: 59,
			},
			{
				label: "DNK",
				value: 60,
			},
			{
				label: "DJI",
				value: 61,
			},
			{
				label: "DMA",
				value: 62,
			},
			{
				label: "DOM",
				value: 63,
			},
			{
				label: "ECU",
				value: 64,
			},
			{
				label: "EGY",
				value: 65,
			},
			{
				label: "SLV",
				value: 66,
			},
			{
				label: "GNQ",
				value: 67,
			},
			{
				label: "ERI",
				value: 68,
			},
			{
				label: "EST",
				value: 69,
			},
			{
				label: "ETH",
				value: 70,
			},
			{
				label: "FLK",
				value: 71,
			},
			{
				label: "FRO",
				value: 72,
			},
			{
				label: "FJI",
				value: 73,
			},
			{
				label: "FIN",
				value: 74,
			},
			{
				label: "FRA",
				value: 75,
			},
			{
				label: "GUF",
				value: 76,
			},
			{
				label: "PYF",
				value: 77,
			},
			{
				label: "ATF",
				value: 78,
			},
			{
				label: "GAB",
				value: 79,
			},
			{
				label: "GMB",
				value: 80,
			},
			{
				label: "GEO",
				value: 81,
			},
			{
				label: "DEU",
				value: 82,
			},
			{
				label: "GHA",
				value: 83,
			},
			{
				label: "GIB",
				value: 84,
			},
			{
				label: "GRC",
				value: 85,
			},
			{
				label: "GRL",
				value: 86,
			},
			{
				label: "GRD",
				value: 87,
			},
			{
				label: "GLP",
				value: 88,
			},
			{
				label: "GUM",
				value: 89,
			},
			{
				label: "GTM",
				value: 90,
			},
			{
				label: "GGY",
				value: 91,
			},
			{
				label: "GIN",
				value: 92,
			},
			{
				label: "GNB",
				value: 93,
			},
			{
				label: "GUY",
				value: 94,
			},
			{
				label: "HTI",
				value: 95,
			},
			{
				label: "HMD",
				value: 96,
			},
			{
				label: "HND",
				value: 97,
			},
			{
				label: "HKG",
				value: 98,
			},
			{
				label: "HUN",
				value: 99,
			},
			{
				label: "ISL",
				value: 100,
			},
			{
				label: "IND",
				value: 101,
			},
			{
				label: "IDN",
				value: 102,
			},
			{
				label: "IRN",
				value: 103,
			},
			{
				label: "IRQ",
				value: 104,
			},
			{
				label: "IRL",
				value: 105,
			},
			{
				label: "IMN",
				value: 106,
			},
			{
				label: "ISR",
				value: 107,
			},
			{
				label: "ITA",
				value: 108,
			},
			{
				label: "CIV",
				value: 109,
			},
			{
				label: "JAM",
				value: 110,
			},
			{
				label: "JPN",
				value: 111,
			},
			{
				label: "JEY",
				value: 112,
			},
			{
				label: "JOR",
				value: 113,
			},
			{
				label: "KAZ",
				value: 114,
			},
			{
				label: "KEN",
				value: 115,
			},
			{
				label: "KIR",
				value: 116,
			},
			{
				label: "KWT",
				value: 117,
			},
			{
				label: "KGZ",
				value: 118,
			},
			{
				label: "LAO",
				value: 119,
			},
			{
				label: "LVA",
				value: 120,
			},
			{
				label: "LBN",
				value: 121,
			},
			{
				label: "LSO",
				value: 122,
			},
			{
				label: "LBR",
				value: 123,
			},
			{
				label: "LBY",
				value: 124,
			},
			{
				label: "LIE",
				value: 125,
			},
			{
				label: "LTU",
				value: 126,
			},
			{
				label: "LUX",
				value: 127,
			},
			{
				label: "MAC",
				value: 128,
			},
			{
				label: "MKD",
				value: 129,
			},
			{
				label: "MDG",
				value: 130,
			},
			{
				label: "MWI",
				value: 131,
			},
			{
				label: "MYS",
				value: 132,
			},
			{
				label: "MDV",
				value: 133,
			},
			{
				label: "MLI",
				value: 134,
			},
			{
				label: "MLT",
				value: 135,
			},
			{
				label: "MHL",
				value: 136,
			},
			{
				label: "MTQ",
				value: 137,
			},
			{
				label: "MRT",
				value: 138,
			},
			{
				label: "MUS",
				value: 139,
			},
			{
				label: "MYT",
				value: 140,
			},
			{
				label: "MEX",
				value: 141,
			},
			{
				label: "FSM",
				value: 142,
			},
			{
				label: "MDA",
				value: 143,
			},
			{
				label: "MCO",
				value: 144,
			},
			{
				label: "MNG",
				value: 145,
			},
			{
				label: "MSR",
				value: 147,
			},
			{
				label: "MAR",
				value: 148,
			},
			{
				label: "MOZ",
				value: 149,
			},
			{
				label: "MMR",
				value: 150,
			},
			{
				label: "NAM",
				value: 151,
			},
			{
				label: "NRU",
				value: 152,
			},
			{
				label: "NPL",
				value: 153,
			},
			{
				label: "NLD",
				value: 154,
			},
			{
				label: "ANT",
				value: 155,
			},
			{
				label: "NCL",
				value: 156,
			},
			{
				label: "NZL",
				value: 157,
			},
			{
				label: "NIC",
				value: 158,
			},
			{
				label: "NER",
				value: 159,
			},
			{
				label: "NGA",
				value: 160,
			},
			{
				label: "NIU",
				value: 161,
			},
			{
				label: "NFK",
				value: 162,
			},
			{
				label: "PRK",
				value: 163,
			},
			{
				label: "MNP",
				value: 164,
			},
			{
				label: "NOR",
				value: 165,
			},
			{
				label: "OMN",
				value: 166,
			},
			{
				label: "PAK",
				value: 167,
			},
			{
				label: "PLW",
				value: 168,
			},
			{
				label: "PSE",
				value: 169,
			},
			{
				label: "PAN",
				value: 170,
			},
			{
				label: "PNG",
				value: 171,
			},
			{
				label: "PRY",
				value: 172,
			},
			{
				label: "PER",
				value: 173,
			},
			{
				label: "PHL",
				value: 174,
			},
			{
				label: "PCN",
				value: 175,
			},
			{
				label: "POL",
				value: 176,
			},
			{
				label: "PRT",
				value: 177,
			},
			{
				label: "PRI",
				value: 178,
			},
			{
				label: "QAT",
				value: 179,
			},
			{
				label: "COG",
				value: 180,
			},
			{
				label: "REU",
				value: 181,
			},
			{
				label: "ROU",
				value: 182,
			},
			{
				label: "RUS",
				value: 183,
			},
			{
				label: "RWA",
				value: 184,
			},
			{
				label: "BLM",
				value: 185,
			},
			{
				label: "KNA",
				value: 186,
			},
			{
				label: "LCA",
				value: 187,
			},
			{
				label: "MAF",
				value: 188,
			},
			{
				label: "VCT",
				value: 189,
			},
			{
				label: "WSM",
				value: 190,
			},
			{
				label: "SMR",
				value: 191,
			},
			{
				label: "STP",
				value: 192,
			},
			{
				label: "SAU",
				value: 193,
			},
			{
				label: "SEN",
				value: 194,
			},
			{
				label: "SRB",
				value: 195,
			},
			{
				label: "MNE",
				value: 196,
			},
			{
				label: "SYC",
				value: 197,
			},
			{
				label: "SLE",
				value: 198,
			},
			{
				label: "SGP",
				value: 199,
			},
			{
				label: "SXM",
				value: 200,
			},
			{
				label: "SVK",
				value: 201,
			},
			{
				label: "SVN",
				value: 202,
			},
			{
				label: "SLB",
				value: 203,
			},
			{
				label: "SOM",
				value: 204,
			},
			{
				label: "ZAF",
				value: 205,
			},
			{
				label: "SGS",
				value: 206,
			},
			{
				label: "KOR",
				value: 207,
			},
			{
				label: "SSD",
				value: 208,
			},
			{
				label: "ESP",
				value: 209,
			},
			{
				label: "LKA",
				value: 210,
			},
			{
				label: "SHN",
				value: 211,
			},
			{
				label: "SPM",
				value: 212,
			},
			{
				label: "SDN",
				value: 213,
			},
			{
				label: "SUR",
				value: 214,
			},
			{
				label: "SJM",
				value: 215,
			},
			{
				label: "SWZ",
				value: 216,
			},
			{
				label: "SWE",
				value: 217,
			},
			{
				label: "CHE",
				value: 218,
			},
			{
				label: "SYR",
				value: 219,
			},
			{
				label: "TWN",
				value: 220,
			},
			{
				label: "TJK",
				value: 221,
			},
			{
				label: "TZA",
				value: 222,
			},
			{
				label: "THA",
				value: 223,
			},
			{
				label: "TLS",
				value: 224,
			},
			{
				label: "TGO",
				value: 225,
			},
			{
				label: "TKL",
				value: 226,
			},
			{
				label: "TON",
				value: 227,
			},
			{
				label: "TTO",
				value: 228,
			},
			{
				label: "TUN",
				value: 229,
			},
			{
				label: "TUR",
				value: 230,
			},
			{
				label: "TKM",
				value: 231,
			},
			{
				label: "TCA",
				value: 232,
			},
			{
				label: "TUV",
				value: 233,
			},
			{
				label: "UGA",
				value: 234,
			},
			{
				label: "UKR",
				value: 235,
			},
			{
				label: "ARE",
				value: 236,
			},
			{
				label: "GBR",
				value: 237,
			},
			{
				label: "USA",
				value: 238,
			},
			{
				label: "URY",
				value: 239,
			},
			{
				label: "UMI",
				value: 240,
			},
			{
				label: "VIR",
				value: 241,
			},
			{
				label: "UZB",
				value: 242,
			},
			{
				label: "VUT",
				value: 243,
			},
			{
				label: "VAT",
				value: 244,
			},
			{
				label: "VEN",
				value: 245,
			},
			{
				label: "VNM",
				value: 246,
			},
			{
				label: "WLF",
				value: 247,
			},
			{
				label: "ESH",
				value: 248,
			},
			{
				label: "YEM",
				value: 249,
			},
			{
				label: "ZMB",
				value: 250,
			},
			{
				label: "ZWE",
				value: 251,
			},
		],
		CHRISTMAS_ISLAND: 48,
		COCOS_ISLANDS: 49,
		COLOMBIA: 50,
		COMOROS: 51,
		COOK_ISLANDS: 52,
		COSTA_RICA: 53,
		CROATIA: 54,
		CUBA: 55,
		CURACAO: 56,
		CYPRUS: 57,
		CZECH_REPUBLIC: 58,
		DEMOCRATIC_REPUBLIC_OF_THE_CONGO: 59,
		DENMARK: 60,
		DJIBOUTI: 61,
		DOMINICA: 62,
		DOMINICAN_REPUBLIC: 63,
		ECUADOR: 64,
		EGYPT: 65,
		EL_SALVADOR: 66,
		EQUATORIAL_GUINEA: 67,
		ERITREA: 68,
		ESTONIA: 69,
		ETHIOPIA: 70,
		FALKLAND_ISLANDS: 71,
		FAROE_ISLANDS: 72,
		FIJI: 73,
		FINLAND: 74,
		FRANCE: 75,
		FRENCH_GUIANA: 76,
		FRENCH_POLYNESIA: 77,
		FRENCH_SOUTHERN_AND_ANTARCTIC_TERRITORIES: 78,
		GABON: 79,
		GAMBIA: 80,
		GEORGIA: 81,
		GERMANY: 82,
		GHANA: 83,
		GIBRALTAR: 84,
		GREECE: 85,
		GREENLAND: 86,
		GRENADA: 87,
		GUADELOUPE: 88,
		GUAM: 89,
		GUATEMALA: 90,
		GUERNSEY: 91,
		GUINEA: 92,
		GUINEA_BISSAU: 93,
		GUYANA: 94,
		HAITI: 95,
		HEARD_AND_MCDONALD_ISLANDS: 96,
		HONDURAS: 97,
		HONG_KONG: 98,
		HUNGARY: 99,
		ICELAND: 100,
		INDIA: 101,
		INDONESIA: 102,
		IRAN: 103,
		IRAQ: 104,
		IRELAND: 105,
		ISLE_OF_MAN: 106,
		ISRAEL: 107,
		ITALY: 108,
		IVORY_COAST: 109,
		JAMAICA: 110,
		JAPAN: 111,
		JERSEY: 112,
		JORDAN: 113,
		KAZAKHSTAN: 114,
		KENYA: 115,
		KIRIBATI: 116,
		KUWAIT: 117,
		KYRGYZSTAN: 118,
		LAOS: 119,
		LATVIA: 120,
		LEBANON: 121,
		LESOTHO: 122,
		LIBERIA: 123,
		LIBYAN_ARAB_JAMAHIRIYA: 124,
		LIECHTENSTEIN: 125,
		LITHUANIA: 126,
		LUXEMBOURG: 127,
		MACAU: 128,
		MACEDONIA: 129,
		MADAGASCAR: 130,
		MALAWI: 131,
		MALAYSIA: 132,
		MALDIVES: 133,
		MALI: 134,
		MALTA: 135,
		MARSHALL_ISLANDS: 136,
		MARTINIQUE: 137,
		MAURITANIA: 138,
		MAURITIUS: 139,
		MAYOTTE: 140,
		MEXICO: 141,
		MICRONESIA: 142,
		MOLDOVA: 143,
		MONACO: 144,
		MONGOLIA: 145,
		MONTENEGRO: 196,
		MONTSERRAT: 147,
		MOROCCO: 148,
		MOZAMBIQUE: 149,
		MYANMAR: 150,
		NAMIBIA: 151,
		NAURU: 152,
		NEPAL: 153,
		NETHERLANDS: 154,
		NETHERLANDS_ANTILLES: 155,
		NEW_CALEDONIA: 156,
		NEW_ZEALAND: 157,
		NICARAGUA: 158,
		NIGER: 159,
		NIGERIA: 160,
		NIUE: 161,
		NORFOLK_ISLAND: 162,
		NORTHERN_MARIANA_ISLANDS: 164,
		NORTH_KOREA: 163,
		NORWAY: 165,
		OMAN: 166,
		PAKISTAN: 167,
		PALAU: 168,
		PALESTINIAN_TERRITORY: 169,
		PANAMA: 170,
		PAPUA_NEW_GUINEA: 171,
		PARAGUAY: 172,
		PERU: 173,
		PHILIPPINES: 174,
		PITCAIRN_ISLAND: 175,
		POLAND: 176,
		PORTUGAL: 177,
		PUERTO_RICO: 178,
		QATAR: 179,
		REPUBLIC_OF_THE_CONGO: 180,
		REUNION: 181,
		ROMANIA: 182,
		RUSSIA: 183,
		RWANDA: 184,
		SAINT_BARTHELEMY: 185,
		SAINT_KITTS_AND_NEVIS: 186,
		SAINT_LUCIA: 187,
		SAINT_MARTIN: 188,
		SAINT_VINCENT_AND_THE_GRENADINES: 189,
		SAMOA: 190,
		SAN_MARINO: 191,
		SAO_TOME_AND_PRINCIPE: 192,
		SAUDI_ARABIA: 193,
		SENEGAL: 194,
		SERBIA: 195,
		SEYCHELLES: 197,
		SIERRA_LEONE: 198,
		SINGAPORE: 199,
		SINT_MAARTEN: 200,
		SLOVAKIA: 201,
		SLOVENIA: 202,
		SOLOMON_ISLANDS: 203,
		SOMALIA: 204,
		SOUTH_AFRICA: 205,
		SOUTH_GEORGIA_AND_THE_SOUTH_SANDWICH_ISLANDS: 206,
		SOUTH_KOREA: 207,
		SOUTH_SUDAN: 208,
		SPAIN: 209,
		SRI_LANKA: 210,
		ST_HELENA: 211,
		ST_PIERRE_AND_MIQUELON: 212,
		SUDAN: 213,
		SURINAME: 214,
		SVALBARD_AND_JAN_MAYEN: 215,
		SWAZILAND: 216,
		SWEDEN: 217,
		SWITZERLAND: 218,
		SYRIAN_ARAB_REPUBLIC: 219,
		TAIWAN: 220,
		TAJIKISTAN: 221,
		TANZANIA: 222,
		THAILAND: 223,
		TIMOR_LESTE: 224,
		TOGO: 225,
		TOKELAU: 226,
		TONGA: 227,
		TRINIDAD_AND_TOBAGO: 228,
		TUNISIA: 229,
		TURKEY: 230,
		TURKMENISTAN: 231,
		TURKS_AND_CAICOS_ISLANDS: 232,
		TUVALU: 233,
		UGANDA: 234,
		UKRAINE: 235,
		UNITED_ARAB_EMIRATES: 236,
		UNITED_KINGDOM: 237,
		UNITED_STATES: 238,
		URUGUAY: 239,
		US_MINOR_OUTLYING_ISLANDS: 240,
		US_VIRGIN_ISLANDS: 241,
		UZBEKISTAN: 242,
		VANUATU: 243,
		VATICAN_CITY: 244,
		VENEZUELA: 245,
		VIETNAM: 246,
		WALLIS_AND_FUTUNA: 247,
		WESTERN_SAHARA: 248,
		YEMEN: 249,
		ZAMBIA: 250,
		ZIMBABWE: 251,
	},
	CountryCodeCaller: {
		AFGHANISTAN: "AF",
		ALAND_ISLANDS: "AX",
		ALBANIA: "AL",
		ALGERIA: "DZ",
		AMERICAN_SAMOA: "AS",
		ANDORRA: "AD",
		ANGOLA: "AO",
		ANGUILLA: "AI",
		ANTARCTICA: "AQ",
		ANTIGUA_AND_BARBUDA: "AG",
		ARGENTINA: "AR",
		ARMENIA: "AM",
		ARUBA: "AW",
		AUSTRALIA: "AU",
		AUSTRIA: "AT",
		AZERBAIJAN: "AZ",
		BAHAMAS: "BS",
		BAHRAIN: "BH",
		BANGLADESH: "BD",
		BARBADOS: "BB",
		BELARUS: "BY",
		BELGIUM: "BE",
		BELIZE: "BZ",
		BENIN: "BJ",
		BERMUDA: "BM",
		BHUTAN: "BT",
		BOLIVIA: "BO",
		BONAIRE_SINT_EUSTATIUS_AND_SABA: "BQ",
		BOSNIA_AND_HERZEGOVINA: "BA",
		BOTSWANA: "BW",
		BOUVET_ISLAND: "BV",
		BRAZIL: "BR",
		BRITISH_INDIAN_OCEAN_TERRITORY: "IO",
		BRITISH_VIRGIN_ISLANDS: "VG",
		BRUNEI_DARUSSALAM: "BN",
		BULGARIA: "BG",
		BURKINA_FASO: "BF",
		BURUNDI: "BI",
		CAMBODIA: "KH",
		CAMEROON: "CM",
		CANADA: "CA",
		CAPE_VERDE: "CV",
		CAYMAN_ISLANDS: "KY",
		CENTRAL_AFRICAN_REPUBLIC: "CF",
		CHAD: "TD",
		CHILE: "CL",
		CHINA: "CN",
		CHOICES: {
			AD: [376],
			AE: [971],
			AF: [93],
			AG: [1268],
			AI: [1264],
			AL: [355],
			AM: [374],
			AN: [599],
			AO: [244],
			AQ: [6721],
			AR: [54],
			AS: [1684],
			AT: [43],
			AU: [61, 672],
			AW: [297],
			AX: [35818],
			AZ: [994],
			BA: [387],
			BB: [1246],
			BD: [880],
			BE: [32],
			BF: [226],
			BG: [359],
			BH: [973],
			BI: [257],
			BJ: [229],
			BL: [590],
			BM: [1441],
			BN: [673],
			BO: [591],
			BQ: [5997, 5994, 5993],
			BR: [55],
			BS: [1242],
			BT: [975],
			BV: [47],
			BW: [267],
			BY: [375],
			BZ: [501],
			CA: [1],
			CC: [6189162],
			CD: [243],
			CF: [236],
			CG: [242, 243],
			CH: [41],
			CI: [225],
			CK: [682],
			CL: [56],
			CM: [237],
			CN: [86, 852],
			CO: [57],
			CR: [506],
			CU: [53],
			CV: [238],
			CW: [5999],
			CX: [6189164],
			CY: [357],
			CZ: [420],
			DE: [49],
			DJ: [253],
			DK: [45],
			DM: [1767],
			DO: [1809, 1829],
			DZ: [213],
			EC: [593],
			EE: [372],
			EG: [20],
			EH: [2125288, 2125289],
			ER: [291],
			ES: [34],
			ET: [251],
			FI: [358],
			FJ: [679],
			FK: [500],
			FM: [691],
			FO: [298],
			FR: [33, 596],
			GA: [241],
			GD: [1473],
			GE: [995],
			GF: [594],
			GG: [441481, 447781],
			GH: [233],
			GI: [350],
			GL: [299],
			GM: [220],
			GN: [224],
			GP: [590],
			GQ: [240],
			GR: [30],
			GS: [500],
			GT: [502],
			GU: [1671],
			GW: [245],
			GY: [592],
			HK: [852],
			HM: [672, 672],
			HN: [504],
			HR: [385],
			HT: [509],
			HU: [36],
			ID: [62],
			IE: [353],
			IL: [972],
			IM: [441624, 447524],
			IN: [91],
			IO: [246],
			IQ: [964],
			IR: [98],
			IS: [354],
			IT: [39],
			JE: [441534],
			JM: [1876],
			JO: [962],
			JP: [81],
			KE: [254],
			KG: [996],
			KH: [855],
			KI: [686],
			KM: [269],
			KN: [1869],
			KP: [850],
			KR: [82],
			KW: [965],
			KY: [1345],
			KZ: [76, 77],
			LA: [856],
			LB: [961],
			LC: [1758],
			LI: [423],
			LK: [94],
			LR: [231],
			LS: [266],
			LT: [370],
			LU: [352],
			LV: [371],
			LY: [218],
			MA: [212],
			MC: [377],
			MD: [373, 3732, 3735],
			ME: [382],
			MF: [590],
			MG: [261],
			MH: [692],
			MK: [389],
			ML: [223],
			MM: [95],
			MN: [976],
			MO: [853],
			MP: [1670],
			MQ: [596],
			MR: [222],
			MS: [1664],
			MT: [356],
			MU: [230],
			MV: [960],
			MW: [265],
			MX: [52],
			MY: [60],
			MZ: [258],
			NC: [687],
			NE: [227],
			NF: [6723],
			NG: [234],
			NI: [505],
			NL: [31],
			NO: [47, 4779],
			NP: [977],
			NR: [674],
			NU: [683],
			NZ: [64],
			OM: [968],
			PA: [507],
			PE: [51],
			PF: [689],
			PG: [675],
			PH: [63],
			PK: [92],
			PL: [48],
			PM: [508],
			PN: [64],
			PR: [1787, 1939],
			PS: [970],
			PT: [351],
			PW: [680],
			PY: [595],
			QA: [974],
			RE: [262],
			RO: [40],
			RS: [381],
			RU: [7, 3, 4, 5],
			RW: [250],
			SA: [966],
			SB: [677],
			SC: [248],
			SD: [249],
			SE: [46],
			SG: [65],
			SH: [290, 247, 2908],
			SI: [386],
			SJ: [4779],
			SK: [421],
			SL: [232],
			SM: [378],
			SN: [221],
			SO: [252],
			SR: [597],
			SS: [211],
			ST: [239],
			SV: [503],
			SX: [1721],
			SY: [963],
			SZ: [268],
			TC: [1649],
			TD: [235],
			TF: [33],
			TG: [228],
			TH: [66],
			TJ: [992],
			TK: [690],
			TL: [670],
			TM: [993],
			TN: [216],
			TO: [676],
			TR: [90],
			TT: [1868],
			TV: [688],
			TW: [886],
			TZ: [255, 25524],
			UA: [380],
			UG: [256],
			UK: [44, 246, 4428],
			UM: [1808],
			US: [1],
			UY: [598],
			UZ: [998],
			VA: [3906698, 379],
			VC: [1784],
			VE: [58],
			VG: [1284],
			VI: [1340],
			VN: [84],
			VU: [678],
			WF: [681],
			WS: [685],
			YE: [967],
			YT: [262269, 262639],
			ZA: [27],
			ZM: [260],
			ZW: [263],
			nan: [264],
		},
		CHOICE_LIST: [
			{
				label: [376],
				value: "AD",
			},
			{
				label: [971],
				value: "AE",
			},
			{
				label: [93],
				value: "AF",
			},
			{
				label: [1268],
				value: "AG",
			},
			{
				label: [1264],
				value: "AI",
			},
			{
				label: [355],
				value: "AL",
			},
			{
				label: [374],
				value: "AM",
			},
			{
				label: [599],
				value: "AN",
			},
			{
				label: [244],
				value: "AO",
			},
			{
				label: [6721],
				value: "AQ",
			},
			{
				label: [54],
				value: "AR",
			},
			{
				label: [1684],
				value: "AS",
			},
			{
				label: [43],
				value: "AT",
			},
			{
				label: [61, 672],
				value: "AU",
			},
			{
				label: [297],
				value: "AW",
			},
			{
				label: [35818],
				value: "AX",
			},
			{
				label: [994],
				value: "AZ",
			},
			{
				label: [387],
				value: "BA",
			},
			{
				label: [1246],
				value: "BB",
			},
			{
				label: [880],
				value: "BD",
			},
			{
				label: [32],
				value: "BE",
			},
			{
				label: [226],
				value: "BF",
			},
			{
				label: [359],
				value: "BG",
			},
			{
				label: [973],
				value: "BH",
			},
			{
				label: [257],
				value: "BI",
			},
			{
				label: [229],
				value: "BJ",
			},
			{
				label: [590],
				value: "BL",
			},
			{
				label: [1441],
				value: "BM",
			},
			{
				label: [673],
				value: "BN",
			},
			{
				label: [591],
				value: "BO",
			},
			{
				label: [5997, 5994, 5993],
				value: "BQ",
			},
			{
				label: [55],
				value: "BR",
			},
			{
				label: [1242],
				value: "BS",
			},
			{
				label: [975],
				value: "BT",
			},
			{
				label: [47],
				value: "BV",
			},
			{
				label: [267],
				value: "BW",
			},
			{
				label: [375],
				value: "BY",
			},
			{
				label: [501],
				value: "BZ",
			},
			{
				label: [1],
				value: "CA",
			},
			{
				label: [6189162],
				value: "CC",
			},
			{
				label: [243],
				value: "CD",
			},
			{
				label: [236],
				value: "CF",
			},
			{
				label: [242, 243],
				value: "CG",
			},
			{
				label: [41],
				value: "CH",
			},
			{
				label: [225],
				value: "CI",
			},
			{
				label: [682],
				value: "CK",
			},
			{
				label: [56],
				value: "CL",
			},
			{
				label: [237],
				value: "CM",
			},
			{
				label: [86, 852],
				value: "CN",
			},
			{
				label: [57],
				value: "CO",
			},
			{
				label: [506],
				value: "CR",
			},
			{
				label: [53],
				value: "CU",
			},
			{
				label: [238],
				value: "CV",
			},
			{
				label: [5999],
				value: "CW",
			},
			{
				label: [6189164],
				value: "CX",
			},
			{
				label: [357],
				value: "CY",
			},
			{
				label: [420],
				value: "CZ",
			},
			{
				label: [49],
				value: "DE",
			},
			{
				label: [253],
				value: "DJ",
			},
			{
				label: [45],
				value: "DK",
			},
			{
				label: [1767],
				value: "DM",
			},
			{
				label: [1809, 1829],
				value: "DO",
			},
			{
				label: [213],
				value: "DZ",
			},
			{
				label: [593],
				value: "EC",
			},
			{
				label: [372],
				value: "EE",
			},
			{
				label: [20],
				value: "EG",
			},
			{
				label: [2125288, 2125289],
				value: "EH",
			},
			{
				label: [291],
				value: "ER",
			},
			{
				label: [34],
				value: "ES",
			},
			{
				label: [251],
				value: "ET",
			},
			{
				label: [358],
				value: "FI",
			},
			{
				label: [679],
				value: "FJ",
			},
			{
				label: [500],
				value: "FK",
			},
			{
				label: [691],
				value: "FM",
			},
			{
				label: [298],
				value: "FO",
			},
			{
				label: [33, 596],
				value: "FR",
			},
			{
				label: [241],
				value: "GA",
			},
			{
				label: [1473],
				value: "GD",
			},
			{
				label: [995],
				value: "GE",
			},
			{
				label: [594],
				value: "GF",
			},
			{
				label: [441481, 447781],
				value: "GG",
			},
			{
				label: [233],
				value: "GH",
			},
			{
				label: [350],
				value: "GI",
			},
			{
				label: [299],
				value: "GL",
			},
			{
				label: [220],
				value: "GM",
			},
			{
				label: [224],
				value: "GN",
			},
			{
				label: [590],
				value: "GP",
			},
			{
				label: [240],
				value: "GQ",
			},
			{
				label: [30],
				value: "GR",
			},
			{
				label: [500],
				value: "GS",
			},
			{
				label: [502],
				value: "GT",
			},
			{
				label: [1671],
				value: "GU",
			},
			{
				label: [245],
				value: "GW",
			},
			{
				label: [592],
				value: "GY",
			},
			{
				label: [852],
				value: "HK",
			},
			{
				label: [672, 672],
				value: "HM",
			},
			{
				label: [504],
				value: "HN",
			},
			{
				label: [385],
				value: "HR",
			},
			{
				label: [509],
				value: "HT",
			},
			{
				label: [36],
				value: "HU",
			},
			{
				label: [62],
				value: "ID",
			},
			{
				label: [353],
				value: "IE",
			},
			{
				label: [972],
				value: "IL",
			},
			{
				label: [441624, 447524],
				value: "IM",
			},
			{
				label: [91],
				value: "IN",
			},
			{
				label: [246],
				value: "IO",
			},
			{
				label: [964],
				value: "IQ",
			},
			{
				label: [98],
				value: "IR",
			},
			{
				label: [354],
				value: "IS",
			},
			{
				label: [39],
				value: "IT",
			},
			{
				label: [441534],
				value: "JE",
			},
			{
				label: [1876],
				value: "JM",
			},
			{
				label: [962],
				value: "JO",
			},
			{
				label: [81],
				value: "JP",
			},
			{
				label: [254],
				value: "KE",
			},
			{
				label: [996],
				value: "KG",
			},
			{
				label: [855],
				value: "KH",
			},
			{
				label: [686],
				value: "KI",
			},
			{
				label: [269],
				value: "KM",
			},
			{
				label: [1869],
				value: "KN",
			},
			{
				label: [850],
				value: "KP",
			},
			{
				label: [82],
				value: "KR",
			},
			{
				label: [965],
				value: "KW",
			},
			{
				label: [1345],
				value: "KY",
			},
			{
				label: [76, 77],
				value: "KZ",
			},
			{
				label: [856],
				value: "LA",
			},
			{
				label: [961],
				value: "LB",
			},
			{
				label: [1758],
				value: "LC",
			},
			{
				label: [423],
				value: "LI",
			},
			{
				label: [94],
				value: "LK",
			},
			{
				label: [231],
				value: "LR",
			},
			{
				label: [266],
				value: "LS",
			},
			{
				label: [370],
				value: "LT",
			},
			{
				label: [352],
				value: "LU",
			},
			{
				label: [371],
				value: "LV",
			},
			{
				label: [218],
				value: "LY",
			},
			{
				label: [212],
				value: "MA",
			},
			{
				label: [377],
				value: "MC",
			},
			{
				label: [373, 3732, 3735],
				value: "MD",
			},
			{
				label: [382],
				value: "ME",
			},
			{
				label: [590],
				value: "MF",
			},
			{
				label: [261],
				value: "MG",
			},
			{
				label: [692],
				value: "MH",
			},
			{
				label: [389],
				value: "MK",
			},
			{
				label: [223],
				value: "ML",
			},
			{
				label: [95],
				value: "MM",
			},
			{
				label: [976],
				value: "MN",
			},
			{
				label: [853],
				value: "MO",
			},
			{
				label: [1670],
				value: "MP",
			},
			{
				label: [596],
				value: "MQ",
			},
			{
				label: [222],
				value: "MR",
			},
			{
				label: [1664],
				value: "MS",
			},
			{
				label: [356],
				value: "MT",
			},
			{
				label: [230],
				value: "MU",
			},
			{
				label: [960],
				value: "MV",
			},
			{
				label: [265],
				value: "MW",
			},
			{
				label: [52],
				value: "MX",
			},
			{
				label: [60],
				value: "MY",
			},
			{
				label: [258],
				value: "MZ",
			},
			{
				label: [687],
				value: "NC",
			},
			{
				label: [227],
				value: "NE",
			},
			{
				label: [6723],
				value: "NF",
			},
			{
				label: [234],
				value: "NG",
			},
			{
				label: [505],
				value: "NI",
			},
			{
				label: [31],
				value: "NL",
			},
			{
				label: [47, 4779],
				value: "NO",
			},
			{
				label: [977],
				value: "NP",
			},
			{
				label: [674],
				value: "NR",
			},
			{
				label: [683],
				value: "NU",
			},
			{
				label: [64],
				value: "NZ",
			},
			{
				label: [968],
				value: "OM",
			},
			{
				label: [507],
				value: "PA",
			},
			{
				label: [51],
				value: "PE",
			},
			{
				label: [689],
				value: "PF",
			},
			{
				label: [675],
				value: "PG",
			},
			{
				label: [63],
				value: "PH",
			},
			{
				label: [92],
				value: "PK",
			},
			{
				label: [48],
				value: "PL",
			},
			{
				label: [508],
				value: "PM",
			},
			{
				label: [64],
				value: "PN",
			},
			{
				label: [1787, 1939],
				value: "PR",
			},
			{
				label: [970],
				value: "PS",
			},
			{
				label: [351],
				value: "PT",
			},
			{
				label: [680],
				value: "PW",
			},
			{
				label: [595],
				value: "PY",
			},
			{
				label: [974],
				value: "QA",
			},
			{
				label: [262],
				value: "RE",
			},
			{
				label: [40],
				value: "RO",
			},
			{
				label: [381],
				value: "RS",
			},
			{
				label: [7, 3, 4, 5],
				value: "RU",
			},
			{
				label: [250],
				value: "RW",
			},
			{
				label: [966],
				value: "SA",
			},
			{
				label: [677],
				value: "SB",
			},
			{
				label: [248],
				value: "SC",
			},
			{
				label: [249],
				value: "SD",
			},
			{
				label: [46],
				value: "SE",
			},
			{
				label: [65],
				value: "SG",
			},
			{
				label: [290, 247, 2908],
				value: "SH",
			},
			{
				label: [386],
				value: "SI",
			},
			{
				label: [4779],
				value: "SJ",
			},
			{
				label: [421],
				value: "SK",
			},
			{
				label: [232],
				value: "SL",
			},
			{
				label: [378],
				value: "SM",
			},
			{
				label: [221],
				value: "SN",
			},
			{
				label: [252],
				value: "SO",
			},
			{
				label: [597],
				value: "SR",
			},
			{
				label: [211],
				value: "SS",
			},
			{
				label: [239],
				value: "ST",
			},
			{
				label: [503],
				value: "SV",
			},
			{
				label: [1721],
				value: "SX",
			},
			{
				label: [963],
				value: "SY",
			},
			{
				label: [268],
				value: "SZ",
			},
			{
				label: [1649],
				value: "TC",
			},
			{
				label: [235],
				value: "TD",
			},
			{
				label: [33],
				value: "TF",
			},
			{
				label: [228],
				value: "TG",
			},
			{
				label: [66],
				value: "TH",
			},
			{
				label: [992],
				value: "TJ",
			},
			{
				label: [690],
				value: "TK",
			},
			{
				label: [670],
				value: "TL",
			},
			{
				label: [993],
				value: "TM",
			},
			{
				label: [216],
				value: "TN",
			},
			{
				label: [676],
				value: "TO",
			},
			{
				label: [90],
				value: "TR",
			},
			{
				label: [1868],
				value: "TT",
			},
			{
				label: [688],
				value: "TV",
			},
			{
				label: [886],
				value: "TW",
			},
			{
				label: [255, 25524],
				value: "TZ",
			},
			{
				label: [380],
				value: "UA",
			},
			{
				label: [256],
				value: "UG",
			},
			{
				label: [44, 246, 4428],
				value: "UK",
			},
			{
				label: [1808],
				value: "UM",
			},
			{
				label: [1],
				value: "US",
			},
			{
				label: [598],
				value: "UY",
			},
			{
				label: [998],
				value: "UZ",
			},
			{
				label: [3906698, 379],
				value: "VA",
			},
			{
				label: [1784],
				value: "VC",
			},
			{
				label: [58],
				value: "VE",
			},
			{
				label: [1284],
				value: "VG",
			},
			{
				label: [1340],
				value: "VI",
			},
			{
				label: [84],
				value: "VN",
			},
			{
				label: [678],
				value: "VU",
			},
			{
				label: [681],
				value: "WF",
			},
			{
				label: [685],
				value: "WS",
			},
			{
				label: [967],
				value: "YE",
			},
			{
				label: [262269, 262639],
				value: "YT",
			},
			{
				label: [27],
				value: "ZA",
			},
			{
				label: [260],
				value: "ZM",
			},
			{
				label: [263],
				value: "ZW",
			},
			{
				label: [264],
				value: "nan",
			},
		],
		CHRISTMAS_ISLAND: "CX",
		COCOS_ISLANDS: "CC",
		COLOMBIA: "CO",
		COMOROS: "KM",
		COOK_ISLANDS: "CK",
		COSTA_RICA: "CR",
		CROATIA: "HR",
		CUBA: "CU",
		CURACAO: "CW",
		CYPRUS: "CY",
		CZECH_REPUBLIC: "CZ",
		DEMOCRATIC_REPUBLIC_OF_THE_CONGO: "CD",
		DENMARK: "DK",
		DJIBOUTI: "DJ",
		DOMINICA: "DM",
		DOMINICAN_REPUBLIC: "DO",
		ECUADOR: "EC",
		EGYPT: "EG",
		EL_SALVADOR: "SV",
		EQUATORIAL_GUINEA: "GQ",
		ERITREA: "ER",
		ESTONIA: "EE",
		ETHIOPIA: "ET",
		FALKLAND_ISLANDS: "FK",
		FAROE_ISLANDS: "FO",
		FIJI: "FJ",
		FINLAND: "FI",
		FRANCE: "FR",
		FRENCH_GUIANA: "GF",
		FRENCH_POLYNESIA: "PF",
		FRENCH_SOUTHERN_AND_ANTARCTIC_TERRITORIES: "TF",
		GABON: "GA",
		GAMBIA: "GM",
		GEORGIA: "GE",
		GERMANY: "DE",
		GHANA: "GH",
		GIBRALTAR: "GI",
		GREECE: "GR",
		GREENLAND: "GL",
		GRENADA: "GD",
		GUADELOUPE: "GP",
		GUAM: "GU",
		GUATEMALA: "GT",
		GUERNSEY: "GG",
		GUINEA: "GN",
		GUINEA_BISSAU: "GW",
		GUYANA: "GY",
		HAITI: "HT",
		HEARD_AND_MCDONALD_ISLANDS: "HM",
		HONDURAS: "HN",
		HONG_KONG: "HK",
		HUNGARY: "HU",
		ICELAND: "IS",
		INDIA: "IN",
		INDONESIA: "ID",
		IRAN: "IR",
		IRAQ: "IQ",
		IRELAND: "IE",
		ISLE_OF_MAN: "IM",
		ISRAEL: "IL",
		ITALY: "IT",
		IVORY_COAST: "CI",
		JAMAICA: "JM",
		JAPAN: "JP",
		JERSEY: "JE",
		JORDAN: "JO",
		KAZAKHSTAN: "KZ",
		KENYA: "KE",
		KIRIBATI: "KI",
		KUWAIT: "KW",
		KYRGYZSTAN: "KG",
		LAOS: "LA",
		LATVIA: "LV",
		LEBANON: "LB",
		LESOTHO: "LS",
		LIBERIA: "LR",
		LIBYAN_ARAB_JAMAHIRIYA: "LY",
		LIECHTENSTEIN: "LI",
		LITHUANIA: "LT",
		LUXEMBOURG: "LU",
		MACAU: "MO",
		MACEDONIA: "MK",
		MADAGASCAR: "MG",
		MALAWI: "MW",
		MALAYSIA: "MY",
		MALDIVES: "MV",
		MALI: "ML",
		MALTA: "MT",
		MARSHALL_ISLANDS: "MH",
		MARTINIQUE: "MQ",
		MAURITANIA: "MR",
		MAURITIUS: "MU",
		MAYOTTE: "YT",
		MEXICO: "MX",
		MICRONESIA: "FM",
		MOLDOVA: "MD",
		MONACO: "MC",
		MONGOLIA: "MN",
		MONTENEGRO: "ME",
		MONTSERRAT: "MS",
		MOROCCO: "MA",
		MOZAMBIQUE: "MZ",
		MYANMAR: "MM",
		NAMIBIA: "nan",
		NAURU: "NR",
		NEPAL: "NP",
		NETHERLANDS: "NL",
		NETHERLANDS_ANTILLES: "AN",
		NEW_CALEDONIA: "NC",
		NEW_ZEALAND: "NZ",
		NICARAGUA: "NI",
		NIGER: "NE",
		NIGERIA: "NG",
		NIUE: "NU",
		NORFOLK_ISLAND: "NF",
		NORTHERN_MARIANA_ISLANDS: "MP",
		NORTH_KOREA: "KP",
		NORWAY: "NO",
		OMAN: "OM",
		PAKISTAN: "PK",
		PALAU: "PW",
		PALESTINIAN_TERRITORY: "PS",
		PANAMA: "PA",
		PAPUA_NEW_GUINEA: "PG",
		PARAGUAY: "PY",
		PERU: "PE",
		PHILIPPINES: "PH",
		PITCAIRN_ISLAND: "PN",
		POLAND: "PL",
		PORTUGAL: "PT",
		PUERTO_RICO: "PR",
		QATAR: "QA",
		REPUBLIC_OF_THE_CONGO: "CG",
		REUNION: "RE",
		ROMANIA: "RO",
		RUSSIA: "RU",
		RWANDA: "RW",
		SAINT_BARTHELEMY: "BL",
		SAINT_KITTS_AND_NEVIS: "KN",
		SAINT_LUCIA: "LC",
		SAINT_MARTIN: "MF",
		SAINT_VINCENT_AND_THE_GRENADINES: "VC",
		SAMOA: "WS",
		SAN_MARINO: "SM",
		SAO_TOME_AND_PRINCIPE: "ST",
		SAUDI_ARABIA: "SA",
		SENEGAL: "SN",
		SERBIA: "RS",
		SEYCHELLES: "SC",
		SIERRA_LEONE: "SL",
		SINGAPORE: "SG",
		SINT_MAARTEN: "SX",
		SLOVAKIA: "SK",
		SLOVENIA: "SI",
		SOLOMON_ISLANDS: "SB",
		SOMALIA: "SO",
		SOUTH_AFRICA: "ZA",
		SOUTH_GEORGIA_AND_THE_SOUTH_SANDWICH_ISLANDS: "GS",
		SOUTH_KOREA: "KR",
		SOUTH_SUDAN: "SS",
		SPAIN: "ES",
		SRI_LANKA: "LK",
		ST_HELENA: "SH",
		ST_PIERRE_AND_MIQUELON: "PM",
		SUDAN: "SD",
		SURINAME: "SR",
		SVALBARD_AND_JAN_MAYEN: "SJ",
		SWAZILAND: "SZ",
		SWEDEN: "SE",
		SWITZERLAND: "CH",
		SYRIAN_ARAB_REPUBLIC: "SY",
		TAIWAN: "TW",
		TAJIKISTAN: "TJ",
		TANZANIA: "TZ",
		THAILAND: "TH",
		TIMOR_LESTE: "TL",
		TOGO: "TG",
		TOKELAU: "TK",
		TONGA: "TO",
		TRINIDAD_AND_TOBAGO: "TT",
		TUNISIA: "TN",
		TURKEY: "TR",
		TURKMENISTAN: "TM",
		TURKS_AND_CAICOS_ISLANDS: "TC",
		TUVALU: "TV",
		UGANDA: "UG",
		UKRAINE: "UA",
		UNITED_ARAB_EMIRATES: "AE",
		UNITED_KINGDOM: "UK",
		UNITED_STATES: "US",
		URUGUAY: "UY",
		US_MINOR_OUTLYING_ISLANDS: "UM",
		US_VIRGIN_ISLANDS: "VI",
		UZBEKISTAN: "UZ",
		VANUATU: "VU",
		VATICAN_CITY: "VA",
		VENEZUELA: "VE",
		VIETNAM: "VN",
		WALLIS_AND_FUTUNA: "WF",
		WESTERN_SAHARA: "EH",
		YEMEN: "YE",
		ZAMBIA: "ZM",
		ZIMBABWE: "ZW",
	},
	CountryNationality: {
		AFGHANISTAN: "AFGHANISTAN",
		ALAND_ISLANDS: "ALAND_ISLANDS",
		ALBANIA: "ALBANIA",
		ALGERIA: "ALGERIA",
		AMERICAN_SAMOA: "AMERICAN_SAMOA",
		ANDORRA: "ANDORRA",
		ANGOLA: "ANGOLA",
		ANGUILLA: "ANGUILLA",
		ANTARCTICA: "ANTARCTICA",
		ANTIGUA_AND_BARBUDA: "ANTIGUA_AND_BARBUDA",
		ARGENTINA: "ARGENTINA",
		ARMENIA: "ARMENIA",
		ARUBA: "ARUBA",
		AUSTRALIA: "AUSTRALIA",
		AUSTRIA: "AUSTRIA",
		AZERBAIJAN: "AZERBAIJAN",
		BAHAMAS: "BAHAMAS",
		BAHRAIN: "BAHRAIN",
		BANGLADESH: "BANGLADESH",
		BARBADOS: "BARBADOS",
		BELARUS: "BELARUS",
		BELGIUM: "BELGIUM",
		BELIZE: "BELIZE",
		BENIN: "BENIN",
		BERMUDA: "BERMUDA",
		BHUTAN: "BHUTAN",
		BOLIVIA: "BOLIVIA",
		BONAIRE_SINT_EUSTATIUS_AND_SABA: "BONAIRE_SINT_EUSTATIUS_AND_SABA",
		BOSNIA_AND_HERZEGOVINA: "BOSNIA_AND_HERZEGOVINA",
		BOTSWANA: "BOTSWANA",
		BOUVET_ISLAND: "BOUVET_ISLAND",
		BRAZIL: "BRAZIL",
		BRITISH_INDIAN_OCEAN_TERRITORY: "BRITISH_INDIAN_OCEAN_TERRITORY",
		BRITISH_VIRGIN_ISLANDS: "BRITISH_VIRGIN_ISLANDS",
		BRUNEI_DARUSSALAM: "BRUNEI_DARUSSALAM",
		BULGARIA: "BULGARIA",
		BURKINA_FASO: "BURKINA_FASO",
		BURUNDI: "BURUNDI",
		CAMBODIA: "CAMBODIA",
		CAMEROON: "CAMEROON",
		CANADA: "CANADA",
		CAPE_VERDE: "CAPE_VERDE",
		CAYMAN_ISLANDS: "CAYMAN_ISLANDS",
		CENTRAL_AFRICAN_REPUBLIC: "CENTRAL_AFRICAN_REPUBLIC",
		CHAD: "CHAD",
		CHILE: "CHILE",
		CHINA: "CHINA",
		CHOICES: {
			AFGHANISTAN: "AF",
			ALAND_ISLANDS: "AX",
			ALBANIA: "AL",
			ALGERIA: "DZ",
			AMERICAN_SAMOA: "AS",
			ANDORRA: "AD",
			ANGOLA: "AO",
			ANGUILLA: "AI",
			ANTARCTICA: "AQ",
			ANTIGUA_AND_BARBUDA: "AG",
			ARGENTINA: "AR",
			ARMENIA: "AM",
			ARUBA: "AW",
			AUSTRALIA: "AU",
			AUSTRIA: "AT",
			AZERBAIJAN: "AZ",
			BAHAMAS: "BS",
			BAHRAIN: "BH",
			BANGLADESH: "BD",
			BARBADOS: "BB",
			BELARUS: "BY",
			BELGIUM: "BE",
			BELIZE: "BZ",
			BENIN: "BJ",
			BERMUDA: "BM",
			BHUTAN: "BT",
			BOLIVIA: "BO",
			BONAIRE_SINT_EUSTATIUS_AND_SABA: "BQ",
			BOSNIA_AND_HERZEGOVINA: "BA",
			BOTSWANA: "BW",
			BOUVET_ISLAND: "BV",
			BRAZIL: "BR",
			BRITISH_INDIAN_OCEAN_TERRITORY: "IO",
			BRITISH_VIRGIN_ISLANDS: "VG",
			BRUNEI_DARUSSALAM: "BN",
			BULGARIA: "BG",
			BURKINA_FASO: "BF",
			BURUNDI: "BI",
			CAMBODIA: "KH",
			CAMEROON: "CM",
			CANADA: "CA",
			CAPE_VERDE: "CV",
			CAYMAN_ISLANDS: "KY",
			CENTRAL_AFRICAN_REPUBLIC: "CF",
			CHAD: "TD",
			CHILE: "CL",
			CHINA: "CN",
			CHRISTMAS_ISLAND: "CX",
			COCOS_ISLANDS: "CC",
			COLOMBIA: "CO",
			COMOROS: "KM",
			COOK_ISLANDS: "CK",
			COSTA_RICA: "CR",
			CROATIA: "HR",
			CUBA: "CU",
			CURACAO: "CW",
			CYPRUS: "CY",
			CZECH_REPUBLIC: "CZ",
			DEMOCRATIC_REPUBLIC_OF_THE_CONGO: "CD",
			DENMARK: "DK",
			DJIBOUTI: "DJ",
			DOMINICA: "DM",
			DOMINICAN_REPUBLIC: "DO",
			ECUADOR: "EC",
			EGYPT: "EG",
			EL_SALVADOR: "SV",
			EQUATORIAL_GUINEA: "GQ",
			ERITREA: "ER",
			ESTONIA: "EE",
			ETHIOPIA: "ET",
			FALKLAND_ISLANDS: "FK",
			FAROE_ISLANDS: "FO",
			FIJI: "FJ",
			FINLAND: "FI",
			FRANCE: "FR",
			FRENCH_GUIANA: "GF",
			FRENCH_POLYNESIA: "PF",
			FRENCH_SOUTHERN_AND_ANTARCTIC_TERRITORIES: "TF",
			GABON: "GA",
			GAMBIA: "GM",
			GEORGIA: "GE",
			GERMANY: "DE",
			GHANA: "GH",
			GIBRALTAR: "GI",
			GREECE: "GR",
			GREENLAND: "GL",
			GRENADA: "GD",
			GUADELOUPE: "GP",
			GUAM: "GU",
			GUATEMALA: "GT",
			GUERNSEY: "GG",
			GUINEA: "GN",
			GUINEA_BISSAU: "GW",
			GUYANA: "GY",
			HAITI: "HT",
			HEARD_AND_MCDONALD_ISLANDS: "HM",
			HONDURAS: "HN",
			HONG_KONG: "HK",
			HUNGARY: "HU",
			ICELAND: "IS",
			INDIA: "IN",
			INDONESIA: "ID",
			IRAN: "IR",
			IRAQ: "IQ",
			IRELAND: "IE",
			ISLE_OF_MAN: "IM",
			ISRAEL: "IL",
			ITALY: "IT",
			IVORY_COAST: "CI",
			JAMAICA: "JM",
			JAPAN: "JP",
			JERSEY: "JE",
			JORDAN: "JO",
			KAZAKHSTAN: "KZ",
			KENYA: "KE",
			KIRIBATI: "KI",
			KUWAIT: "KW",
			KYRGYZSTAN: "KG",
			LAOS: "LA",
			LATVIA: "LV",
			LEBANON: "LB",
			LESOTHO: "LS",
			LIBERIA: "LR",
			LIBYAN_ARAB_JAMAHIRIYA: "LY",
			LIECHTENSTEIN: "LI",
			LITHUANIA: "LT",
			LUXEMBOURG: "LU",
			MACAU: "MO",
			MACEDONIA: "MK",
			MADAGASCAR: "MG",
			MALAWI: "MW",
			MALAYSIA: "MY",
			MALDIVES: "MV",
			MALI: "ML",
			MALTA: "MT",
			MARSHALL_ISLANDS: "MH",
			MARTINIQUE: "MQ",
			MAURITANIA: "MR",
			MAURITIUS: "MU",
			MAYOTTE: "YT",
			MEXICO: "MX",
			MICRONESIA: "FM",
			MOLDOVA: "MD",
			MONACO: "MC",
			MONGOLIA: "MN",
			MONTENEGRO: "ME",
			MONTSERRAT: "MS",
			MOROCCO: "MA",
			MOZAMBIQUE: "MZ",
			MYANMAR: "MM",
			NAMIBIA: "nan",
			NAURU: "NR",
			NEPAL: "NP",
			NETHERLANDS: "NL",
			NETHERLANDS_ANTILLES: "AN",
			NEW_CALEDONIA: "NC",
			NEW_ZEALAND: "NZ",
			NICARAGUA: "NI",
			NIGER: "NE",
			NIGERIA: "NG",
			NIUE: "NU",
			NORFOLK_ISLAND: "NF",
			NORTHERN_MARIANA_ISLANDS: "MP",
			NORTH_KOREA: "KP",
			NORWAY: "NO",
			OMAN: "OM",
			PAKISTAN: "PK",
			PALAU: "PW",
			PALESTINIAN_TERRITORY: "PS",
			PANAMA: "PA",
			PAPUA_NEW_GUINEA: "PG",
			PARAGUAY: "PY",
			PERU: "PE",
			PHILIPPINES: "PH",
			PITCAIRN_ISLAND: "PN",
			POLAND: "PL",
			PORTUGAL: "PT",
			PUERTO_RICO: "PR",
			QATAR: "QA",
			REPUBLIC_OF_THE_CONGO: "CG",
			REUNION: "RE",
			ROMANIA: "RO",
			RUSSIA: "RU",
			RWANDA: "RW",
			SAINT_BARTHELEMY: "BL",
			SAINT_KITTS_AND_NEVIS: "KN",
			SAINT_LUCIA: "LC",
			SAINT_MARTIN: "MF",
			SAINT_VINCENT_AND_THE_GRENADINES: "VC",
			SAMOA: "WS",
			SAN_MARINO: "SM",
			SAO_TOME_AND_PRINCIPE: "ST",
			SAUDI_ARABIA: "SA",
			SENEGAL: "SN",
			SERBIA: "RS",
			SEYCHELLES: "SC",
			SIERRA_LEONE: "SL",
			SINGAPORE: "SG",
			SINT_MAARTEN: "SX",
			SLOVAKIA: "SK",
			SLOVENIA: "SI",
			SOLOMON_ISLANDS: "SB",
			SOMALIA: "SO",
			SOUTH_AFRICA: "ZA",
			SOUTH_GEORGIA_AND_THE_SOUTH_SANDWICH_ISLANDS: "GS",
			SOUTH_KOREA: "KR",
			SOUTH_SUDAN: "SS",
			SPAIN: "ES",
			SRI_LANKA: "LK",
			ST_HELENA: "SH",
			ST_PIERRE_AND_MIQUELON: "PM",
			SUDAN: "SD",
			SURINAME: "SR",
			SVALBARD_AND_JAN_MAYEN: "SJ",
			SWAZILAND: "SZ",
			SWEDEN: "SE",
			SWITZERLAND: "CH",
			SYRIAN_ARAB_REPUBLIC: "SY",
			TAIWAN: "TW",
			TAJIKISTAN: "TJ",
			TANZANIA: "TZ",
			THAILAND: "TH",
			TIMOR_LESTE: "TL",
			TOGO: "TG",
			TOKELAU: "TK",
			TONGA: "TO",
			TRINIDAD_AND_TOBAGO: "TT",
			TUNISIA: "TN",
			TURKEY: "TR",
			TURKMENISTAN: "TM",
			TURKS_AND_CAICOS_ISLANDS: "TC",
			TUVALU: "TV",
			UGANDA: "UG",
			UKRAINE: "UA",
			UNITED_ARAB_EMIRATES: "AE",
			UNITED_KINGDOM: "UK",
			UNITED_STATES: "US",
			URUGUAY: "UY",
			US_MINOR_OUTLYING_ISLANDS: "UM",
			US_VIRGIN_ISLANDS: "VI",
			UZBEKISTAN: "UZ",
			VANUATU: "VU",
			VATICAN_CITY: "VA",
			VENEZUELA: "VE",
			VIETNAM: "VN",
			WALLIS_AND_FUTUNA: "WF",
			WESTERN_SAHARA: "EH",
			YEMEN: "YE",
			ZAMBIA: "ZM",
			ZIMBABWE: "ZW",
		},
		CHOICE_LIST: [
			{
				label: "AF",
				value: "AFGHANISTAN",
			},
			{
				label: "AX",
				value: "ALAND_ISLANDS",
			},
			{
				label: "AL",
				value: "ALBANIA",
			},
			{
				label: "DZ",
				value: "ALGERIA",
			},
			{
				label: "AS",
				value: "AMERICAN_SAMOA",
			},
			{
				label: "AD",
				value: "ANDORRA",
			},
			{
				label: "AO",
				value: "ANGOLA",
			},
			{
				label: "AI",
				value: "ANGUILLA",
			},
			{
				label: "AQ",
				value: "ANTARCTICA",
			},
			{
				label: "AG",
				value: "ANTIGUA_AND_BARBUDA",
			},
			{
				label: "AR",
				value: "ARGENTINA",
			},
			{
				label: "AM",
				value: "ARMENIA",
			},
			{
				label: "AW",
				value: "ARUBA",
			},
			{
				label: "AU",
				value: "AUSTRALIA",
			},
			{
				label: "AT",
				value: "AUSTRIA",
			},
			{
				label: "AZ",
				value: "AZERBAIJAN",
			},
			{
				label: "BS",
				value: "BAHAMAS",
			},
			{
				label: "BH",
				value: "BAHRAIN",
			},
			{
				label: "BD",
				value: "BANGLADESH",
			},
			{
				label: "BB",
				value: "BARBADOS",
			},
			{
				label: "BY",
				value: "BELARUS",
			},
			{
				label: "BE",
				value: "BELGIUM",
			},
			{
				label: "BZ",
				value: "BELIZE",
			},
			{
				label: "BJ",
				value: "BENIN",
			},
			{
				label: "BM",
				value: "BERMUDA",
			},
			{
				label: "BT",
				value: "BHUTAN",
			},
			{
				label: "BO",
				value: "BOLIVIA",
			},
			{
				label: "BQ",
				value: "BONAIRE_SINT_EUSTATIUS_AND_SABA",
			},
			{
				label: "BA",
				value: "BOSNIA_AND_HERZEGOVINA",
			},
			{
				label: "BW",
				value: "BOTSWANA",
			},
			{
				label: "BV",
				value: "BOUVET_ISLAND",
			},
			{
				label: "BR",
				value: "BRAZIL",
			},
			{
				label: "IO",
				value: "BRITISH_INDIAN_OCEAN_TERRITORY",
			},
			{
				label: "VG",
				value: "BRITISH_VIRGIN_ISLANDS",
			},
			{
				label: "BN",
				value: "BRUNEI_DARUSSALAM",
			},
			{
				label: "BG",
				value: "BULGARIA",
			},
			{
				label: "BF",
				value: "BURKINA_FASO",
			},
			{
				label: "BI",
				value: "BURUNDI",
			},
			{
				label: "KH",
				value: "CAMBODIA",
			},
			{
				label: "CM",
				value: "CAMEROON",
			},
			{
				label: "CA",
				value: "CANADA",
			},
			{
				label: "CV",
				value: "CAPE_VERDE",
			},
			{
				label: "KY",
				value: "CAYMAN_ISLANDS",
			},
			{
				label: "CF",
				value: "CENTRAL_AFRICAN_REPUBLIC",
			},
			{
				label: "TD",
				value: "CHAD",
			},
			{
				label: "CL",
				value: "CHILE",
			},
			{
				label: "CN",
				value: "CHINA",
			},
			{
				label: "CX",
				value: "CHRISTMAS_ISLAND",
			},
			{
				label: "CC",
				value: "COCOS_ISLANDS",
			},
			{
				label: "CO",
				value: "COLOMBIA",
			},
			{
				label: "KM",
				value: "COMOROS",
			},
			{
				label: "CK",
				value: "COOK_ISLANDS",
			},
			{
				label: "CR",
				value: "COSTA_RICA",
			},
			{
				label: "HR",
				value: "CROATIA",
			},
			{
				label: "CU",
				value: "CUBA",
			},
			{
				label: "CW",
				value: "CURACAO",
			},
			{
				label: "CY",
				value: "CYPRUS",
			},
			{
				label: "CZ",
				value: "CZECH_REPUBLIC",
			},
			{
				label: "CD",
				value: "DEMOCRATIC_REPUBLIC_OF_THE_CONGO",
			},
			{
				label: "DK",
				value: "DENMARK",
			},
			{
				label: "DJ",
				value: "DJIBOUTI",
			},
			{
				label: "DM",
				value: "DOMINICA",
			},
			{
				label: "DO",
				value: "DOMINICAN_REPUBLIC",
			},
			{
				label: "EC",
				value: "ECUADOR",
			},
			{
				label: "EG",
				value: "EGYPT",
			},
			{
				label: "SV",
				value: "EL_SALVADOR",
			},
			{
				label: "GQ",
				value: "EQUATORIAL_GUINEA",
			},
			{
				label: "ER",
				value: "ERITREA",
			},
			{
				label: "EE",
				value: "ESTONIA",
			},
			{
				label: "ET",
				value: "ETHIOPIA",
			},
			{
				label: "FK",
				value: "FALKLAND_ISLANDS",
			},
			{
				label: "FO",
				value: "FAROE_ISLANDS",
			},
			{
				label: "FJ",
				value: "FIJI",
			},
			{
				label: "FI",
				value: "FINLAND",
			},
			{
				label: "FR",
				value: "FRANCE",
			},
			{
				label: "GF",
				value: "FRENCH_GUIANA",
			},
			{
				label: "PF",
				value: "FRENCH_POLYNESIA",
			},
			{
				label: "TF",
				value: "FRENCH_SOUTHERN_AND_ANTARCTIC_TERRITORIES",
			},
			{
				label: "GA",
				value: "GABON",
			},
			{
				label: "GM",
				value: "GAMBIA",
			},
			{
				label: "GE",
				value: "GEORGIA",
			},
			{
				label: "DE",
				value: "GERMANY",
			},
			{
				label: "GH",
				value: "GHANA",
			},
			{
				label: "GI",
				value: "GIBRALTAR",
			},
			{
				label: "GR",
				value: "GREECE",
			},
			{
				label: "GL",
				value: "GREENLAND",
			},
			{
				label: "GD",
				value: "GRENADA",
			},
			{
				label: "GP",
				value: "GUADELOUPE",
			},
			{
				label: "GU",
				value: "GUAM",
			},
			{
				label: "GT",
				value: "GUATEMALA",
			},
			{
				label: "GG",
				value: "GUERNSEY",
			},
			{
				label: "GN",
				value: "GUINEA",
			},
			{
				label: "GW",
				value: "GUINEA_BISSAU",
			},
			{
				label: "GY",
				value: "GUYANA",
			},
			{
				label: "HT",
				value: "HAITI",
			},
			{
				label: "HM",
				value: "HEARD_AND_MCDONALD_ISLANDS",
			},
			{
				label: "HN",
				value: "HONDURAS",
			},
			{
				label: "HK",
				value: "HONG_KONG",
			},
			{
				label: "HU",
				value: "HUNGARY",
			},
			{
				label: "IS",
				value: "ICELAND",
			},
			{
				label: "IN",
				value: "INDIA",
			},
			{
				label: "ID",
				value: "INDONESIA",
			},
			{
				label: "IR",
				value: "IRAN",
			},
			{
				label: "IQ",
				value: "IRAQ",
			},
			{
				label: "IE",
				value: "IRELAND",
			},
			{
				label: "IM",
				value: "ISLE_OF_MAN",
			},
			{
				label: "IL",
				value: "ISRAEL",
			},
			{
				label: "IT",
				value: "ITALY",
			},
			{
				label: "CI",
				value: "IVORY_COAST",
			},
			{
				label: "JM",
				value: "JAMAICA",
			},
			{
				label: "JP",
				value: "JAPAN",
			},
			{
				label: "JE",
				value: "JERSEY",
			},
			{
				label: "JO",
				value: "JORDAN",
			},
			{
				label: "KZ",
				value: "KAZAKHSTAN",
			},
			{
				label: "KE",
				value: "KENYA",
			},
			{
				label: "KI",
				value: "KIRIBATI",
			},
			{
				label: "KW",
				value: "KUWAIT",
			},
			{
				label: "KG",
				value: "KYRGYZSTAN",
			},
			{
				label: "LA",
				value: "LAOS",
			},
			{
				label: "LV",
				value: "LATVIA",
			},
			{
				label: "LB",
				value: "LEBANON",
			},
			{
				label: "LS",
				value: "LESOTHO",
			},
			{
				label: "LR",
				value: "LIBERIA",
			},
			{
				label: "LY",
				value: "LIBYAN_ARAB_JAMAHIRIYA",
			},
			{
				label: "LI",
				value: "LIECHTENSTEIN",
			},
			{
				label: "LT",
				value: "LITHUANIA",
			},
			{
				label: "LU",
				value: "LUXEMBOURG",
			},
			{
				label: "MO",
				value: "MACAU",
			},
			{
				label: "MK",
				value: "MACEDONIA",
			},
			{
				label: "MG",
				value: "MADAGASCAR",
			},
			{
				label: "MW",
				value: "MALAWI",
			},
			{
				label: "MY",
				value: "MALAYSIA",
			},
			{
				label: "MV",
				value: "MALDIVES",
			},
			{
				label: "ML",
				value: "MALI",
			},
			{
				label: "MT",
				value: "MALTA",
			},
			{
				label: "MH",
				value: "MARSHALL_ISLANDS",
			},
			{
				label: "MQ",
				value: "MARTINIQUE",
			},
			{
				label: "MR",
				value: "MAURITANIA",
			},
			{
				label: "MU",
				value: "MAURITIUS",
			},
			{
				label: "YT",
				value: "MAYOTTE",
			},
			{
				label: "MX",
				value: "MEXICO",
			},
			{
				label: "FM",
				value: "MICRONESIA",
			},
			{
				label: "MD",
				value: "MOLDOVA",
			},
			{
				label: "MC",
				value: "MONACO",
			},
			{
				label: "MN",
				value: "MONGOLIA",
			},
			{
				label: "ME",
				value: "MONTENEGRO",
			},
			{
				label: "MS",
				value: "MONTSERRAT",
			},
			{
				label: "MA",
				value: "MOROCCO",
			},
			{
				label: "MZ",
				value: "MOZAMBIQUE",
			},
			{
				label: "MM",
				value: "MYANMAR",
			},
			{
				label: "nan",
				value: "NAMIBIA",
			},
			{
				label: "NR",
				value: "NAURU",
			},
			{
				label: "NP",
				value: "NEPAL",
			},
			{
				label: "NL",
				value: "NETHERLANDS",
			},
			{
				label: "AN",
				value: "NETHERLANDS_ANTILLES",
			},
			{
				label: "NC",
				value: "NEW_CALEDONIA",
			},
			{
				label: "NZ",
				value: "NEW_ZEALAND",
			},
			{
				label: "NI",
				value: "NICARAGUA",
			},
			{
				label: "NE",
				value: "NIGER",
			},
			{
				label: "NG",
				value: "NIGERIA",
			},
			{
				label: "NU",
				value: "NIUE",
			},
			{
				label: "NF",
				value: "NORFOLK_ISLAND",
			},
			{
				label: "MP",
				value: "NORTHERN_MARIANA_ISLANDS",
			},
			{
				label: "KP",
				value: "NORTH_KOREA",
			},
			{
				label: "NO",
				value: "NORWAY",
			},
			{
				label: "OM",
				value: "OMAN",
			},
			{
				label: "PK",
				value: "PAKISTAN",
			},
			{
				label: "PW",
				value: "PALAU",
			},
			{
				label: "PS",
				value: "PALESTINIAN_TERRITORY",
			},
			{
				label: "PA",
				value: "PANAMA",
			},
			{
				label: "PG",
				value: "PAPUA_NEW_GUINEA",
			},
			{
				label: "PY",
				value: "PARAGUAY",
			},
			{
				label: "PE",
				value: "PERU",
			},
			{
				label: "PH",
				value: "PHILIPPINES",
			},
			{
				label: "PN",
				value: "PITCAIRN_ISLAND",
			},
			{
				label: "PL",
				value: "POLAND",
			},
			{
				label: "PT",
				value: "PORTUGAL",
			},
			{
				label: "PR",
				value: "PUERTO_RICO",
			},
			{
				label: "QA",
				value: "QATAR",
			},
			{
				label: "CG",
				value: "REPUBLIC_OF_THE_CONGO",
			},
			{
				label: "RE",
				value: "REUNION",
			},
			{
				label: "RO",
				value: "ROMANIA",
			},
			{
				label: "RU",
				value: "RUSSIA",
			},
			{
				label: "RW",
				value: "RWANDA",
			},
			{
				label: "BL",
				value: "SAINT_BARTHELEMY",
			},
			{
				label: "KN",
				value: "SAINT_KITTS_AND_NEVIS",
			},
			{
				label: "LC",
				value: "SAINT_LUCIA",
			},
			{
				label: "MF",
				value: "SAINT_MARTIN",
			},
			{
				label: "VC",
				value: "SAINT_VINCENT_AND_THE_GRENADINES",
			},
			{
				label: "WS",
				value: "SAMOA",
			},
			{
				label: "SM",
				value: "SAN_MARINO",
			},
			{
				label: "ST",
				value: "SAO_TOME_AND_PRINCIPE",
			},
			{
				label: "SA",
				value: "SAUDI_ARABIA",
			},
			{
				label: "SN",
				value: "SENEGAL",
			},
			{
				label: "RS",
				value: "SERBIA",
			},
			{
				label: "SC",
				value: "SEYCHELLES",
			},
			{
				label: "SL",
				value: "SIERRA_LEONE",
			},
			{
				label: "SG",
				value: "SINGAPORE",
			},
			{
				label: "SX",
				value: "SINT_MAARTEN",
			},
			{
				label: "SK",
				value: "SLOVAKIA",
			},
			{
				label: "SI",
				value: "SLOVENIA",
			},
			{
				label: "SB",
				value: "SOLOMON_ISLANDS",
			},
			{
				label: "SO",
				value: "SOMALIA",
			},
			{
				label: "ZA",
				value: "SOUTH_AFRICA",
			},
			{
				label: "GS",
				value: "SOUTH_GEORGIA_AND_THE_SOUTH_SANDWICH_ISLANDS",
			},
			{
				label: "KR",
				value: "SOUTH_KOREA",
			},
			{
				label: "SS",
				value: "SOUTH_SUDAN",
			},
			{
				label: "ES",
				value: "SPAIN",
			},
			{
				label: "LK",
				value: "SRI_LANKA",
			},
			{
				label: "SH",
				value: "ST_HELENA",
			},
			{
				label: "PM",
				value: "ST_PIERRE_AND_MIQUELON",
			},
			{
				label: "SD",
				value: "SUDAN",
			},
			{
				label: "SR",
				value: "SURINAME",
			},
			{
				label: "SJ",
				value: "SVALBARD_AND_JAN_MAYEN",
			},
			{
				label: "SZ",
				value: "SWAZILAND",
			},
			{
				label: "SE",
				value: "SWEDEN",
			},
			{
				label: "CH",
				value: "SWITZERLAND",
			},
			{
				label: "SY",
				value: "SYRIAN_ARAB_REPUBLIC",
			},
			{
				label: "TW",
				value: "TAIWAN",
			},
			{
				label: "TJ",
				value: "TAJIKISTAN",
			},
			{
				label: "TZ",
				value: "TANZANIA",
			},
			{
				label: "TH",
				value: "THAILAND",
			},
			{
				label: "TL",
				value: "TIMOR_LESTE",
			},
			{
				label: "TG",
				value: "TOGO",
			},
			{
				label: "TK",
				value: "TOKELAU",
			},
			{
				label: "TO",
				value: "TONGA",
			},
			{
				label: "TT",
				value: "TRINIDAD_AND_TOBAGO",
			},
			{
				label: "TN",
				value: "TUNISIA",
			},
			{
				label: "TR",
				value: "TURKEY",
			},
			{
				label: "TM",
				value: "TURKMENISTAN",
			},
			{
				label: "TC",
				value: "TURKS_AND_CAICOS_ISLANDS",
			},
			{
				label: "TV",
				value: "TUVALU",
			},
			{
				label: "UG",
				value: "UGANDA",
			},
			{
				label: "UA",
				value: "UKRAINE",
			},
			{
				label: "AE",
				value: "UNITED_ARAB_EMIRATES",
			},
			{
				label: "UK",
				value: "UNITED_KINGDOM",
			},
			{
				label: "US",
				value: "UNITED_STATES",
			},
			{
				label: "UY",
				value: "URUGUAY",
			},
			{
				label: "UM",
				value: "US_MINOR_OUTLYING_ISLANDS",
			},
			{
				label: "VI",
				value: "US_VIRGIN_ISLANDS",
			},
			{
				label: "UZ",
				value: "UZBEKISTAN",
			},
			{
				label: "VU",
				value: "VANUATU",
			},
			{
				label: "VA",
				value: "VATICAN_CITY",
			},
			{
				label: "VE",
				value: "VENEZUELA",
			},
			{
				label: "VN",
				value: "VIETNAM",
			},
			{
				label: "WF",
				value: "WALLIS_AND_FUTUNA",
			},
			{
				label: "EH",
				value: "WESTERN_SAHARA",
			},
			{
				label: "YE",
				value: "YEMEN",
			},
			{
				label: "ZM",
				value: "ZAMBIA",
			},
			{
				label: "ZW",
				value: "ZIMBABWE",
			},
		],
		CHRISTMAS_ISLAND: "CHRISTMAS_ISLAND",
		COCOS_ISLANDS: "COCOS_ISLANDS",
		COLOMBIA: "COLOMBIA",
		COMOROS: "COMOROS",
		COOK_ISLANDS: "COOK_ISLANDS",
		COSTA_RICA: "COSTA_RICA",
		CROATIA: "CROATIA",
		CUBA: "CUBA",
		CURACAO: "CURACAO",
		CYPRUS: "CYPRUS",
		CZECH_REPUBLIC: "CZECH_REPUBLIC",
		DEMOCRATIC_REPUBLIC_OF_THE_CONGO: "DEMOCRATIC_REPUBLIC_OF_THE_CONGO",
		DENMARK: "DENMARK",
		DJIBOUTI: "DJIBOUTI",
		DOMINICA: "DOMINICA",
		DOMINICAN_REPUBLIC: "DOMINICAN_REPUBLIC",
		ECUADOR: "ECUADOR",
		EGYPT: "EGYPT",
		EL_SALVADOR: "EL_SALVADOR",
		EQUATORIAL_GUINEA: "EQUATORIAL_GUINEA",
		ERITREA: "ERITREA",
		ESTONIA: "ESTONIA",
		ETHIOPIA: "ETHIOPIA",
		FALKLAND_ISLANDS: "FALKLAND_ISLANDS",
		FAROE_ISLANDS: "FAROE_ISLANDS",
		FIJI: "FIJI",
		FINLAND: "FINLAND",
		FRANCE: "FRANCE",
		FRENCH_GUIANA: "FRENCH_GUIANA",
		FRENCH_POLYNESIA: "FRENCH_POLYNESIA",
		FRENCH_SOUTHERN_AND_ANTARCTIC_TERRITORIES:
			"FRENCH_SOUTHERN_AND_ANTARCTIC_TERRITORIES",
		GABON: "GABON",
		GAMBIA: "GAMBIA",
		GEORGIA: "GEORGIA",
		GERMANY: "GERMANY",
		GHANA: "GHANA",
		GIBRALTAR: "GIBRALTAR",
		GREECE: "GREECE",
		GREENLAND: "GREENLAND",
		GRENADA: "GRENADA",
		GUADELOUPE: "GUADELOUPE",
		GUAM: "GUAM",
		GUATEMALA: "GUATEMALA",
		GUERNSEY: "GUERNSEY",
		GUINEA: "GUINEA",
		GUINEA_BISSAU: "GUINEA_BISSAU",
		GUYANA: "GUYANA",
		HAITI: "HAITI",
		HEARD_AND_MCDONALD_ISLANDS: "HEARD_AND_MCDONALD_ISLANDS",
		HONDURAS: "HONDURAS",
		HONG_KONG: "HONG_KONG",
		HUNGARY: "HUNGARY",
		ICELAND: "ICELAND",
		INDIA: "INDIA",
		INDONESIA: "INDONESIA",
		IRAN: "IRAN",
		IRAQ: "IRAQ",
		IRELAND: "IRELAND",
		ISLE_OF_MAN: "ISLE_OF_MAN",
		ISRAEL: "ISRAEL",
		ITALY: "ITALY",
		IVORY_COAST: "IVORY_COAST",
		JAMAICA: "JAMAICA",
		JAPAN: "JAPAN",
		JERSEY: "JERSEY",
		JORDAN: "JORDAN",
		KAZAKHSTAN: "KAZAKHSTAN",
		KENYA: "KENYA",
		KIRIBATI: "KIRIBATI",
		KUWAIT: "KUWAIT",
		KYRGYZSTAN: "KYRGYZSTAN",
		LAOS: "LAOS",
		LATVIA: "LATVIA",
		LEBANON: "LEBANON",
		LESOTHO: "LESOTHO",
		LIBERIA: "LIBERIA",
		LIBYAN_ARAB_JAMAHIRIYA: "LIBYAN_ARAB_JAMAHIRIYA",
		LIECHTENSTEIN: "LIECHTENSTEIN",
		LITHUANIA: "LITHUANIA",
		LUXEMBOURG: "LUXEMBOURG",
		MACAU: "MACAU",
		MACEDONIA: "MACEDONIA",
		MADAGASCAR: "MADAGASCAR",
		MALAWI: "MALAWI",
		MALAYSIA: "MALAYSIA",
		MALDIVES: "MALDIVES",
		MALI: "MALI",
		MALTA: "MALTA",
		MARSHALL_ISLANDS: "MARSHALL_ISLANDS",
		MARTINIQUE: "MARTINIQUE",
		MAURITANIA: "MAURITANIA",
		MAURITIUS: "MAURITIUS",
		MAYOTTE: "MAYOTTE",
		MEXICO: "MEXICO",
		MICRONESIA: "MICRONESIA",
		MOLDOVA: "MOLDOVA",
		MONACO: "MONACO",
		MONGOLIA: "MONGOLIA",
		MONTENEGRO: "MONTENEGRO",
		MONTSERRAT: "MONTSERRAT",
		MOROCCO: "MOROCCO",
		MOZAMBIQUE: "MOZAMBIQUE",
		MYANMAR: "MYANMAR",
		NAMIBIA: "NAMIBIA",
		NAURU: "NAURU",
		NEPAL: "NEPAL",
		NETHERLANDS: "NETHERLANDS",
		NETHERLANDS_ANTILLES: "NETHERLANDS_ANTILLES",
		NEW_CALEDONIA: "NEW_CALEDONIA",
		NEW_ZEALAND: "NEW_ZEALAND",
		NICARAGUA: "NICARAGUA",
		NIGER: "NIGER",
		NIGERIA: "NIGERIA",
		NIUE: "NIUE",
		NORFOLK_ISLAND: "NORFOLK_ISLAND",
		NORTHERN_MARIANA_ISLANDS: "NORTHERN_MARIANA_ISLANDS",
		NORTH_KOREA: "NORTH_KOREA",
		NORWAY: "NORWAY",
		OMAN: "OMAN",
		PAKISTAN: "PAKISTAN",
		PALAU: "PALAU",
		PALESTINIAN_TERRITORY: "PALESTINIAN_TERRITORY",
		PANAMA: "PANAMA",
		PAPUA_NEW_GUINEA: "PAPUA_NEW_GUINEA",
		PARAGUAY: "PARAGUAY",
		PERU: "PERU",
		PHILIPPINES: "PHILIPPINES",
		PITCAIRN_ISLAND: "PITCAIRN_ISLAND",
		POLAND: "POLAND",
		PORTUGAL: "PORTUGAL",
		PUERTO_RICO: "PUERTO_RICO",
		QATAR: "QATAR",
		REPUBLIC_OF_THE_CONGO: "REPUBLIC_OF_THE_CONGO",
		REUNION: "REUNION",
		ROMANIA: "ROMANIA",
		RUSSIA: "RUSSIA",
		RWANDA: "RWANDA",
		SAINT_BARTHELEMY: "SAINT_BARTHELEMY",
		SAINT_KITTS_AND_NEVIS: "SAINT_KITTS_AND_NEVIS",
		SAINT_LUCIA: "SAINT_LUCIA",
		SAINT_MARTIN: "SAINT_MARTIN",
		SAINT_VINCENT_AND_THE_GRENADINES: "SAINT_VINCENT_AND_THE_GRENADINES",
		SAMOA: "SAMOA",
		SAN_MARINO: "SAN_MARINO",
		SAO_TOME_AND_PRINCIPE: "SAO_TOME_AND_PRINCIPE",
		SAUDI_ARABIA: "SAUDI_ARABIA",
		SENEGAL: "SENEGAL",
		SERBIA: "SERBIA",
		SEYCHELLES: "SEYCHELLES",
		SIERRA_LEONE: "SIERRA_LEONE",
		SINGAPORE: "SINGAPORE",
		SINT_MAARTEN: "SINT_MAARTEN",
		SLOVAKIA: "SLOVAKIA",
		SLOVENIA: "SLOVENIA",
		SOLOMON_ISLANDS: "SOLOMON_ISLANDS",
		SOMALIA: "SOMALIA",
		SOUTH_AFRICA: "SOUTH_AFRICA",
		SOUTH_GEORGIA_AND_THE_SOUTH_SANDWICH_ISLANDS:
			"SOUTH_GEORGIA_AND_THE_SOUTH_SANDWICH_ISLANDS",
		SOUTH_KOREA: "SOUTH_KOREA",
		SOUTH_SUDAN: "SOUTH_SUDAN",
		SPAIN: "SPAIN",
		SRI_LANKA: "SRI_LANKA",
		ST_HELENA: "ST_HELENA",
		ST_PIERRE_AND_MIQUELON: "ST_PIERRE_AND_MIQUELON",
		SUDAN: "SUDAN",
		SURINAME: "SURINAME",
		SVALBARD_AND_JAN_MAYEN: "SVALBARD_AND_JAN_MAYEN",
		SWAZILAND: "SWAZILAND",
		SWEDEN: "SWEDEN",
		SWITZERLAND: "SWITZERLAND",
		SYRIAN_ARAB_REPUBLIC: "SYRIAN_ARAB_REPUBLIC",
		TAIWAN: "TAIWAN",
		TAJIKISTAN: "TAJIKISTAN",
		TANZANIA: "TANZANIA",
		THAILAND: "THAILAND",
		TIMOR_LESTE: "TIMOR_LESTE",
		TOGO: "TOGO",
		TOKELAU: "TOKELAU",
		TONGA: "TONGA",
		TRINIDAD_AND_TOBAGO: "TRINIDAD_AND_TOBAGO",
		TUNISIA: "TUNISIA",
		TURKEY: "TURKEY",
		TURKMENISTAN: "TURKMENISTAN",
		TURKS_AND_CAICOS_ISLANDS: "TURKS_AND_CAICOS_ISLANDS",
		TUVALU: "TUVALU",
		UGANDA: "UGANDA",
		UKRAINE: "UKRAINE",
		UNITED_ARAB_EMIRATES: "UNITED_ARAB_EMIRATES",
		UNITED_KINGDOM: "UNITED_KINGDOM",
		UNITED_STATES: "UNITED_STATES",
		URUGUAY: "URUGUAY",
		US_MINOR_OUTLYING_ISLANDS: "US_MINOR_OUTLYING_ISLANDS",
		US_VIRGIN_ISLANDS: "US_VIRGIN_ISLANDS",
		UZBEKISTAN: "UZBEKISTAN",
		VANUATU: "VANUATU",
		VATICAN_CITY: "VATICAN_CITY",
		VENEZUELA: "VENEZUELA",
		VIETNAM: "VIETNAM",
		WALLIS_AND_FUTUNA: "WALLIS_AND_FUTUNA",
		WESTERN_SAHARA: "WESTERN_SAHARA",
		YEMEN: "YEMEN",
		ZAMBIA: "ZAMBIA",
		ZIMBABWE: "ZIMBABWE",
	},
	CurrencyCode: {
		AED: 0,
		AFN: 1,
		ALL: 2,
		AMD: 3,
		ANG: 4,
		AOA: 5,
		ARS: 6,
		ATS: 7,
		AUD: 8,
		AWG: 9,
		AZN: 10,
		BAM: 11,
		BBD: 12,
		BDT: 13,
		BEF: 14,
		BGN: 15,
		BHD: 16,
		BIF: 17,
		BMD: 18,
		BND: 19,
		BOB: 20,
		BRL: 21,
		BSD: 22,
		BTN: 23,
		BWP: 24,
		BYR: 25,
		BZD: 26,
		CAD: 27,
		CDF: 28,
		CHF: 29,
		CHOICES: {
			0: "United Arab Emirates Dirham",
			1: "Afghanistan Afghani",
			2: "Albania Lek",
			3: "Armenia Dram",
			4: "NL Antilles Guilder",
			5: "Angola Kwanza",
			6: "Argentina Peso",
			7: "Austria Schilling",
			8: "Australia Dollar",
			9: "Aruba Florin",
			10: "Azerbaijan New Manat",
			11: "Bosnia Mark",
			12: "Barbados Dollar",
			13: "Bangladesh Taka",
			14: "Belgium Franc",
			15: "Bulgaria Lev",
			16: "Bahrain Dinar",
			17: "Burundi Franc",
			18: "Bermuda Dollar",
			19: "Brunei Dollar",
			20: "Bolivia Boliviano",
			21: "Brazil Real",
			22: "Bahamas Dollar",
			23: "Bhutan Ngultrum",
			24: "Botswana Pula",
			25: "Belarus Ruble",
			26: "Belize Dollar",
			27: "Canada Dollar",
			28: "Congo Franc",
			29: "Switzerland Franc",
			30: "Chili Peso",
			31: "China Yuan/Renminbi",
			32: "Colombia Peso",
			33: "Costa Rica Colon",
			34: "Cuba Convertible Peso",
			35: "Cuba Peso",
			36: "Cape Verde Escudo",
			37: "Cyprus Pound",
			38: "Czech Koruna",
			39: "Djibouti Franc",
			40: "Denmark Krone",
			41: "Germany Mark",
			42: "Dominican Republich Peso",
			43: "Algeria Dinar",
			44: "Estonia Kroon",
			45: "Egypt Pound",
			46: "Spain Peseta",
			47: "Ethiopia Birr",
			48: "Euro",
			49: "Finland Markka",
			50: "Fiji Dollar",
			51: "Falkland Islands Pound",
			52: "Great Britain Pound",
			53: "Georgia Lari",
			54: "Ghana New Cedi",
			55: "Gibraltar Pound",
			56: "Gambia Dalasi",
			57: "Guinea Franc",
			58: "Greece Drachma",
			59: "Guatemala Quetzal",
			60: "Guyana Dollar",
			61: "Hong Kong Dollar",
			62: "Honduras Lempira",
			63: "Croatia Kuna",
			64: "Haiti Gourde",
			65: "Hungary Forint",
			66: "Indonesia Rupiah",
			67: "Ireland Pound",
			68: "Israel New Shekel",
			69: "India Rupee",
			70: "Iraq Dinar",
			71: "Iran Rial",
			72: "Iceland Krona",
			73: "Italy Lira",
			74: "Jamaica Dollar",
			75: "Jordan Dinar",
			76: "Japan Yen",
			77: "Kenya Shilling",
			78: "Kyrgyzstan Som",
			79: "Cambodia Riel",
			80: "Comoros Franc",
			81: "North Korea Won",
			82: "South Korea Won",
			83: "Kuwait Dinar",
			84: "Cayman Islands Dollar",
			85: "Kazakhstan Tenge",
			86: "Laos Kip",
			87: "Lebanon Pound",
			88: "Sri Lanka Rupee",
			89: "Liberia Dollar",
			90: "Lesotho Loti",
			91: "Lithuania Litas",
			92: "Luxembourg Franc",
			93: "Latvia Lats",
			94: "Libya Dinar",
			95: "Morocco Dirham",
			96: "Moldova Leu",
			97: "Malagasy Ariary",
			98: "Macedonia Denar",
			99: "Myanmar Kyat",
			100: "Mongolia Tugrik",
			101: "Macau Pataca",
			102: "Mauritania Ouguiya",
			103: "Malta Lira",
			104: "Mauritius Rupee",
			105: "Maldives Rufiyaa",
			106: "Malawi Kwacha",
			107: "Mexico Peso",
			108: "Malaysia Ringgit",
			109: "Mozambique New Metical",
			110: "Namibia Dollar",
			111: "Nigeria Naira",
			112: "Nicaragua Cordoba Oro",
			113: "Netherlands Guilder",
			114: "Norway Kroner",
			115: "Nepal Rupee",
			116: "New Zealand Dollar",
			117: "Oman Rial",
			118: "Panama Balboa",
			119: "Peru Nuevo Sol",
			120: "Papua New Guinea Kina",
			121: "Philippines Peso",
			122: "Pakistan Rupee",
			123: "Poland Zloty",
			124: "Portugal Escudo",
			125: "Paraguay Guarani",
			126: "Qatar Rial",
			127: "Romania New Lei",
			128: "Serbia Dinar",
			129: "Russia Rouble",
			130: "Rwanda Franc",
			131: "Saudi Arabia Riyal",
			132: "Solomon Islands Dollar",
			133: "Seychelles Rupee",
			134: "Sudan Pound",
			135: "Sweden Krona",
			136: "Singapore Dollar",
			137: "St Helena Pound",
			138: "Slovenia Tolar",
			139: "Slovakia Koruna",
			140: "Sierra Leone Leone",
			141: "Somali Shilling",
			142: "Suriname Dollar",
			143: "Sao Tome/Principe Dobra",
			144: "El Salvador Colon",
			145: "Syria Pound",
			146: "Swaziland Lilangeni",
			147: "Thailand Baht",
			148: "Turkmenistan Manat",
			149: "Tunisia Dinar",
			150: "Tonga Pa'anga",
			151: "Turkish New Lira",
			152: "Trinidad/Tobago Dollar",
			153: "Taiwan Dollar",
			154: "Tanzania Shilling",
			155: "Ukraine Hryvnia",
			156: "Uganda Shilling",
			157: "USA Dollar",
			158: "Uruguay Peso",
			159: "Venezuela Bolivar",
			160: "Vietnam Dong",
			161: "Vanuatu Vatu",
			162: "Samoa Tala",
			163: "CFA Franc BEAC",
			164: "East Caribbean Dollar",
			165: "CFA Franc BCEAO",
			166: "CFP Franc",
			167: "Yemen Rial",
			168: "South Africa Rand",
			169: "Zambia Kwacha",
			170: "Zimbabwe Dollar",
		},
		CHOICE_LIST: [
			{
				label: "United Arab Emirates Dirham",
				value: 0,
			},
			{
				label: "Afghanistan Afghani",
				value: 1,
			},
			{
				label: "Albania Lek",
				value: 2,
			},
			{
				label: "Armenia Dram",
				value: 3,
			},
			{
				label: "NL Antilles Guilder",
				value: 4,
			},
			{
				label: "Angola Kwanza",
				value: 5,
			},
			{
				label: "Argentina Peso",
				value: 6,
			},
			{
				label: "Austria Schilling",
				value: 7,
			},
			{
				label: "Australia Dollar",
				value: 8,
			},
			{
				label: "Aruba Florin",
				value: 9,
			},
			{
				label: "Azerbaijan New Manat",
				value: 10,
			},
			{
				label: "Bosnia Mark",
				value: 11,
			},
			{
				label: "Barbados Dollar",
				value: 12,
			},
			{
				label: "Bangladesh Taka",
				value: 13,
			},
			{
				label: "Belgium Franc",
				value: 14,
			},
			{
				label: "Bulgaria Lev",
				value: 15,
			},
			{
				label: "Bahrain Dinar",
				value: 16,
			},
			{
				label: "Burundi Franc",
				value: 17,
			},
			{
				label: "Bermuda Dollar",
				value: 18,
			},
			{
				label: "Brunei Dollar",
				value: 19,
			},
			{
				label: "Bolivia Boliviano",
				value: 20,
			},
			{
				label: "Brazil Real",
				value: 21,
			},
			{
				label: "Bahamas Dollar",
				value: 22,
			},
			{
				label: "Bhutan Ngultrum",
				value: 23,
			},
			{
				label: "Botswana Pula",
				value: 24,
			},
			{
				label: "Belarus Ruble",
				value: 25,
			},
			{
				label: "Belize Dollar",
				value: 26,
			},
			{
				label: "Canada Dollar",
				value: 27,
			},
			{
				label: "Congo Franc",
				value: 28,
			},
			{
				label: "Switzerland Franc",
				value: 29,
			},
			{
				label: "Chili Peso",
				value: 30,
			},
			{
				label: "China Yuan/Renminbi",
				value: 31,
			},
			{
				label: "Colombia Peso",
				value: 32,
			},
			{
				label: "Costa Rica Colon",
				value: 33,
			},
			{
				label: "Cuba Convertible Peso",
				value: 34,
			},
			{
				label: "Cuba Peso",
				value: 35,
			},
			{
				label: "Cape Verde Escudo",
				value: 36,
			},
			{
				label: "Cyprus Pound",
				value: 37,
			},
			{
				label: "Czech Koruna",
				value: 38,
			},
			{
				label: "Djibouti Franc",
				value: 39,
			},
			{
				label: "Denmark Krone",
				value: 40,
			},
			{
				label: "Germany Mark",
				value: 41,
			},
			{
				label: "Dominican Republich Peso",
				value: 42,
			},
			{
				label: "Algeria Dinar",
				value: 43,
			},
			{
				label: "Estonia Kroon",
				value: 44,
			},
			{
				label: "Egypt Pound",
				value: 45,
			},
			{
				label: "Spain Peseta",
				value: 46,
			},
			{
				label: "Ethiopia Birr",
				value: 47,
			},
			{
				label: "Euro",
				value: 48,
			},
			{
				label: "Finland Markka",
				value: 49,
			},
			{
				label: "Fiji Dollar",
				value: 50,
			},
			{
				label: "Falkland Islands Pound",
				value: 51,
			},
			{
				label: "Great Britain Pound",
				value: 52,
			},
			{
				label: "Georgia Lari",
				value: 53,
			},
			{
				label: "Ghana New Cedi",
				value: 54,
			},
			{
				label: "Gibraltar Pound",
				value: 55,
			},
			{
				label: "Gambia Dalasi",
				value: 56,
			},
			{
				label: "Guinea Franc",
				value: 57,
			},
			{
				label: "Greece Drachma",
				value: 58,
			},
			{
				label: "Guatemala Quetzal",
				value: 59,
			},
			{
				label: "Guyana Dollar",
				value: 60,
			},
			{
				label: "Hong Kong Dollar",
				value: 61,
			},
			{
				label: "Honduras Lempira",
				value: 62,
			},
			{
				label: "Croatia Kuna",
				value: 63,
			},
			{
				label: "Haiti Gourde",
				value: 64,
			},
			{
				label: "Hungary Forint",
				value: 65,
			},
			{
				label: "Indonesia Rupiah",
				value: 66,
			},
			{
				label: "Ireland Pound",
				value: 67,
			},
			{
				label: "Israel New Shekel",
				value: 68,
			},
			{
				label: "India Rupee",
				value: 69,
			},
			{
				label: "Iraq Dinar",
				value: 70,
			},
			{
				label: "Iran Rial",
				value: 71,
			},
			{
				label: "Iceland Krona",
				value: 72,
			},
			{
				label: "Italy Lira",
				value: 73,
			},
			{
				label: "Jamaica Dollar",
				value: 74,
			},
			{
				label: "Jordan Dinar",
				value: 75,
			},
			{
				label: "Japan Yen",
				value: 76,
			},
			{
				label: "Kenya Shilling",
				value: 77,
			},
			{
				label: "Kyrgyzstan Som",
				value: 78,
			},
			{
				label: "Cambodia Riel",
				value: 79,
			},
			{
				label: "Comoros Franc",
				value: 80,
			},
			{
				label: "North Korea Won",
				value: 81,
			},
			{
				label: "South Korea Won",
				value: 82,
			},
			{
				label: "Kuwait Dinar",
				value: 83,
			},
			{
				label: "Cayman Islands Dollar",
				value: 84,
			},
			{
				label: "Kazakhstan Tenge",
				value: 85,
			},
			{
				label: "Laos Kip",
				value: 86,
			},
			{
				label: "Lebanon Pound",
				value: 87,
			},
			{
				label: "Sri Lanka Rupee",
				value: 88,
			},
			{
				label: "Liberia Dollar",
				value: 89,
			},
			{
				label: "Lesotho Loti",
				value: 90,
			},
			{
				label: "Lithuania Litas",
				value: 91,
			},
			{
				label: "Luxembourg Franc",
				value: 92,
			},
			{
				label: "Latvia Lats",
				value: 93,
			},
			{
				label: "Libya Dinar",
				value: 94,
			},
			{
				label: "Morocco Dirham",
				value: 95,
			},
			{
				label: "Moldova Leu",
				value: 96,
			},
			{
				label: "Malagasy Ariary",
				value: 97,
			},
			{
				label: "Macedonia Denar",
				value: 98,
			},
			{
				label: "Myanmar Kyat",
				value: 99,
			},
			{
				label: "Mongolia Tugrik",
				value: 100,
			},
			{
				label: "Macau Pataca",
				value: 101,
			},
			{
				label: "Mauritania Ouguiya",
				value: 102,
			},
			{
				label: "Malta Lira",
				value: 103,
			},
			{
				label: "Mauritius Rupee",
				value: 104,
			},
			{
				label: "Maldives Rufiyaa",
				value: 105,
			},
			{
				label: "Malawi Kwacha",
				value: 106,
			},
			{
				label: "Mexico Peso",
				value: 107,
			},
			{
				label: "Malaysia Ringgit",
				value: 108,
			},
			{
				label: "Mozambique New Metical",
				value: 109,
			},
			{
				label: "Namibia Dollar",
				value: 110,
			},
			{
				label: "Nigeria Naira",
				value: 111,
			},
			{
				label: "Nicaragua Cordoba Oro",
				value: 112,
			},
			{
				label: "Netherlands Guilder",
				value: 113,
			},
			{
				label: "Norway Kroner",
				value: 114,
			},
			{
				label: "Nepal Rupee",
				value: 115,
			},
			{
				label: "New Zealand Dollar",
				value: 116,
			},
			{
				label: "Oman Rial",
				value: 117,
			},
			{
				label: "Panama Balboa",
				value: 118,
			},
			{
				label: "Peru Nuevo Sol",
				value: 119,
			},
			{
				label: "Papua New Guinea Kina",
				value: 120,
			},
			{
				label: "Philippines Peso",
				value: 121,
			},
			{
				label: "Pakistan Rupee",
				value: 122,
			},
			{
				label: "Poland Zloty",
				value: 123,
			},
			{
				label: "Portugal Escudo",
				value: 124,
			},
			{
				label: "Paraguay Guarani",
				value: 125,
			},
			{
				label: "Qatar Rial",
				value: 126,
			},
			{
				label: "Romania New Lei",
				value: 127,
			},
			{
				label: "Serbia Dinar",
				value: 128,
			},
			{
				label: "Russia Rouble",
				value: 129,
			},
			{
				label: "Rwanda Franc",
				value: 130,
			},
			{
				label: "Saudi Arabia Riyal",
				value: 131,
			},
			{
				label: "Solomon Islands Dollar",
				value: 132,
			},
			{
				label: "Seychelles Rupee",
				value: 133,
			},
			{
				label: "Sudan Pound",
				value: 134,
			},
			{
				label: "Sweden Krona",
				value: 135,
			},
			{
				label: "Singapore Dollar",
				value: 136,
			},
			{
				label: "St Helena Pound",
				value: 137,
			},
			{
				label: "Slovenia Tolar",
				value: 138,
			},
			{
				label: "Slovakia Koruna",
				value: 139,
			},
			{
				label: "Sierra Leone Leone",
				value: 140,
			},
			{
				label: "Somali Shilling",
				value: 141,
			},
			{
				label: "Suriname Dollar",
				value: 142,
			},
			{
				label: "Sao Tome/Principe Dobra",
				value: 143,
			},
			{
				label: "El Salvador Colon",
				value: 144,
			},
			{
				label: "Syria Pound",
				value: 145,
			},
			{
				label: "Swaziland Lilangeni",
				value: 146,
			},
			{
				label: "Thailand Baht",
				value: 147,
			},
			{
				label: "Turkmenistan Manat",
				value: 148,
			},
			{
				label: "Tunisia Dinar",
				value: 149,
			},
			{
				label: "Tonga Pa'anga",
				value: 150,
			},
			{
				label: "Turkish New Lira",
				value: 151,
			},
			{
				label: "Trinidad/Tobago Dollar",
				value: 152,
			},
			{
				label: "Taiwan Dollar",
				value: 153,
			},
			{
				label: "Tanzania Shilling",
				value: 154,
			},
			{
				label: "Ukraine Hryvnia",
				value: 155,
			},
			{
				label: "Uganda Shilling",
				value: 156,
			},
			{
				label: "USA Dollar",
				value: 157,
			},
			{
				label: "Uruguay Peso",
				value: 158,
			},
			{
				label: "Venezuela Bolivar",
				value: 159,
			},
			{
				label: "Vietnam Dong",
				value: 160,
			},
			{
				label: "Vanuatu Vatu",
				value: 161,
			},
			{
				label: "Samoa Tala",
				value: 162,
			},
			{
				label: "CFA Franc BEAC",
				value: 163,
			},
			{
				label: "East Caribbean Dollar",
				value: 164,
			},
			{
				label: "CFA Franc BCEAO",
				value: 165,
			},
			{
				label: "CFP Franc",
				value: 166,
			},
			{
				label: "Yemen Rial",
				value: 167,
			},
			{
				label: "South Africa Rand",
				value: 168,
			},
			{
				label: "Zambia Kwacha",
				value: 169,
			},
			{
				label: "Zimbabwe Dollar",
				value: 170,
			},
		],
		CLP: 30,
		CNY: 31,
		COP: 32,
		CRC: 33,
		CUC: 34,
		CUP: 35,
		CVE: 36,
		CYP: 37,
		CZK: 38,
		DJF: 39,
		DKK: 40,
		DMK: 41,
		DOP: 42,
		DZD: 43,
		EEK: 44,
		EGP: 45,
		ESP: 46,
		ETB: 47,
		EUR: 48,
		FIM: 49,
		FJD: 50,
		FKP: 51,
		GBP: 52,
		GEL: 53,
		GHS: 54,
		GIP: 55,
		GMD: 56,
		GNF: 57,
		GRD: 58,
		GTQ: 59,
		GYD: 60,
		HKD: 61,
		HNL: 62,
		HRK: 63,
		HTG: 64,
		HUF: 65,
		IDR: 66,
		IED: 67,
		ILS: 68,
		INR: 69,
		IQD: 70,
		IRR: 71,
		ISK: 72,
		ITL: 73,
		JMD: 74,
		JOD: 75,
		JPY: 76,
		KES: 77,
		KGS: 78,
		KHR: 79,
		KMF: 80,
		KPW: 81,
		KRW: 82,
		KWD: 83,
		KYD: 84,
		KZT: 85,
		LAK: 86,
		LBP: 87,
		LKR: 88,
		LRD: 89,
		LSL: 90,
		LTL: 91,
		LUF: 92,
		LVL: 93,
		LYD: 94,
		MAD: 95,
		MDL: 96,
		MGA: 97,
		MKD: 98,
		MMK: 99,
		MNT: 100,
		MOP: 101,
		MRO: 102,
		MTL: 103,
		MUR: 104,
		MVR: 105,
		MWK: 106,
		MXN: 107,
		MYR: 108,
		MZN: 109,
		NAD: 110,
		NGN: 111,
		NIO: 112,
		NLG: 113,
		NOK: 114,
		NPR: 115,
		NZD: 116,
		OMR: 117,
		PAB: 118,
		PEN: 119,
		PGK: 120,
		PHP: 121,
		PKR: 122,
		PLN: 123,
		PTE: 124,
		PYG: 125,
		QAR: 126,
		RON: 127,
		RSD: 128,
		RUB: 129,
		RWF: 130,
		SAR: 131,
		SBD: 132,
		SCR: 133,
		SDG: 134,
		SEK: 135,
		SGD: 136,
		SHP: 137,
		SIT: 138,
		SKK: 139,
		SLL: 140,
		SOS: 141,
		SRD: 142,
		STD: 143,
		SVC: 144,
		SYP: 145,
		SZL: 146,
		THB: 147,
		TMM: 148,
		TND: 149,
		TOP: 150,
		TRY: 151,
		TTD: 152,
		TWD: 153,
		TZS: 154,
		UAH: 155,
		UGX: 156,
		USD: 157,
		UYU: 158,
		VEB: 159,
		VND: 160,
		VUV: 161,
		WST: 162,
		XAF: 163,
		XCD: 164,
		XOF: 165,
		XPF: 166,
		YER: 167,
		ZAR: 168,
		ZMK: 169,
		ZWD: 170,
	},
	Export: {
		CHOICES: {
			1: "New",
			2: "Synced",
			3: "Failed",
			4: "Refreshing",
			5: "SetupNotDone",
		},
		CHOICE_LIST: [
			{
				label: "New",
				value: 1,
			},
			{
				label: "Synced",
				value: 2,
			},
			{
				label: "Failed",
				value: 3,
			},
			{
				label: "Refreshing",
				value: 4,
			},
			{
				label: "SetupNotDone",
				value: 5,
			},
		],
		FAILED: 3,
		NEW: 1,
		REFRESHING: 4,
		SETUPNOTDONE: 5,
		SYNCED: 2,
	},
	FOP: {
		CARD: 3,
		CASH: 2,
		CHEQUE: 4,
		CHOICES: {
			1: "Credit",
			2: "Cash",
			3: "Card",
			4: "Cheque",
			5: "Bank Transfer",
		},
		CHOICE_LIST: [
			{
				label: "Credit",
				value: 1,
			},
			{
				label: "Cash",
				value: 2,
			},
			{
				label: "Card",
				value: 3,
			},
			{
				label: "Cheque",
				value: 4,
			},
			{
				label: "Bank Transfer",
				value: 5,
			},
		],
		CREDIT: 1,
		TRANSFER: 5,
	},
	FlightStatusCode: {
		CHOICES: {
			K: "Confirmed",
			NS: "InfantNoSeat",
			OPE: "Open",
			T: "Ticketed",
			X: "Cancelled",
		},
		CHOICE_LIST: [
			{
				label: "Confirmed",
				value: "K",
			},
			{
				label: "InfantNoSeat",
				value: "NS",
			},
			{
				label: "Open",
				value: "OPE",
			},
			{
				label: "Ticketed",
				value: "T",
			},
			{
				label: "Cancelled",
				value: "X",
			},
		],
		K: "K",
		NS: "NS",
		OPE: "OPE",
		T: "T",
		X: "X",
	},
	Gender: {
		CHOICES: {
			F: "Female",
			M: "Male",
			X: "Others",
		},
		CHOICE_LIST: [
			{
				label: "Female",
				value: "F",
			},
			{
				label: "Male",
				value: "M",
			},
			{
				label: "Others",
				value: "X",
			},
		],
		FEMALE: "F",
		MALE: "M",
		UNSPECIFIED: "X",
	},
	IATANumbers: {
		CHOICES: {
			"00000136": "00000136",
			"00000162": "00000162",
			86213525: "86213525",
		},
		CHOICE_LIST: [
			{
				label: "00000136",
				value: "00000136",
			},
			{
				label: "00000162",
				value: "00000162",
			},
			{
				label: "86213525",
				value: "86213525",
			},
		],
		value1: "00000136",
		value2: "00000162",
		value3: "86213525",
	},
	ItenaryTypeConstant: {
		CHOICES: {
			1: "Oneway",
			2: "Return",
			3: "Multicity",
		},
		CHOICE_LIST: [
			{
				label: "Oneway",
				value: 1,
			},
			{
				label: "Return",
				value: 2,
			},
			{
				label: "Multicity",
				value: 3,
			},
		],
		ONE: 1,
		THREE: 3,
		TWO: 2,
	},
	NotificationChoices: {
		CHOICES: {
			closed: "closed",
			open: "open",
		},
		CHOICE_LIST: [
			{
				label: "closed",
				value: "closed",
			},
			{
				label: "open",
				value: "open",
			},
		],
		status_closed: "closed",
		status_open: "open",
	},
	NuRequestStatus: {
		CHOICES: {
			1: "Created",
			2: "Requested",
			3: "Responded",
			4: "Success",
			5: "Error",
		},
		CHOICE_LIST: [
			{
				label: "Created",
				value: 1,
			},
			{
				label: "Requested",
				value: 2,
			},
			{
				label: "Responded",
				value: 3,
			},
			{
				label: "Success",
				value: 4,
			},
			{
				label: "Error",
				value: 5,
			},
		],
		CREATED: 1,
		ERROR: 5,
		REQUESTED: 2,
		RESPONDED: 3,
		SUCCESS: 4,
	},
	OfferTypeChoices: {
		CHOICES: {
			CombinationOffers: "CombinationOffers",
			FlightOffers: "FlightOffers",
		},
		CHOICE_LIST: [
			{
				label: "CombinationOffers",
				value: "CombinationOffers",
			},
			{
				label: "FlightOffers",
				value: "FlightOffers",
			},
		],
		COMBINATION_OFFERS: "CombinationOffers",
		FLIGHT_OFFERS: "FlightOffers",
	},
	OrderChange: {
		CHOICES: {
			1: "PASSENGER_UPDATE",
			2: "PASSENGER_DELETE",
			3: "PASSENGER_SEAT_SELECTION",
			4: "PASSENGER_ORDER_PAYMENT",
			5: "PASSENGER_ORDER_REISSUE",
		},
		CHOICE_LIST: [
			{
				label: "PASSENGER_UPDATE",
				value: 1,
			},
			{
				label: "PASSENGER_DELETE",
				value: 2,
			},
			{
				label: "PASSENGER_SEAT_SELECTION",
				value: 3,
			},
			{
				label: "PASSENGER_ORDER_PAYMENT",
				value: 4,
			},
			{
				label: "PASSENGER_ORDER_REISSUE",
				value: 5,
			},
		],
		PASSENGER_DELETE: 2,
		PASSENGER_ORDER_PAYMENT: 4,
		PASSENGER_ORDER_REISSUE: 5,
		PASSENGER_SEAT_SELECTION: 3,
		PASSENGER_UPDATE: 1,
	},
	OrderStatuses: {
		ALL: 1,
		BOOKED: 2,
		CANCELLED: 5,
		CHOICES: {
			1: "All (Recent)",
			2: "Booked",
			3: "Ticketed",
			4: "Void",
			5: "Cancelled",
			6: "Requires Sync",
		},
		CHOICE_LIST: [
			{
				label: "All (Recent)",
				value: 1,
			},
			{
				label: "Booked",
				value: 2,
			},
			{
				label: "Ticketed",
				value: 3,
			},
			{
				label: "Void",
				value: 4,
			},
			{
				label: "Cancelled",
				value: 5,
			},
			{
				label: "Requires Sync",
				value: 6,
			},
		],
		SYNC: 6,
		TICKETED: 3,
		VOID: 4,
	},
	OrganizationKind: {
		AGENCY: 3,
		AIRLINE: 1,
		CHOICES: {
			1: "Airline",
			2: "Platform",
			3: "Agency",
			4: "GDS",
		},
		CHOICE_LIST: [
			{
				label: "Airline",
				value: 1,
			},
			{
				label: "Platform",
				value: 2,
			},
			{
				label: "Agency",
				value: 3,
			},
			{
				label: "GDS",
				value: 4,
			},
		],
		GDS: 4,
		PLATFORM: 2,
	},
	OrganizationStatus: {
		ACTIVE: 1,
		CHOICES: {
			1: "Active",
			2: "Inactive",
			3: "Suspended",
		},
		CHOICE_LIST: [
			{
				label: "Active",
				value: 1,
			},
			{
				label: "Inactive",
				value: 2,
			},
			{
				label: "Suspended",
				value: 3,
			},
		],
		INACTIVE: 2,
		SUSPENDED: 3,
	},
	PublishChoices: {
		AIR: 2,
		CHOICES: {
			0: "Disabled",
			1: "TRAACS",
			2: "AIR(Amadeus)",
		},
		CHOICE_LIST: [
			{
				label: "Disabled",
				value: 0,
			},
			{
				label: "TRAACS",
				value: 1,
			},
			{
				label: "AIR(Amadeus)",
				value: 2,
			},
		],
		DISABLED: 0,
		TRAACS: 1,
	},
	SeatCharacteristics: {
		A: "A",
		A1: "1A",
		A3: "3A",
		A6: "6A",
		A7: "7A",
		AA: "AA",
		AB: "AB",
		AC: "AC",
		AG: "AG",
		AJ: "AJ",
		AL: "AL",
		AM: "AM",
		AR: "AR",
		AS: "AS",
		AT: "AT",
		AU: "AU",
		AV: "AV",
		AW: "AW",
		B: "B",
		B1: "1B",
		B3: "3B",
		B6: "6B",
		B7: "7B",
		BA: "BA",
		BK: "BK",
		C: "C",
		C1: "1C",
		CC: "CC",
		CH: "CH",
		CHOICES: {
			1: "Restricted seat - General",
			10: 'Seat designated for RBD "A"',
			11: 'Seat designated for RBD "B"',
			12: 'Seat designated for RBD "C"',
			13: 'Seat designated for RBD "D"',
			14: 'Seat designated for RBD "F"',
			15: 'Seat designated for RBD "H"',
			16: 'Seat designated for RBD "J"',
			17: 'Seat designated for RBD "K"',
			18: 'Seat designated for RBD "L"',
			19: 'Seat designated for RBD "M"',
			"1A": "Seat not allowed for infant",
			"1B": "Seat not allowed for medical",
			"1C": "Seat not allowed for unaccompanied minor",
			"1D": "Restricted recline seat",
			"1M": "Seat with movie view",
			"1W": "Window seat without window",
			2: "Leg rest available",
			20: 'Seat designated for RBD "P"',
			21: 'Seat designated for RBD "Q"',
			22: 'Seat designated for RBD "R"',
			23: 'Seat designated for RBD "S"',
			24: 'Seat designated for RBD "T"',
			25: 'Seat designated for RBD "V"',
			26: 'Seat designated for RBD "W"',
			27: 'Seat designated for RBD "Y"',
			28: "Not fitted",
			3: "Individual video screen - Choice of movies",
			"3A": "Individual video screen - No choice of movie",
			"3B": "Individual video screen-Choice of movies, games, information, etc",
			4: "Not a window seat",
			5: "Not an aisle seat",
			6: "Near galley seat",
			"6A": "In front of galley seat",
			"6B": "Behind galley seat",
			7: "Near toilet seat",
			70: "Individual video screen - services unspecified",
			71: "No seat --access to handicapped lavatory",
			72: "Undesirable seat",
			"7A": "In front of toilet seat",
			"7B": "Behind toilet seat",
			8: "No seat at this location",
			9: "Center seat (not window, not aisle)",
			A: "Aisle seat",
			AA: "All available aisle seats",
			AB: "Seat adjacent to bar",
			AC: "Seat adjacent to - closet",
			AG: "Seat adjacent to galley",
			AJ: "Adjacent aisle seats",
			AL: "Seat adjacent to lavatory",
			AM: "Individual movie screen - No choice of movie selection",
			AR: "No seat - airphone",
			AS: "Individual airphone",
			AT: "Seat adjacent to table",
			AU: "Seat adjacent to stairs to upper deck",
			AV: "Only available seats",
			AW: "All available window seats",
			B: "Seat with bassinet facility",
			BA: "No seat - bar",
			BK: "Blocked Seat for preferred passenger in adjacent seat",
			C: "Crew seat",
			CC: "Center section seat(s)",
			CH: "Chargeable seat",
			CL: "No seat - closet",
			CS: "Conditional seat-contact airline",
			D: "No seat - exit door",
			DE: "Deportee",
			E: "Exit and emergency exit",
			EA: "Not on exit seat",
			EC: "Electronic connection for lap top or FAX machine",
			EK: "Economy comfort seat",
			EX: "No seat - emergency Exit",
			F: "Added seat",
			FC: "Front of cabin class/compartment",
			G: "Seat at forward end of cabin",
			GF: "General facility",
			GN: "No seat - galley",
			GR: "Group seat - offered to travellers belonging to a group",
			H: "Seat with facilities for handicapped/incapacitated passenger",
			I: "Seat suitable for adult with an infant",
			IA: "Inside aisle seats",
			IE: "Seat not suitable for child",
			J: "Rear facing seat",
			K: "Bulkhead seat",
			KA: "Bulkhead seat with movie screen",
			KN: "Bulkhead, no seat",
			L: "Leg space seat",
			LA: "No seat \u2013 lavatory",
			LB: "Rear facing lie flat seat",
			LE: "Left facing lie flat seat more left angle",
			LF: "Lie flat seat",
			LG: "No seat - luggage storage",
			LH: "Restricted seat - offered on long-haul segments",
			LL: "Left facing lie flat seat",
			LR: "Right facing lie flat seat",
			LS: "Left side of aircraft",
			LT: "Right facing lie flat seat more right angle",
			M: "Seat without a movie view",
			MA: "Medically OK to travel",
			MS: "Middle seat",
			N: "No smoking seat",
			O: "Preferential seat",
			OW: "Overwing seat(s)",
			P: "Extra seat for comfort - arm rest can be raised",
			PC: "Pet cabin",
			Q: "Seat in a quiet zone",
			RS: "Right side of aircraft",
			S: "Smoking seat",
			SC: "Skycouch",
			SO: "No seat - storage space",
			ST: "No seat - stairs to upper deck",
			T: "Rear/Tail section of aircraft",
			TA: "No seat - table",
			U: "Seat suitable for unaccompanied minors",
			UP: "Upper deck",
			V: "Seat to be left vacant or offered last",
			W: "Window seat",
			WA: "Window and aisle together",
			X: "No facility seat (indifferent seat)",
			Z: "Buffer zone seat",
		},
		CHOICE_LIST: [
			{
				label: "Restricted seat - General",
				value: "1",
			},
			{
				label: 'Seat designated for RBD "A"',
				value: "10",
			},
			{
				label: 'Seat designated for RBD "B"',
				value: "11",
			},
			{
				label: 'Seat designated for RBD "C"',
				value: "12",
			},
			{
				label: 'Seat designated for RBD "D"',
				value: "13",
			},
			{
				label: 'Seat designated for RBD "F"',
				value: "14",
			},
			{
				label: 'Seat designated for RBD "H"',
				value: "15",
			},
			{
				label: 'Seat designated for RBD "J"',
				value: "16",
			},
			{
				label: 'Seat designated for RBD "K"',
				value: "17",
			},
			{
				label: 'Seat designated for RBD "L"',
				value: "18",
			},
			{
				label: 'Seat designated for RBD "M"',
				value: "19",
			},
			{
				label: "Seat not allowed for infant",
				value: "1A",
			},
			{
				label: "Seat not allowed for medical",
				value: "1B",
			},
			{
				label: "Seat not allowed for unaccompanied minor",
				value: "1C",
			},
			{
				label: "Restricted recline seat",
				value: "1D",
			},
			{
				label: "Seat with movie view",
				value: "1M",
			},
			{
				label: "Window seat without window",
				value: "1W",
			},
			{
				label: "Leg rest available",
				value: "2",
			},
			{
				label: 'Seat designated for RBD "P"',
				value: "20",
			},
			{
				label: 'Seat designated for RBD "Q"',
				value: "21",
			},
			{
				label: 'Seat designated for RBD "R"',
				value: "22",
			},
			{
				label: 'Seat designated for RBD "S"',
				value: "23",
			},
			{
				label: 'Seat designated for RBD "T"',
				value: "24",
			},
			{
				label: 'Seat designated for RBD "V"',
				value: "25",
			},
			{
				label: 'Seat designated for RBD "W"',
				value: "26",
			},
			{
				label: 'Seat designated for RBD "Y"',
				value: "27",
			},
			{
				label: "Not fitted",
				value: "28",
			},
			{
				label: "Individual video screen - Choice of movies",
				value: "3",
			},
			{
				label: "Individual video screen - No choice of movie",
				value: "3A",
			},
			{
				label:
					"Individual video screen-Choice of movies, games, information, etc",
				value: "3B",
			},
			{
				label: "Not a window seat",
				value: "4",
			},
			{
				label: "Not an aisle seat",
				value: "5",
			},
			{
				label: "Near galley seat",
				value: "6",
			},
			{
				label: "In front of galley seat",
				value: "6A",
			},
			{
				label: "Behind galley seat",
				value: "6B",
			},
			{
				label: "Near toilet seat",
				value: "7",
			},
			{
				label: "Individual video screen - services unspecified",
				value: "70",
			},
			{
				label: "No seat --access to handicapped lavatory",
				value: "71",
			},
			{
				label: "Undesirable seat",
				value: "72",
			},
			{
				label: "In front of toilet seat",
				value: "7A",
			},
			{
				label: "Behind toilet seat",
				value: "7B",
			},
			{
				label: "No seat at this location",
				value: "8",
			},
			{
				label: "Center seat (not window, not aisle)",
				value: "9",
			},
			{
				label: "Aisle seat",
				value: "A",
			},
			{
				label: "All available aisle seats",
				value: "AA",
			},
			{
				label: "Seat adjacent to bar",
				value: "AB",
			},
			{
				label: "Seat adjacent to - closet",
				value: "AC",
			},
			{
				label: "Seat adjacent to galley",
				value: "AG",
			},
			{
				label: "Adjacent aisle seats",
				value: "AJ",
			},
			{
				label: "Seat adjacent to lavatory",
				value: "AL",
			},
			{
				label: "Individual movie screen - No choice of movie selection",
				value: "AM",
			},
			{
				label: "No seat - airphone",
				value: "AR",
			},
			{
				label: "Individual airphone",
				value: "AS",
			},
			{
				label: "Seat adjacent to table",
				value: "AT",
			},
			{
				label: "Seat adjacent to stairs to upper deck",
				value: "AU",
			},
			{
				label: "Only available seats",
				value: "AV",
			},
			{
				label: "All available window seats",
				value: "AW",
			},
			{
				label: "Seat with bassinet facility",
				value: "B",
			},
			{
				label: "No seat - bar",
				value: "BA",
			},
			{
				label: "Blocked Seat for preferred passenger in adjacent seat",
				value: "BK",
			},
			{
				label: "Crew seat",
				value: "C",
			},
			{
				label: "Center section seat(s)",
				value: "CC",
			},
			{
				label: "Chargeable seat",
				value: "CH",
			},
			{
				label: "No seat - closet",
				value: "CL",
			},
			{
				label: "Conditional seat-contact airline",
				value: "CS",
			},
			{
				label: "No seat - exit door",
				value: "D",
			},
			{
				label: "Deportee",
				value: "DE",
			},
			{
				label: "Exit and emergency exit",
				value: "E",
			},
			{
				label: "Not on exit seat",
				value: "EA",
			},
			{
				label: "Electronic connection for lap top or FAX machine",
				value: "EC",
			},
			{
				label: "Economy comfort seat",
				value: "EK",
			},
			{
				label: "No seat - emergency Exit",
				value: "EX",
			},
			{
				label: "Added seat",
				value: "F",
			},
			{
				label: "Front of cabin class/compartment",
				value: "FC",
			},
			{
				label: "Seat at forward end of cabin",
				value: "G",
			},
			{
				label: "General facility",
				value: "GF",
			},
			{
				label: "No seat - galley",
				value: "GN",
			},
			{
				label: "Group seat - offered to travellers belonging to a group",
				value: "GR",
			},
			{
				label: "Seat with facilities for handicapped/incapacitated passenger",
				value: "H",
			},
			{
				label: "Seat suitable for adult with an infant",
				value: "I",
			},
			{
				label: "Inside aisle seats",
				value: "IA",
			},
			{
				label: "Seat not suitable for child",
				value: "IE",
			},
			{
				label: "Rear facing seat",
				value: "J",
			},
			{
				label: "Bulkhead seat",
				value: "K",
			},
			{
				label: "Bulkhead seat with movie screen",
				value: "KA",
			},
			{
				label: "Bulkhead, no seat",
				value: "KN",
			},
			{
				label: "Leg space seat",
				value: "L",
			},
			{
				label: "No seat \u2013 lavatory",
				value: "LA",
			},
			{
				label: "Rear facing lie flat seat",
				value: "LB",
			},
			{
				label: "Left facing lie flat seat more left angle",
				value: "LE",
			},
			{
				label: "Lie flat seat",
				value: "LF",
			},
			{
				label: "No seat - luggage storage",
				value: "LG",
			},
			{
				label: "Restricted seat - offered on long-haul segments",
				value: "LH",
			},
			{
				label: "Left facing lie flat seat",
				value: "LL",
			},
			{
				label: "Right facing lie flat seat",
				value: "LR",
			},
			{
				label: "Left side of aircraft",
				value: "LS",
			},
			{
				label: "Right facing lie flat seat more right angle",
				value: "LT",
			},
			{
				label: "Seat without a movie view",
				value: "M",
			},
			{
				label: "Medically OK to travel",
				value: "MA",
			},
			{
				label: "Middle seat",
				value: "MS",
			},
			{
				label: "No smoking seat",
				value: "N",
			},
			{
				label: "Preferential seat",
				value: "O",
			},
			{
				label: "Overwing seat(s)",
				value: "OW",
			},
			{
				label: "Extra seat for comfort - arm rest can be raised",
				value: "P",
			},
			{
				label: "Pet cabin",
				value: "PC",
			},
			{
				label: "Seat in a quiet zone",
				value: "Q",
			},
			{
				label: "Right side of aircraft",
				value: "RS",
			},
			{
				label: "Smoking seat",
				value: "S",
			},
			{
				label: "Skycouch",
				value: "SC",
			},
			{
				label: "No seat - storage space",
				value: "SO",
			},
			{
				label: "No seat - stairs to upper deck",
				value: "ST",
			},
			{
				label: "Rear/Tail section of aircraft",
				value: "T",
			},
			{
				label: "No seat - table",
				value: "TA",
			},
			{
				label: "Seat suitable for unaccompanied minors",
				value: "U",
			},
			{
				label: "Upper deck",
				value: "UP",
			},
			{
				label: "Seat to be left vacant or offered last",
				value: "V",
			},
			{
				label: "Window seat",
				value: "W",
			},
			{
				label: "Window and aisle together",
				value: "WA",
			},
			{
				label: "No facility seat (indifferent seat)",
				value: "X",
			},
			{
				label: "Buffer zone seat",
				value: "Z",
			},
		],
		CL: "CL",
		CS: "CS",
		D: "D",
		D1: "1D",
		DE: "DE",
		E: "E",
		EA: "EA",
		EC: "EC",
		EK: "EK",
		EX: "EX",
		F: "F",
		FC: "FC",
		G: "G",
		GF: "GF",
		GN: "GN",
		GR: "GR",
		H: "H",
		I1: "I",
		IA: "IA",
		IE: "IE",
		J: "J",
		K: "K",
		KA: "KA",
		KN: "KN",
		L: "L",
		LA: "LA",
		LB: "LB",
		LE: "LE",
		LF: "LF",
		LG: "LG",
		LH: "LH",
		LL: "LL",
		LR: "LR",
		LS: "LS",
		LT: "LT",
		M: "M",
		M1: "1M",
		MA: "MA",
		MS: "MS",
		N: "N",
		O1: "O",
		OW: "OW",
		P: "P",
		PC: "PC",
		Q: "Q",
		RS: "RS",
		S: "S",
		S1: "1",
		S10: "10",
		S11: "11",
		S12: "12",
		S13: "13",
		S14: "14",
		S15: "15",
		S16: "16",
		S17: "17",
		S18: "18",
		S19: "19",
		S2: "2",
		S20: "20",
		S21: "21",
		S22: "22",
		S23: "23",
		S24: "24",
		S25: "25",
		S26: "26",
		S27: "27",
		S28: "28",
		S3: "3",
		S4: "4",
		S5: "5",
		S6: "6",
		S7: "7",
		S70: "70",
		S71: "71",
		S72: "72",
		S8: "8",
		S9: "9",
		SC: "SC",
		SO: "SO",
		ST: "ST",
		T: "T",
		TA: "TA",
		U: "U",
		UP: "UP",
		V: "V",
		W: "W",
		W1: "1W",
		WA: "WA",
		X: "X",
		Z: "Z",
	},
	ServiceStatus: {
		ACTIVE: 1,
		CERTIFIED: 4,
		CHOICES: {
			1: "Active",
			2: "Inactive",
			3: "Ready",
			4: "Certified",
		},
		CHOICE_LIST: [
			{
				label: "Active",
				value: 1,
			},
			{
				label: "Inactive",
				value: 2,
			},
			{
				label: "Ready",
				value: 3,
			},
			{
				label: "Certified",
				value: 4,
			},
		],
		INACTIVE: 2,
		READY: 3,
	},
	TicketCouponStatuses: {
		ACCRUAL: "AC",
		ACTIVEQUEUE: "702",
		ADD: "A",
		AIRPORTCONTROL: "AL",
		ALLOCATED: "ALL",
		AVAILABLE: "AVA",
		BOARDED: "BD",
		CANCELLED: "X",
		CHANGE: "C",
		CHECKEDIN: "CK",
		CHOICES: {
			700: "NoActiveItinerary",
			701: "CouponNotification",
			702: "ActiveQueue",
			703: "DoNotQueue",
			704: "QueueNotification",
			705: "QueueInPrint",
			706: "SubQueuePresent",
			707: "On Hold",
			708: "Exchanged-FIM",
			709: "PaxDeleted",
			710: "Refund-Non-fare",
			A: "Add",
			AC: "Accrual",
			AL: "AirportControl",
			ALL: "Allocated",
			AVA: "Available",
			B: "Flown-Used",
			BD: "Boarded",
			C: "Change",
			CK: "CheckedIn",
			CLO: "Closed",
			D: "Reprint",
			DB: "Deboarded",
			DN: "DeniedBoarding",
			E: "Exchanged-Reissued",
			F: "FreeTextCritical",
			G: "NonAirSegment",
			I: "OriginalIssueOpen",
			IF: "InfoOnly",
			INU: "InUse",
			IO: "IrregularOp",
			K: "Confirmed",
			LIM: "LimitedUse",
			NAV: "NotAvailable",
			NC: "NotCheckedIn",
			NS: "InfantNoSeat",
			OF: "Offloaded",
			OK: "Ok-Confirmed",
			OLD: "Old-Replaced",
			OPE: "Open",
			P: "Provisional",
			PAV: "PartialAvailable",
			PE: "PrintExchange",
			PR: "Printed",
			PRF: "Preferred",
			PRP: "ProposedAllocation",
			R: "Request",
			RD: "Redemption",
			REP: "Replacement",
			REV: "Revised",
			RF: "Refunded",
			RQ: "Requested",
			S: "Suspended",
			SA: "SpaceAvailable",
			SB: "Standby",
			SRV: "Serviceable",
			T: "Ticketed",
			UNS: "Unserviceable",
			V: "Void",
			WL: "Waitlisted",
			X: "Cancelled",
		},
		CHOICE_LIST: [
			{
				label: "NoActiveItinerary",
				value: "700",
			},
			{
				label: "CouponNotification",
				value: "701",
			},
			{
				label: "ActiveQueue",
				value: "702",
			},
			{
				label: "DoNotQueue",
				value: "703",
			},
			{
				label: "QueueNotification",
				value: "704",
			},
			{
				label: "QueueInPrint",
				value: "705",
			},
			{
				label: "SubQueuePresent",
				value: "706",
			},
			{
				label: "On Hold",
				value: "707",
			},
			{
				label: "Exchanged-FIM",
				value: "708",
			},
			{
				label: "PaxDeleted",
				value: "709",
			},
			{
				label: "Refund-Non-fare",
				value: "710",
			},
			{
				label: "Add",
				value: "A",
			},
			{
				label: "Accrual",
				value: "AC",
			},
			{
				label: "AirportControl",
				value: "AL",
			},
			{
				label: "Allocated",
				value: "ALL",
			},
			{
				label: "Available",
				value: "AVA",
			},
			{
				label: "Flown-Used",
				value: "B",
			},
			{
				label: "Boarded",
				value: "BD",
			},
			{
				label: "Change",
				value: "C",
			},
			{
				label: "CheckedIn",
				value: "CK",
			},
			{
				label: "Closed",
				value: "CLO",
			},
			{
				label: "Reprint",
				value: "D",
			},
			{
				label: "Deboarded",
				value: "DB",
			},
			{
				label: "DeniedBoarding",
				value: "DN",
			},
			{
				label: "Exchanged-Reissued",
				value: "E",
			},
			{
				label: "FreeTextCritical",
				value: "F",
			},
			{
				label: "NonAirSegment",
				value: "G",
			},
			{
				label: "OriginalIssueOpen",
				value: "I",
			},
			{
				label: "InfoOnly",
				value: "IF",
			},
			{
				label: "InUse",
				value: "INU",
			},
			{
				label: "IrregularOp",
				value: "IO",
			},
			{
				label: "Confirmed",
				value: "K",
			},
			{
				label: "LimitedUse",
				value: "LIM",
			},
			{
				label: "NotAvailable",
				value: "NAV",
			},
			{
				label: "NotCheckedIn",
				value: "NC",
			},
			{
				label: "InfantNoSeat",
				value: "NS",
			},
			{
				label: "Offloaded",
				value: "OF",
			},
			{
				label: "Ok-Confirmed",
				value: "OK",
			},
			{
				label: "Old-Replaced",
				value: "OLD",
			},
			{
				label: "Open",
				value: "OPE",
			},
			{
				label: "Provisional",
				value: "P",
			},
			{
				label: "PartialAvailable",
				value: "PAV",
			},
			{
				label: "PrintExchange",
				value: "PE",
			},
			{
				label: "Printed",
				value: "PR",
			},
			{
				label: "Preferred",
				value: "PRF",
			},
			{
				label: "ProposedAllocation",
				value: "PRP",
			},
			{
				label: "Request",
				value: "R",
			},
			{
				label: "Redemption",
				value: "RD",
			},
			{
				label: "Replacement",
				value: "REP",
			},
			{
				label: "Revised",
				value: "REV",
			},
			{
				label: "Refunded",
				value: "RF",
			},
			{
				label: "Requested",
				value: "RQ",
			},
			{
				label: "Suspended",
				value: "S",
			},
			{
				label: "SpaceAvailable",
				value: "SA",
			},
			{
				label: "Standby",
				value: "SB",
			},
			{
				label: "Serviceable",
				value: "SRV",
			},
			{
				label: "Ticketed",
				value: "T",
			},
			{
				label: "Unserviceable",
				value: "UNS",
			},
			{
				label: "Void",
				value: "V",
			},
			{
				label: "Waitlisted",
				value: "WL",
			},
			{
				label: "Cancelled",
				value: "X",
			},
		],
		CLOSED: "CLO",
		CONFIRMED: "K",
		COUPONNOTIFICATION: "701",
		DEBOARDED: "DB",
		DENIEDBOARDING: "DN",
		DONOTQUEUE: "703",
		EXCHANGED_FIM: "708",
		EXCHANGED_REISSUED: "E",
		FLOWN_USED: "B",
		FREETEXTCRITICAL: "F",
		INFANTNOSEAT: "NS",
		INFOONLY: "IF",
		INUSE: "INU",
		IRREGULAROP: "IO",
		LIMITEDUSE: "LIM",
		NOACTIVEITINERARY: "700",
		NONAIRSEGMENT: "G",
		NOTAVAILABLE: "NAV",
		NOTCHECKEDIN: "NC",
		OFFLOADED: "OF",
		OK_CONFIRMED: "OK",
		OLD_REPLACED: "OLD",
		ON_HOLD: "707",
		OPEN: "OPE",
		ORIGINALISSUEOPEN: "I",
		PARTIALAVAILABLE: "PAV",
		PAXDELETED: "709",
		PREFERRED: "PRF",
		PRINTED: "PR",
		PRINTEXCHANGE: "PE",
		PROPOSEDALLOCATION: "PRP",
		PROVISIONAL: "P",
		QUEUEINPRINT: "705",
		QUEUENOTIFICATION: "704",
		REDEMPTION: "RD",
		REFUNDED: "RF",
		REFUND_NON_FARE: "710",
		REPLACEMENT: "REP",
		REPRINT: "D",
		REQUEST: "R",
		REQUESTED: "RQ",
		REVISED: "REV",
		SERVICEABLE: "SRV",
		SPACEAVAILABLE: "SA",
		STANDBY: "SB",
		SUBQUEUEPRESENT: "706",
		SUSPENDED: "S",
		TICKETED: "T",
		UNSERVICEABLE: "UNS",
		VOID: "V",
		WAITLISTED: "WL",
	},
	TicketKind: {
		C: 2,
		CHOICES: {
			1: "Ticketed",
			2: "Refunded (Cancelled)",
		},
		CHOICE_LIST: [
			{
				label: "Ticketed",
				value: 1,
			},
			{
				label: "Refunded (Cancelled)",
				value: 2,
			},
		],
		T: 1,
	},
	TicketStatus: {
		CANCELLED: 3,
		CHOICES: {
			1: "Issued",
			2: "Voided",
			3: "Cancelled",
		},
		CHOICE_LIST: [
			{
				label: "Issued",
				value: 1,
			},
			{
				label: "Voided",
				value: 2,
			},
			{
				label: "Cancelled",
				value: 3,
			},
		],
		ISSUED: 1,
		VOIDED: 2,
	},
	TicketStatusCode: {
		CHOICES: {
			1: "TICKETLESS",
			700: "OTHER",
			702: "Ticket (electronic)",
			703: "Stock control number",
			E: "Excess baggage",
			INF: "Infant ticket",
			J: "EMD-A (Associated)",
			O: "Tour order",
			S: "Special service ticket",
			T: "TICKET",
			V: "Void",
			Y: "EMD-S (Standalone)",
		},
		CHOICE_LIST: [
			{
				label: "TICKETLESS",
				value: "1",
			},
			{
				label: "OTHER",
				value: "700",
			},
			{
				label: "Ticket (electronic)",
				value: "702",
			},
			{
				label: "Stock control number",
				value: "703",
			},
			{
				label: "Excess baggage",
				value: "E",
			},
			{
				label: "Infant ticket",
				value: "INF",
			},
			{
				label: "EMD-A (Associated)",
				value: "J",
			},
			{
				label: "Tour order",
				value: "O",
			},
			{
				label: "Special service ticket",
				value: "S",
			},
			{
				label: "TICKET",
				value: "T",
			},
			{
				label: "Void",
				value: "V",
			},
			{
				label: "EMD-S (Standalone)",
				value: "Y",
			},
		],
		EMD_ANCILLARY: "J",
		EMD_BAGGAGE: "E",
		EMD_PENALTY: "Y",
		E_TICKET: "702",
		OTHER: "700",
		SSR: "S",
		STOCK: "703",
		TICKET: "T",
		TICKETLESS: "1",
		TICKET_INF: "INF",
		TOUR_ORDER: "O",
		VOID: "V",
	},
	TicketStatusShort: {
		CHOICES: {
			1: "TKTLS",
			700: "D-OTH",
			702: "E-TKT",
			703: "STOCK",
			E: "EMD-B",
			INF: "T-INF",
			J: "EMD-A",
			O: "T-ORD",
			S: "T-SSR",
			T: "TICKT",
			V: "Void",
			Y: "EMD-S",
		},
		CHOICE_LIST: [
			{
				label: "TKTLS",
				value: "1",
			},
			{
				label: "D-OTH",
				value: "700",
			},
			{
				label: "E-TKT",
				value: "702",
			},
			{
				label: "STOCK",
				value: "703",
			},
			{
				label: "EMD-B",
				value: "E",
			},
			{
				label: "T-INF",
				value: "INF",
			},
			{
				label: "EMD-A",
				value: "J",
			},
			{
				label: "T-ORD",
				value: "O",
			},
			{
				label: "T-SSR",
				value: "S",
			},
			{
				label: "TICKT",
				value: "T",
			},
			{
				label: "Void",
				value: "V",
			},
			{
				label: "EMD-S",
				value: "Y",
			},
		],
		EMD_ANCILLARY: "J",
		EMD_BAGGAGE: "E",
		EMD_PENALTY: "Y",
		E_TICKET: "702",
		OTHER: "700",
		SSR: "S",
		STOCK: "703",
		TICKET: "T",
		TICKETLESS: "1",
		TICKET_INF: "INF",
		TOUR_ORDER: "O",
		VOID: "V",
	},
	TitleChoices: {
		CHOICES: {
			1: "MR",
			2: "MISS",
			3: "MRS",
			4: "MASTER",
			5: "DR",
		},
		CHOICE_LIST: [
			{
				label: "MR",
				value: 1,
			},
			{
				label: "MISS",
				value: 2,
			},
			{
				label: "MRS",
				value: 3,
			},
			{
				label: "MASTER",
				value: 4,
			},
			{
				label: "DR",
				value: 5,
			},
		],
		DR: 5,
		MASTER: 4,
		MISS: 2,
		MR: 1,
		MRS: 3,
	},
	UploadKind: {
		CHOICES: {
			1: "Profile Picture",
			2: "Excel Report",
		},
		CHOICE_LIST: [
			{
				label: "Profile Picture",
				value: 1,
			},
			{
				label: "Excel Report",
				value: 2,
			},
		],
		EXCEL_REPORT: 2,
		PROFILE_PICTURE: 1,
	},
	UploadStatus: {
		CHOICES: {
			1: "Uploading",
			2: "Uploaded",
			3: "Processing",
			4: "Processed",
			5: "Error",
		},
		CHOICE_LIST: [
			{
				label: "Uploading",
				value: 1,
			},
			{
				label: "Uploaded",
				value: 2,
			},
			{
				label: "Processing",
				value: 3,
			},
			{
				label: "Processed",
				value: 4,
			},
			{
				label: "Error",
				value: 5,
			},
		],
		ERROR: 5,
		PROCESSED: 4,
		PROCESSING: 3,
		UPLOADED: 2,
		UPLOADING: 1,
	},
	UserKind: {
		ADMIN: 1,
		CHOICES: {
			1: "Admin",
			2: "Client",
		},
		CHOICE_LIST: [
			{
				label: "Admin",
				value: 1,
			},
			{
				label: "Client",
				value: 2,
			},
		],
		CLIENT: 2,
	},
};

export default CHOICES;
